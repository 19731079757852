import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Permission, User } from '../../../interfaces/models/user'
import { StateExtended } from '../../../interfaces/StateExtended'


export function useAppUserDetailsSelector() {
  const user: User | null = useSelector((state: StateExtended) => state.user.user)

  return user
}

export function useAppUserTokenSelector() {
  const token: string | null = useSelector((state: StateExtended) => state.user.token)

  return token
}

export function useAppUserAuthSelector() {
  const isAuth: boolean = useSelector((state: StateExtended) => state.user.isAuthenticated)

  return isAuth
}

export function useAppTenantCompanyProfileSelector() {
  const cProfile = useSelector((state: StateExtended) => state.user.companyProfile)

  return cProfile
}

export function useAppUserRolesSelector() {
   const roles: Permission[] | undefined = useSelector((state: StateExtended) => state.user.user?.role.permissions)

   return roles

 }
