import { type } from 'os';
import { ActionExtended } from '../../../../_common/interfaces/ActionExtended';
import { Tenant } from '../../../../_common/interfaces/models/tenant';
import { ACTIONS } from '../../../../_config'

export interface SuperAdminTenantReducer {
  tenantData: Tenant | null;
  modulePermission: any
}

const initialState: SuperAdminTenantReducer = {
  tenantData: null,
  modulePermission: null
};

const superAdminTenantReducer = (state = initialState, action: ActionExtended) => {
  // console.log(action)
  switch (action.type) {
    case ACTIONS.SUPER_ADMIN.TENANT.GET_ALL_REGISTERED_COMPANY_LIST:
      return {
        ...state,
        tenantData: action.payload.data,
      };
    case ACTIONS.SUPER_ADMIN.TENANT.GET_MODULE_PERMISSION:
      return {
        ...state,
        modulePermission: action.payload,
      };
    default:
      return state;
  }
};

export default superAdminTenantReducer;
