import React from 'react';

const LazyEngagement = React.lazy(() =>
  import(/* webpackChunkName: "Home" */ './engagement')
);

const EngagementPage = (props: Record<string, any>) => (
  <React.Suspense fallback={<h1>Loading...</h1>}>
    <LazyEngagement {...props} />
  </React.Suspense>
);

export default EngagementPage