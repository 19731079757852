import { ActionExtended } from "../../../../../_common/interfaces/ActionExtended";
import { ACTIONS } from "../../../../../_config";
import { AssessmentList, AssessmentCategory, AssessmentCategoryQuestion } from "../../../../../_common/interfaces/models/assessment";

export interface AssessmentReducer {
   assessmentList: AssessmentList[] | null;
   predefinedAssessment: AssessmentCategory[] | null;
   categoryQuestionAnswer: AssessmentCategoryQuestion[] | null;
}

const initialState: AssessmentReducer = {
   assessmentList: null,
   predefinedAssessment: null,
   categoryQuestionAnswer: null
};

const assessmentReducer = (state = initialState, action: ActionExtended) => {
   switch (action.type) {
      case ACTIONS.TENANT.PROJECT.ASSESSMENT.GET_ALL_ASSESSMENT:
         return {
            ...state,
            assessmentList: action.payload
         }

      case ACTIONS.TENANT.PROJECT.ASSESSMENT.GET_ALL_PREDEFINE_ASSESSMENT_LIST:
         return {
            ...state,
            predefinedAssessment: action.payload
         }
      case ACTIONS.TENANT.PROJECT.ASSESSMENT.GET_PREDEFINED_CATEGORYWISE_QUESTION:
         return {
            ...state,
            categoryQuestionAnswer: action.payload
         }
      default:
         return state
   }
}

export default assessmentReducer