import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, withRouter, HashRouter, Redirect, useHistory } from 'react-router-dom';
import './App.css';
import { MODULES, OPERATIONS, URLS, URL_AVAILABLE } from './_config';
import requireNoAuth from './_common/hoc/reqNoAuth'
import requireAuth from './_common/hoc/reqAuth'
import reqLayout from './_common/hoc/layout';

// import Registration from './containers/registration'
import JoinUs from './containers/joinus'
import Login from './containers/login'
import VerifyEmail from './containers/verify-email'
import ForgotPassword from './containers/forgot-password'
import ResetPassword from './containers/reset-password'
import Unauthorized from './containers/unauthorized'

// // company page import
import CompanyLogin from './containers/tenant/login'
import CompanyDashboard from './containers/tenant/dashboard'
import TenantMyAccount from './containers/tenant/my-account'

import RoleList from './containers/tenant/role-management/listing';
import AssignPermissionToRole from './containers/tenant/role-management/assign-permission'
// import CompanyAccount from './containers/company/account'
import TenantAboutMe from './containers/tenant/about-me'
import Client from './containers/tenant/client-management/listing'
import ClientSetup from './containers/tenant/client-management/setup'

// superAdmin page import
import TenantListing from './containers/super-admin/tenants/listing'
// import SuperAdminAccount from './containers/super-admin/my-account'
import AdminDashboard from './containers/super-admin/dashboard'
import AdminMyAccount from './containers/super-admin/my-account'

import Page404 from './containers/page-404'

//Tenant User imports
import UserList from './containers/tenant/change-manager/listing';
import AddUser from './containers/tenant/change-manager/add-manager';
import Project from './containers/tenant/project-management/listing';
import ProjectSetup from './containers/tenant/project-management/setup';

import InputsPage from './containers/tenant/project-management/inputs';
import ChangePlanPage from './containers/tenant/project-management/change-plan';
import OrgnanogramPage from './containers/tenant/project-management/orgnanogram';
import EngagementPage from './containers/tenant/project-management/engagement';
import ImpactPage from './containers/tenant/project-management/impact';
import SurveyPage from './containers/tenant/project-management/survey';
import PollPage from './containers/tenant/project-management/poll';
import ChangeLoadPage from './containers/tenant/project-management/change-load';

import ProjectDashboardPage from './containers/tenant/project-management/dashboard';
import CommsPage from './containers/tenant/project-management/comms';
import CommsApprovalPage from './containers/tenant/project-management/commsApproval';
import TrainingPage from './containers/tenant/project-management/training';
import AssessmentPage from './containers/tenant/project-management/assessment';
import ReportsPage from './containers/tenant/project-management/reports';
import PublicSharePage from './containers/tenant/project-management/publicShare';


import { useSuperAdminCommonApi } from './_common/hooks/api/super-admin/common/appCommonApiHook';

//Project imports


function App() {

  const commonApi = useSuperAdminCommonApi()
  useEffect(() => {
    commonApi.callSiteInfo({
      domain: window.location.host
    }, () => {}, () => {})
  }, [])

  return (
    <Router>
      <Switch>
        {/* <Route exact path={URLS.HOME} component={requireAuth(Home)} /> */}
        <Route exact path={URLS.HOME} component={() => (
          <Redirect to={URLS.JOIN_US} />
        )} />
        {/* <Route exact path={URLS.REG} component={requireNoAuth(Registration)} /> */}
        <Route exact path={URLS.JOIN_US} component={requireNoAuth(JoinUs, URL_AVAILABLE.ADMIN_ONLY)} />
        <Route exact path={URLS.LOGIN} component={requireNoAuth(Login, URL_AVAILABLE.ADMIN_ONLY)} />
        <Route exact path={URLS.VERIFY_EMAIL} component={requireNoAuth(VerifyEmail, URL_AVAILABLE.ADMIN_ONLY)} />
        <Route exact path={URLS.FORGOT_PASSWORD} component={requireNoAuth(ForgotPassword, URL_AVAILABLE.ADMIN_ONLY)} />
        <Route exact path={URLS.RESET_PASSWORD} component={requireNoAuth(ResetPassword, URL_AVAILABLE.ADMIN_ONLY)} />
        <Route path={URLS.UNAUTHORIZED} component={Unauthorized} />


        {/* tenant Url */}
        <Route exact path={URLS.TENANT.USER.LOGIN} component={requireNoAuth(CompanyLogin, URL_AVAILABLE.TENANT_ONLY)} />
        <Route exact path={URLS.TENANT.USER.FORGOT_PASSWORD} component={requireNoAuth(ForgotPassword, URL_AVAILABLE.TENANT_ONLY)} />
        <Route exact path={URLS.TENANT.USER.RESET_PASSWORD} component={requireNoAuth(ResetPassword, URL_AVAILABLE.TENANT_ONLY)} />
        <Route exact path={URLS.TENANT.USER.VERIFY_EMAIL} component={requireNoAuth(VerifyEmail, URL_AVAILABLE.TENANT_ONLY)} />
        <Route exact path={URLS.TENANT.USER.DASHBOARD} component={requireAuth(CompanyDashboard, URL_AVAILABLE.TENANT_ONLY)} />
        <Route exact path={URLS.TENANT.CLIENT.CLIENT} component={requireAuth(Client, URL_AVAILABLE.TENANT_ONLY)} />
        <Route exact path={URLS.TENANT.CLIENT.CLIENT_SETUP} component={requireAuth(ClientSetup, URL_AVAILABLE.TENANT_ONLY, OPERATIONS.ADD, MODULES.CLIENT)} />
        <Route exact path={URLS.TENANT.ROLE.ROLE_LISTING} component={requireAuth(RoleList, URL_AVAILABLE.TENANT_ONLY, OPERATIONS.LIST, MODULES.ROLE)} />
        <Route exact path={URLS.TENANT.ROLE.PERMISSION_ASSIGN} component={requireAuth(AssignPermissionToRole, URL_AVAILABLE.TENANT_ONLY, OPERATIONS.ASSIGN, MODULES.ROLE)} />
        <Route exact path={URLS.TENANT.USER.ABOUT_ME} component={requireAuth(TenantAboutMe, URL_AVAILABLE.TENANT_ONLY)} />
        <Route exact path={URLS.TENANT.CH_MANGER.USER_LISTING} component={requireAuth(UserList, URL_AVAILABLE.TENANT_ONLY, OPERATIONS.LIST, MODULES.USER)} />
        <Route exact path={URLS.TENANT.CH_MANGER.USER_ADD} component={requireAuth(AddUser, URL_AVAILABLE.TENANT_ONLY, OPERATIONS.ADD, MODULES.USER)} />
        <Route exact path={URLS.TENANT.CH_MANGER.USER_EDIT} component={requireAuth(AddUser, URL_AVAILABLE.TENANT_ONLY, OPERATIONS.EDIT, MODULES.USER)} />
        <Route exact path={URLS.TENANT.USER.MY_ACCOUNT} component={requireAuth(TenantMyAccount, URL_AVAILABLE.TENANT_ONLY)} />

         {/* Tenant Projects */}
        <Route exact path={URLS.TENANT.PROJECT.PROJECT} component={requireAuth(Project, URL_AVAILABLE.TENANT_ONLY, OPERATIONS.LIST, MODULES.PROJECT)} />
        <Route exact path={URLS.TENANT.PROJECT.PROJECT_SETUP} component={requireAuth(ProjectSetup, URL_AVAILABLE.TENANT_ONLY, OPERATIONS.ADD, MODULES.PROJECT)} />
        {/* Tenant project Inputs */}
        <Route exact path={URLS.TENANT.PROJECT.INPUTS} component={requireAuth(InputsPage, URL_AVAILABLE.TENANT_ONLY, OPERATIONS.ADD, MODULES.PROJECT)} />
        <Route exact path={URLS.TENANT.PROJECT.CHANGES} component={requireAuth(ChangePlanPage, URL_AVAILABLE.TENANT_ONLY, OPERATIONS.LIST, MODULES.PROJECT)} />
        <Route exact path={URLS.TENANT.PROJECT.ORGNANOGRAM} component={requireAuth(OrgnanogramPage, URL_AVAILABLE.TENANT_ONLY, OPERATIONS.LIST, MODULES.PROJECT)} />
        <Route exact path={URLS.TENANT.PROJECT.ENGAGEMENT} component={requireAuth(EngagementPage, URL_AVAILABLE.TENANT_ONLY, OPERATIONS.LIST, MODULES.PROJECT)} />
        <Route exact path={URLS.TENANT.PROJECT.IMPACT} component={requireAuth(ImpactPage, URL_AVAILABLE.TENANT_ONLY, OPERATIONS.LIST, MODULES.PROJECT)} />
        <Route exact path={URLS.TENANT.PROJECT.CHANGE_LOAD} component={requireAuth(ChangeLoadPage, URL_AVAILABLE.TENANT_ONLY, OPERATIONS.LIST, MODULES.PROJECT)} />

        
        
        <Route exact path={URLS.TENANT.PROJECT.DASHBOARD} component={requireAuth(ProjectDashboardPage, URL_AVAILABLE.TENANT_ONLY, OPERATIONS.LIST, MODULES.PROJECT)} />
        <Route exact path={URLS.TENANT.PROJECT.COMMS} component={requireAuth(CommsPage, URL_AVAILABLE.TENANT_ONLY, OPERATIONS.LIST, MODULES.PROJECT)} />

        <Route exact path={URLS.TENANT.PROJECT.COMMS_APPROVAL} component={requireNoAuth(CommsApprovalPage, URL_AVAILABLE.TENANT_ONLY, OPERATIONS.LIST, MODULES.PROJECT)} />

        <Route exact path={URLS.TENANT.PROJECT.PUBLIC_SHARE} component={requireNoAuth(PublicSharePage, URL_AVAILABLE.TENANT_ONLY, OPERATIONS.LIST, MODULES.PROJECT)} />

        
        <Route exact path={URLS.TENANT.PROJECT.TRAINING} component={requireAuth(TrainingPage, URL_AVAILABLE.TENANT_ONLY, OPERATIONS.LIST, MODULES.PROJECT)} />
        <Route exact path={URLS.TENANT.PROJECT.SURVEY} component={requireAuth(SurveyPage, URL_AVAILABLE.TENANT_ONLY, OPERATIONS.LIST, MODULES.PROJECT)} />
        <Route exact path={URLS.TENANT.PROJECT.POLL} component={requireNoAuth(PollPage, URL_AVAILABLE.TENANT_ONLY)} />
        <Route exact path={URLS.TENANT.PROJECT.ASSESSMENT} component={requireAuth(AssessmentPage, URL_AVAILABLE.TENANT_ONLY, OPERATIONS.LIST, MODULES.PROJECT)} />
        <Route exact path={URLS.TENANT.PROJECT.REPORTS} component={requireAuth(ReportsPage, URL_AVAILABLE.TENANT_ONLY, OPERATIONS.LIST, MODULES.PROJECT)} />
        
        

       {/*  <Route exact path={URLS.COMPANY.MY_ACCOUNT} component={requireAuth(CompanyAccount)} />
        <Route exact path={URLS.COMPANY.ABOUT_ME} component={requireAuth(CompanyAboutMe)} /> */}

        {/* superAdmin Url */}
        {/* <Route exact path={URLS.SUPER_ADMIN.LOGIN} component={requireNoAuth(CompanyLogin)} /> */}
        {/* <Route exact path={URLS.SUPER_ADMIN.DASHBOARD} component={requireAuthorisation(requireAuth(AdminDashboard),['super-admin'])} /> */}
        <Route exact path={URLS.SUPER_ADMIN.USER.DASHBOARD} component={requireAuth(AdminDashboard, URL_AVAILABLE.ADMIN_ONLY)} />
        <Route exact path={URLS.SUPER_ADMIN.USER.MY_ACCOUNT} component={requireAuth(AdminMyAccount, URL_AVAILABLE.ADMIN_ONLY)} />
         <Route exact path={URLS.SUPER_ADMIN.TENANT.TENANT_LISTING} component={requireAuth(TenantListing, URL_AVAILABLE.ADMIN_ONLY)} />
         {/* <Route exact path={URLS.SUPER_ADMIN.USER.MY_ACCOUNT} component={requireAuth(SuperAdminAccount)} /> */}

        <Route path="*" component={reqLayout(Page404)} />
      </Switch>
      {/* <LoginModal/> */}
    </Router>
  );
}

export default App;
