import { IChart } from "@mrblenny/react-flow-chart";

export const TWITTER_LINK = 'https://twitter.com/home?lang=en'
export const VISA_LINK = 'https://www.visa.co.in/'
export const MASTER_CARD_LINK = 'https://www.mastercard.co.in/en-in.html'
export const GODADDY_LINK = 'https://in.godaddy.com/'

export const USER_DEFAULT_IMAGE = '/images/default_user.png'

export const DOB_MONTHS = [
  { value: 1, label: 'January' },
  { value: 2, label: 'February' },
  { value: 3, label: 'March' },
  { value: 4, label: 'April' },
  { value: 5, label: 'May' },
  { value: 6, label: 'June' },
  { value: 7, label: 'July' },
  { value: 8, label: 'August' },
  { value: 9, label: 'September' },
  { value: 10, label: 'October' },
  { value: 11, label: 'November' },
  { value: 12, label: 'December' },
]

export const HEAR_FROM_OPTIONS = [{
  label: 'A Friend',
  value: 'A Friend'
}, {
  label: 'A Sibling',
  value: 'A Sibling'
}, {
  label: 'Flyer',
  value: 'Flyer'
}, {
  label: 'Google Search',
  value: 'Google Search'
}, {
  label: 'Saw car in the street',
  value: 'Saw car in the street'
}, {
  label: 'Walk in',
  value: 'Walk in'
}]

export const YES_NO_RADIO = [{
  label: 'No',
  value: 'no'
}, {
  label: 'Yes',
  value: 'yes'
}]

export const ENABLED_DISABLED_RADIO = [{
  label: 'ENABLED',
  value: 'enabled'
}, {
  label: 'DISABLED',
  value: 'disabled'
}]


export const GENDER_RADIO = [{
  label: 'Male',
  value: 'male'
}, {
  label: 'Female',
  value: 'female'
}, {
  label: 'Other',
  value: 'other'
}]

export const ENGAGEMENT_WANT_TO_DO = [{
  label: 'One on ones',
  value: 'One on ones'
}, {
  label: 'Focus group',
  value: 'Focus group'
}, {
  label: 'Meeting',
  value: 'Meeting'
},
{
  label: 'Townhall',
  value: 'Townhall'
},
{
  label: 'Events',
  value: 'Events'
},
{
  label: 'Other',
  value: 'Other'
}
]

export const STACKHOLDER_MATRIX_CATEGORY = [{
  label: 'External',
  value: 'external'
}, {
  label: 'Keep Informed',
  value: 'keep_informed'
}, {
  label: 'Directly Impacted',
  value: 'directly_impacted'
},{
  label: 'Internal',
  value: 'internal'
}
]



export const CHANNEL_TYPE = [{
  label: 'Communique',
  value: 'Communique'
}, {
  label: 'Engagement',
  value: 'Engagement'
}, {
  label: 'Assessment',
  value: 'Assessment'
},
{
  label: 'Training',
  value: 'Training'
},
 {
  label: 'Other',
  value: 'Other'
},

]

export const APPROVAL_CATEGORY = [{
  label: 'Group',
  value: 'group'
}, {
  label: 'Individual',
  value: 'individual'
}]

export const PRIORITY = [{
  label: 'High',
  value: 'High'
}, {
  label: 'Medium',
  value: 'Medium'
}, {
  label: 'Low',
  value: 'Low'
}]

export const ENGAGEMENT_STATUS = [{
  label: 'Not yet Started',
  value: 'Not yet Started'
}, {
  label: 'In Progress',
  value: 'In Progress'
}, {
  label: 'Completed',
  value: 'Completed'
}, {
  label: 'Cancelled',
  value: 'Cancelled'
}
]


export const milestonesMonitorProgress = [
  {
    label: "Chang'd Governance Structure",
    value: 'Change Management Governance Structure'
  },
  {
    label: 'Kick-off session',
    value: 'Kick-off session'
  },
  {
    label: "Chang'd Strategy – Signed Off",
    value: 'Change Management Strategy – Signed Off'
  },
  {
    label: 'Environmental Assessment',
    value: 'Environmental Assessment'
  },
  {
    label: 'Leadership Assessment',
    value: 'Leadership Assessment'
  },
  {
    label: 'Stakeholder Assessment and Map',
    value: 'Stakeholder Assessment and Map'
  },
  {
    label: 'Impact and Gap Assessment',
    value: 'Impact and Gap Assessment'
  },
  {
    label: 'Target Audience Analysis',
    value: 'Target Audience Analysis'
  },
  {
    label: "Chang'd Plan (Comms)",
    value: 'Change Management Plan (Comms)'
  },
  {
    label: "Chang'd Plan (Engagements)",
    value: 'Change Management Plan (Engagements)'
  },
  {
    label: 'Change Agent Network Selection and Onboarding',
    value: 'Change Agent Network Selection and Onboarding'
  },
  {
    label: 'Change Agent Training',
    value: 'Change Agent Training'
  },
  {
    label: 'Change Agent Skills Transfer Plan',
    value: 'Change Agent Skills Transfer Plan'
  },
  {
    label: 'Resistance Management Plan',
    value: 'Resistance Management Plan'
  },
  {
    label: 'Leadership Coaching Plan',
    value: 'Leadership Coaching Plan'
  },
  {
    label: 'Sponsorship Plan',
    value: 'Sponsorship Plan'
  },
  {
    label: 'Gather Training Requirement',
    value: 'Gather Training Requirement'
  },
  {
    label: 'UAT Assessments',
    value: 'UAT Assessments'
  },
  {
    label: 'UAT Awareness Decks',
    value: 'UAT Awareness Decks'
  },
  {
    label: 'Training ',
    value: 'Training '
  },
  {
    label: 'Training Plan',
    value: 'Training Plan'
  },
  {
    label: 'Training Assessment results',
    value: 'Training Assessment results'
  },
  {
    label: 'Training delivery reporting',
    value: 'Training delivery reporting'
  },
  {
    label: 'Environmental Dipstick',
    value: 'Environmental Dipstick'
  },
  {
    label: 'Draft, Socialise and Finalised Awareness Communications',
    value: 'Draft, Socialise and Finalised Awareness Communications'
  },
  {
    label: 'Draft, socialise and Finalise Engagements',
    value: 'Draft, socialise and Finalise Engagements'
  },
  {
    label: 'Track Communications and Adoption Successes',
    value: 'Track Communications and Adoption Successes'
  },
  {
    label: 'Go Live Preparation Engagements',
    value: 'Go Live Preparation Engagements'
  },
  {
    label: 'Go Live readiness assessment',
    value: 'Go Live readiness assessment'
  },
  {
    label: 'Go live communications',
    value: 'Go live communications'
  },
  {
    label: 'Post Go-live communications',
    value: 'Post Go-live communications'
  },
  {
    label: 'Resistance Management Plan Reporting',
    value: 'Resistance Management Plan Reporting'
  },
  {
    label: 'Leadership Coaching Reporting',
    value: 'Leadership Coaching Reporting'
  },
  {
    label: "Chang'd Plan Reporting",
    value: 'Change Management Plan Reporting'
  },
  {
    label: 'Lessons Learnt Report',
    value: 'Lessons Learnt Report'
  },
  {
    label: "Chang'd Team Exit/Transition",
    value: 'Change Management Team Exit/Transition'
  }
]



export const PROFILE_IMAGE_ASPECT_RATIO = 1

export const ClientSetupTeamMemberType = {
  projectTeamId: 'team',
  clientRepresentativeId: 'representative'
}

export const ChartInitialState: IChart = {
  offset: {
    x: 0,
    y: 0
  },
  scale: 0.37,
  nodes: {
    node1: {
      id: "node1",
      type: "output-only",
      position: {
        x: 300,
        y: 100
      },
      ports: {
        port1: {
          id: "port1",
          type: "output",
          properties: {
            value: "yes",
          }
        },
      },
      properties: {
        label: "Select Stakeholder",
      }
    },
  },
  links: {},
  selected: {},
  hovered: {}
}

export const ChartNode = (): any => ({
  type: "input-output",
  ports: {
    port1: {
      id: "port1",
      type: "input"
    },
    port2: {
      id: "port2",
      type: "output"
    }
  },
  properties: {
    label: "Select Stakeholder",
  }
})

export const IMPACT_CHANGE_INITIATIVE = [{
  label: 'Process',
  value: 'Process'
}, {
  label: 'System',
  value: 'System'
}, {
  label: 'Workload',
  value: 'Workload'
}, {
  label: 'Skill/Knowledge',
  value: 'Skill/Knowledge'
},
{
  label: 'Responsibility',
  value: 'Responsibility'
},
{
  label: 'Reporting',
  value: 'Reporting'
},
{
  label: 'Culture/Behavioural',
  value: 'Culture/Behavioural'
},
{
  label: 'Template/Script',
  value: 'Template/Script'
}
]

export const IMPACT_INITIATIVE_REQUIRED = [{
  label: 'Training',
  value: 'Training'
}, {
  label: 'Comms',
  value: 'Comms'
}, {
  label: 'Engagement',
  value: 'Engagement'
}]

export const IMPACT_OF_CHANGE = [{
  label: 'Low',
  value: 'low'
}, {
  label: 'Medium',
  value: 'medium'
}, {
  label: 'High',
  value: 'high'
}]


export const STEPSIX_DESIGN_REQUIRED_LEVEL_OPTIONS = [
  {
    label: 'Little to no commitment',
    value: 'Little to no commitment'
  }, {
    label: 'Minimal Commitment',
    value: 'Minimal Commitment'
  }, {
    label: 'Average commitment',
    value: 'Average commitment'
  },
  {
    label: 'High commitment',
    value: 'High commitment'
  },
  {
    label: 'Exceptionally high commitment',
    value: 'Exceptionally high commitment'
  }
]