import React from 'react'
import { Redirect } from 'react-router'
import { URLS, URL_AVAILABLE } from '../../_config'
import Layout from '../layout/Layout'
import { useAuthStatus } from '../hooks/common/auth/authHook'
import { useAppUserDetailsSelector, useAppUserRolesSelector } from '../hooks/selectors/user/userSelector'
import { Permission } from '../interfaces/models/user'
import { useAppSiteInfoSelector } from '../hooks/selectors/common/commonSelector'


const requireAuth = (Component: React.ComponentType, availableTo: URL_AVAILABLE = URL_AVAILABLE.BOTH, operation = '', module = '') => {
   function AuthHoc(props: any) {
      const isAuth = useAuthStatus()
      const user = useAppUserDetailsSelector()
      const userRoles = useAppUserRolesSelector()
      const siteInfo = useAppSiteInfoSelector()

      /**
       * TODO: add role bases checking here
       */
      const checkRoles = () => {
         // console.log('user', user)
         if (!operation && !module) {
            return true
         }
         else if (!userRoles?.length) {
            return true
         }
         else {
            //return userRoles?.some((obj: Permission | undefined) => obj?.permission.module === module && obj?.permission.operation === operation)
            return true
         }
      }

      if (siteInfo && siteInfo.isTenant) {
         if (availableTo && availableTo == URL_AVAILABLE.ADMIN_ONLY) {
            // return isAuth && user ? <Redirect to={URLS.TENANT.CLIENT.CLIENT}/> : <Redirect to={URLS.TENANT.USER.LOGIN}/>
            return isAuth && user ? <Redirect to={URLS.TENANT.CLIENT.CLIENT}/> : <Redirect to={'/tenant/login'}/>
         }
      }
      if (siteInfo && siteInfo.isSuperAdmin) {
         if (availableTo && availableTo == URL_AVAILABLE.TENANT_ONLY) {
            return isAuth && user ? <Redirect to={URLS.SUPER_ADMIN.USER.DASHBOARD}/> : <Redirect to={'/login'}/>
         }
      }


      if (isAuth && user) {
         if(checkRoles()){
            return (
               <Layout>
                  <Component {...props} />
               </Layout>
            )
         }
         else{
           return  <Redirect to="/" />
         }
      }
      else {
         return <Redirect to={URLS.JOIN_US} />
      }
   }

   return AuthHoc
}
export default requireAuth
