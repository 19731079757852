import { useSelector } from 'react-redux'
import { StateExtended } from '../../../interfaces/StateExtended'

export function useAppCountryListSelector() {
  const list = useSelector((state: StateExtended) => state.globals.countries)
  return list
}

export function useAppStateListSelector() {
  const list = useSelector((state: StateExtended) => state.globals.states)
  return list
}

export function useAppCityListSelector() {
  const list = useSelector((state: StateExtended) => state.globals.cities)
  return list
}

export function useAppSiteInfoSelector() {
   const siteDetails = useSelector((state: StateExtended) => state.globals.siteInfo)
   return siteDetails
 }

 export function useAppUserNameSelector() {
    // const userName = useSelector((state: StateExtended) => state.globals.userName)
    const userName = useSelector((state: StateExtended) => state.globals)
    return userName
  }