import React from 'react';

const LazyRoleList = React.lazy(() =>
  import(/* webpackChunkName: "Home" */ './managerList')
);

const UserList = (props: Record<string, any>) => (
  <React.Suspense fallback={<h1>Loading...</h1>}>
    <LazyRoleList {...props} />
  </React.Suspense>
);

export default UserList