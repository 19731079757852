import { call, put } from 'redux-saga/effects';
import {ACTIONS, API_URL, STORAGE } from '../../_config'
import { CallApi } from '../api/callApi';

export function* tenantLogin(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.TENANT.USER.LOGIN, data);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      localStorage.setItem(STORAGE, resp.data.data.token);
      yield put({
        type: ACTIONS.USER.LOGIN,
        payload: {
          user: resp.data.data.user,
          token: resp.data.data.token,
        }
      })
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* logoutTenantUser(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.TENANT.USER.LOGOUT, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data) {
      localStorage.removeItem(STORAGE)
      yield put({
        type: ACTIONS.USER.LOGOUT,
      })
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
      action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* tenantForgotPassword(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.TENANT.USER.FORGOT_PASSWORD, data);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.success) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* tenantResetPassword(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.TENANT.USER.RESET_PASSWORD, data);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.success) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}
export function* tenantVerifyEmail(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.TENANT.USER.VERIFY_EMAIL, data);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* tenantCompanyProfileFetch(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.GET, API_URL.TENANT.USER.COMPANY_PROFILE, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.success) {
      yield put({
        type: ACTIONS.TENANT.USER.COMPANY_PROFILE,
        payload: resp.data.data,
      })
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* tenantCompanyProfileUpdate(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.TENANT.USER.COMPANY_PROFILE, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.success) {
      yield put({
        type: ACTIONS.TENANT.USER.COMPANY_PROFILE,
        payload: resp.data.data,
      })
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}


export function* tenantUserProfileUpdate(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.TENANT.USER.DETAILS, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data) {
      yield put({
        type: ACTIONS.USER.ME,
        payload: resp.data.data
      })
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* tenantUserProfileFetch(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.GET, API_URL.TENANT.USER.DETAILS, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data) {
      yield put({
        type: ACTIONS.USER.ME,
        payload: resp.data.data
      })
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* tenantUserUploadProfilePicture(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.TENANT.USER.UPLOAD_PROFILE_PICTURE, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.success) {
      yield put({
        type: ACTIONS.USER.ME,
        payload: resp.data.data
      })
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* tenantUserRemoveProfilePicture(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.TENANT.USER.DELETE_PROFILE_PICTURE, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.success) {
      yield put({
        type: ACTIONS.USER.ME,
        payload: resp.data.data
      })
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* changeTenantPassword(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.TENANT.USER.CHANGE_PASSWORD, data,true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* tenantResouceCount(action: any) {
   try {
     const data = action.payload;
     const resp = yield call(CallApi.GET, API_URL.TENANT.USER.RESORCES_COUNT, data,true);
     if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data) {
       action && action.callbackSuccess && action.callbackSuccess(resp.data);
     } else {
       action && action.callbackError && action.callbackError(resp.data);
     }
   } catch (e) {
     action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
   }
 }