import { ActionExtended } from "../../../../../_common/interfaces/ActionExtended";
import { ACTIONS } from "../../../../../_config";
import { ProjectInnerEngagements } from '../../../../../_common/interfaces/models/project'

export interface EngagementReducer {
   // engagementList: any;
   engagementDetails: ProjectInnerEngagements | null;

}

const initialState: EngagementReducer = {
   // engagementList: null,
   engagementDetails: null

};

const engagementReducer = (state = initialState, action: ActionExtended) => {
   switch (action.type) {
      // case ACTIONS.TENANT.PROJECT.ENGAGEMENT.GET_ENGAGEMENT_LIST:
      //    return {
      //       ...state,
      //       engagementList: action.payload
      //    }
      case ACTIONS.TENANT.PROJECT.ENGAGEMENT.GET_ENGAGEMENT_DETAILS:
         return {
            ...state,
            engagementDetails: action.payload
         }
      default:
         return state
   }
}

export default engagementReducer