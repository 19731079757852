import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react'
import axios from 'axios';

import { store, persistor } from './store'
import AppLoader from './_common/components/elements/full-page-loader/appLoader';
import { ACTIONS, API_URL, APP_STAGE, COMPANY_SUBDOMAIN, STORAGE } from './_config';

import { ToastContainer } from 'react-toastify'

import 'react-toastify/dist/ReactToastify.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import "react-datepicker/dist/react-datepicker.css";


function render() {
  ReactDOM.render(
    <React.Fragment>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <React.Fragment>
            <AppLoader />
            <App />
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={true}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </React.Fragment>
        </PersistGate>
      </Provider>
    </React.Fragment>,
    document.getElementById('root')
  );
}

const token = localStorage.getItem(STORAGE);

function tryLogin(url: string): Promise<any> {
  return new Promise((resolve, reject) => {
    try {
      axios({
        method: 'GET',
        url: url,
        headers: {
          Authorization: `Bearer ${token}`,
          'X-Company-Domain': COMPANY_SUBDOMAIN
        },
      }).then(success => {
        if (success && success.data && success.data.data) {
          return resolve(success.data.data)
        } else {
          return reject(null)
        }
      }).catch(err => {
        return reject(null)
      })
    } catch(e) {
      return reject(null)
    }
  })
}

if (token) {
  try {
    tryLogin(API_URL.SUPER_ADMIN.USER.DETAILS).then(userData => {
      store.dispatch({
        type: ACTIONS.USER.LOGIN,
        payload: {
          user: userData,
          token,
        }
      });
      render();
    }).catch(e => {
      tryLogin(API_URL.TENANT.USER.DETAILS).then(userData => {
        store.dispatch({
          type: ACTIONS.USER.LOGIN,
          payload: {
            user: userData,
            token,
          }
        });
        render();
      }).catch(e => {
        localStorage.removeItem(STORAGE);
        store.dispatch({
          type: ACTIONS.USER.LOGOUT,
        });
        render();
      })
    })
  } catch (e) {
    localStorage.removeItem(STORAGE);
    render();
  }
} else {
  render();
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
if (APP_STAGE === 'prod') {
  serviceWorker.register();
} else {
  serviceWorker.unregister();
}