import { call, put } from 'redux-saga/effects';
import {ACTIONS, API_URL, STORAGE } from '../../_config'
import { CallApi } from '../api/callApi';


export function* addRole(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.TENANT.ROLE.ADD_ROLE, data,true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* deleteRole(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.TENANT.ROLE.DELETE_ROLE, data,true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* getAllRoleListing(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.GET, API_URL.TENANT.ROLE.ROLE_LISTING, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data) {
      yield put({
        type: ACTIONS.TENANT.ROLE.ROLE_LISTING,
        payload: resp.data
      })
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* getAllPermission(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.GET, API_URL.TENANT.ROLE.GET_ALL_PERMISSION, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data) {
      yield put({
        type: ACTIONS.TENANT.ROLE.GET_ALL_PERMISSION,
        payload: resp.data
      })
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* assignPermission(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.TENANT.ROLE.ASSIGN_ROLE_PERMISSION, data,true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* getRoleDetailsWithPermission(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.GET, API_URL.TENANT.ROLE.GET_ROLE_PERMISSION_DETAILS, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data) {
      yield put({
        type: ACTIONS.TENANT.ROLE.GET_ROLE_PERMISSION_DETAILS,
        payload: resp.data
      })
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}