import React from 'react';

const LazyUnauthorized = React.lazy(() =>
  import(/* webpackChunkName: "Page404" */ './unauthorized')
);

const Unauthorized = (props: Record<string, any>) => (
  <React.Suspense fallback={<h1>Loading...</h1>}>
    <LazyUnauthorized {...props} />
  </React.Suspense>
);

export default Unauthorized