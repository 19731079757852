import React from 'react';

const LazyCompanyListing = React.lazy(() =>
  import(/* webpackChunkName: "Home" */ './companyListing')
);

const TenantListing = (props: Record<string, any>) => (
  <React.Suspense fallback={<h1>Loading...</h1>}>
    <LazyCompanyListing {...props} />
  </React.Suspense>
);

export default TenantListing