import { ActionExtended } from '../../../../_common/interfaces/ActionExtended';
import { Role, Permission,RoleDetailsWithPermission } from '../../../../_common/interfaces/models/role';
import { ACTIONS } from '../../../../_config'

export interface RoleReducer {
  roleData: Role | null;
  permissionData: Permission[] | null;
  roleDetailsWithPermission: RoleDetailsWithPermission|null;
}

const initialState: RoleReducer = {
  roleData: null,
  permissionData: null,
  roleDetailsWithPermission:null
};

const roleReducer = (state = initialState, action: ActionExtended) => {
  switch (action.type) {
    case ACTIONS.TENANT.ROLE.ROLE_LISTING:
      return {
        ...state,
        roleData: action.payload.data
      };
    case ACTIONS.TENANT.ROLE.GET_ALL_PERMISSION:
      // console.log('inconning permission role reducer', action.payload.data)

      if (action.payload && action.payload.data && action.payload.data.data && action.payload.data.data.length) {
        let permissionArray = action.payload.data.data;
        var formattedPermission = <any>[];
        var k = 0;
        // if (i == 0) {
        //   var innerDataObj = {
        //     id: permissionArray[i].id,
        //     operation: permissionArray[i].operation,
        //     module: permissionArray[i].module,
        //     createdAt: permissionArray[i].createdAt,
        //     deletedAt: permissionArray[i].deletedAt,
        //     updatedAt: permissionArray[i].updatedAt,
        //   }
        //   var obj = {
        //     module: permissionArray[i].module,
        //     data: [innerDataObj]
        //   }
        //   formattedPermission.push(obj)
        // }

        for (var i = 0; i < permissionArray.length; i++) {
          if (formattedPermission && formattedPermission.length) {

            if (formattedPermission[k].module == permissionArray[i].module) {
              var innerDataObj = {
                id: permissionArray[i].id,
                operation: permissionArray[i].operation,
                module: permissionArray[i].module,
                createdAt: permissionArray[i].createdAt,
                deletedAt: permissionArray[i].deletedAt,
                updatedAt: permissionArray[i].updatedAt,
              }
              formattedPermission[k].data.push(innerDataObj)
            } else {
              k++;
              var innerDataObj = {
                id: permissionArray[i].id,
                operation: permissionArray[i].operation,
                module: permissionArray[i].module,
                createdAt: permissionArray[i].createdAt,
                deletedAt: permissionArray[i].deletedAt,
                updatedAt: permissionArray[i].updatedAt,
              }
              var obj = {
                module: permissionArray[i].module,
                data: [innerDataObj]
              }
              formattedPermission.push(obj)
            }

          } else {
            var innerDataObj = {
              id: permissionArray[i].id,
              operation: permissionArray[i].operation,
              module: permissionArray[i].module,
              createdAt: permissionArray[i].createdAt,
              deletedAt: permissionArray[i].deletedAt,
              updatedAt: permissionArray[i].updatedAt,
            }
            var obj = {
              module: permissionArray[i].module,
              data: [innerDataObj]
            }
            formattedPermission.push(obj)
          }
        }
        // console.log('after formatting data==', formattedPermission)
      }

      return {
        ...state,
        // permissionData: action.payload.data
        permissionData: formattedPermission
      };
      case ACTIONS.TENANT.ROLE.GET_ROLE_PERMISSION_DETAILS:
      return {
        ...state,
        roleDetailsWithPermission: action.payload.data
      };
    default:
      return state;
  }
};

export default roleReducer;
