import { call, put } from 'redux-saga/effects';
import { ACTIONS, API_URL, SAGA_ACTIONS, STORAGE } from '../../_config';
import { CallApi } from '../api/callApi';

export function* addNewVersionProjectFiles(action: any) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.POST, API_URL.TENANT.PROJECT.INPUTS.UPLOAD_VERSION_CONTROL_FILES_FROM_INPUT_SECTION, data, true);
      if (resp.status >= 200 && resp.status < 300 && resp.data) {
         action && action.callbackSuccess && action.callbackSuccess(resp.data);
      } else {
         action && action.callbackError && action.callbackError(resp.data);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
   }
}

export function* uploadDocumentFiles(action: any) {
   try {
     const data = action.payload;
     const resp = yield call(CallApi.POST, API_URL.TENANT.PROJECT.INPUTS.UPLOAD_DOCUMENT, data, true);
     if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data) {
       action && action.callbackSuccess && action.callbackSuccess(resp.data);
     } else {
       action && action.callbackError && action.callbackError(resp.data);
     }
   } catch (e) {
     action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
   }
 }