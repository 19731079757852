import { ActionExtended } from "../../../../../_common/interfaces/ActionExtended";
import { ACTIONS } from "../../../../../_config";
import {SurveyList, SurveyDetails} from '../../../../../_common/interfaces/models/survey';

export interface SurveyReducer {
   surveyList: SurveyList[] | null;
   surveyDetails: SurveyDetails | null;

}

const initialState: SurveyReducer = {
   surveyList: null,
   surveyDetails:null
};

const surveyReducer = (state = initialState, action: ActionExtended) => {
   switch (action.type) {
      case ACTIONS.TENANT.PROJECT.SURVEY.GET_ALL_SURVEY:
         return {
            ...state,
            surveyList: action.payload
         }
         case ACTIONS.TENANT.PROJECT.SURVEY.GET_SURVEY_DETAILS:
            return {
               ...state,
               surveyDetails: action.payload
            }
      default:
         return state
   }
}

export default surveyReducer