import { ActionExtended } from "../../../../_common/interfaces/ActionExtended";
import { ProjectUser } from "../../../../_common/interfaces/ApiReqRes";
import { ProjectList, ProjectChangeQuestionMaster, ProjectMethodologyMaster, Project, ProjectStakeholderList } from "../../../../_common/interfaces/models/project";
import { ACTIONS } from "../../../../_config";

export enum DefineChangeSetupStep {
   QUESTION_SIZE = 1,
   CHOOSE_MODEL = 2,
   METHODOLOGY = 3,
   BUSINESSCASE = 4,
   DESIGN = 5,
   PERCEPTION = 6
}


export interface ProjectReducer {
   projectList: ProjectList | null,
   draftProjectList: ProjectList | null,
   setupId: number,
   setupStep: number,
   defineChangeStep: number,
   projectChangeQuestionMaster: ProjectChangeQuestionMaster[] | null,
   projectMethodologyMaster: ProjectMethodologyMaster[] | null,
   projectDetails: Project | null,
   stackHolderList: ProjectStakeholderList | null,
   projectUsersList: any,
}

const initialState: ProjectReducer = {
   projectList: null,
   draftProjectList: null,
   setupId: 0,
   setupStep: 1,
   defineChangeStep: 1,
   projectChangeQuestionMaster: null,
   projectMethodologyMaster: null,
   projectDetails: null,
   stackHolderList: null,
   projectUsersList: null
};

const projectReducer = (state = initialState, action: ActionExtended) => {
   switch (action.type) {
      case ACTIONS.TENANT.PROJECT.PROJECT_LIST:
         return {
            ...state,
            projectList: action.payload
         }
      case ACTIONS.TENANT.PROJECT.SETUP_PROJECT_ID:
         return {
            ...state,
            setupId: action.payload
         }
      case ACTIONS.TENANT.PROJECT.CHANGE_SETUP_STEP:       
         return {
            ...state,
            setupStep: action.payload
         }
      case ACTIONS.TENANT.PROJECT.DRAFT_LIST:
         return {
            ...state,
            draftProjectList: action.payload
         }
      case ACTIONS.TENANT.PROJECT.CHANGE_DEFINE_CHANGE_SETUP_STEP:
         return {
            ...state,
            defineChangeStep: action.payload
         }
      case ACTIONS.TENANT.PROJECT.PROJECT_CHANGE_QUESTION_MASTER:
         return {
            ...state,
            projectChangeQuestionMaster: action.payload
         }
      case ACTIONS.TENANT.PROJECT.GET_PROJECT_METHODOLOGY_MASTER:
         return {
            ...state,
            projectMethodologyMaster: action.payload
         }
      case ACTIONS.TENANT.PROJECT.GET_PARTICULAR_PROJECT_DETAILS:
         return {
            ...state,
            projectDetails: action.payload
         }
      case ACTIONS.TENANT.PROJECT.GET_STAKEHOLDERS:
         return {
            ...state,
            stackHolderList: action.payload
         }
      case ACTIONS.TENANT.PROJECT.GET_PROJECTUSERS:
         return {
            ...state,
            projectUsersList: action.payload
         }

      default:
         return state
   }
}

export default projectReducer