import { ActionExtended } from "../../../../../_common/interfaces/ActionExtended";
import { ACTIONS } from "../../../../../_config";


export interface InputsReducer {
   isInputsShowWithDetails: any| null,
   
}

const initialState: InputsReducer = {
   isInputsShowWithDetails: null
};

const inputsReducer = (state = initialState, action: ActionExtended) => {
   switch (action.type) {
      case ACTIONS.TENANT.PROJECT.INPUTS.IS_INPUT_SHOW_WITH_DETAILS:
         return {
            ...state,
            isInputsShowWithDetails: action.payload
         }
      
      default:
         return state
   }
}

export default inputsReducer