import { call, put } from 'redux-saga/effects';
import { ACTIONS, API_URL, SAGA_ACTIONS, STORAGE } from '../../_config';
import { CallApi } from '../api/callApi';

// export function* getAllEngagementList(action: any) {
//    try {
//       const data = action.payload;
//       const resp = yield call(CallApi.GET, API_URL.TENANT.PROJECT.ENGAGEMENT.GET_ENGAGEMENT_LIST, data, true);
//       if (resp.status >= 200 && resp.status < 300 && resp.data) {
//          yield put({
//             type: ACTIONS.TENANT.PROJECT.ENGAGEMENT.GET_ENGAGEMENT_LIST,
//             payload: resp.data.data,
//          })
//          action && action.callbackSuccess && action.callbackSuccess(resp.data);
//       } else {
//          action && action.callbackError && action.callbackError(resp.data);
//       }
//    } catch (e) {
//       action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
//    }
// }
export function* addEngagement(action: any) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.POST, API_URL.TENANT.PROJECT.ENGAGEMENT.ADD_ENGAGEMENT, data, true);
      if (resp.status >= 200 && resp.status < 300 && resp.data) {
         action && action.callbackSuccess && action.callbackSuccess(resp.data);
      } else {
         action && action.callbackError && action.callbackError(resp.data);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
   }
}

export function* deleteEngagement(action: any) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.POST, API_URL.TENANT.PROJECT.ENGAGEMENT.DELETE_ENGAGEMENT, data, true);
      if (resp.status >= 200 && resp.status < 300 && resp.data) {
         action && action.callbackSuccess && action.callbackSuccess(resp.data);
      } else {
         action && action.callbackError && action.callbackError(resp.data);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
   }
}

export function* duplicateEngagement(action: any) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.POST, API_URL.TENANT.PROJECT.ENGAGEMENT.DUPLICATE_ENGAGEMENT, data, true);
      if (resp.status >= 200 && resp.status < 300 && resp.data) {
         action && action.callbackSuccess && action.callbackSuccess(resp.data);
      } else {
         action && action.callbackError && action.callbackError(resp.data);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
   }
}

export function* updateEngagementStatus(action: any) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.PATCH, API_URL.TENANT.PROJECT.ENGAGEMENT.UPDATE_STATUS, data, true);
      if (resp.status >= 200 && resp.status < 300 && resp.data) {
         action && action.callbackSuccess && action.callbackSuccess(resp.data);
      } else {
         action && action.callbackError && action.callbackError(resp.data);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
   }
}

export function* updateEngagement(action: any) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.POST, API_URL.TENANT.PROJECT.ENGAGEMENT.UPDATE_ENGAGEMENT, data, true);
      if (resp.status >= 200 && resp.status < 300 && resp.data) {
         action && action.callbackSuccess && action.callbackSuccess(resp.data);
      } else {
         action && action.callbackError && action.callbackError(resp.data);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
   }
}

export function* getEngagementDetails(action: any) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.GET, API_URL.TENANT.PROJECT.ENGAGEMENT.GET_ENGAGEMENT_DETAILS, data, true);
      if (resp.status >= 200 && resp.status < 300 && resp.data) {
         yield put({
            type: ACTIONS.TENANT.PROJECT.ENGAGEMENT.GET_ENGAGEMENT_DETAILS,
            payload: resp.data.data,
         })
         action && action.callbackSuccess && action.callbackSuccess(resp.data);
      } else {
         action && action.callbackError && action.callbackError(resp.data);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
   }
}

