import { FileType } from './file';
import { City, Confirmation, Country, Model, State, Status } from './_common'


export interface InnerPermission extends Model {
   module: string;
   operation: string;
}
export interface Permission extends Model {
   permission: InnerPermission;
   permissionId: number;
   roleId: number;
}

export interface Role extends Model {
   name: string;
   permissions?: Permission[];
   type: number;
   slug: string;
}
export interface Host extends Model {
   subDomain: string,
   isActive: boolean,
   isBlackList: boolean,
   isAvailable: boolean,
   fullUrl: string;
}

export enum UserAboutMeType {
   INDIVIDUAL = 'individual',
   COMPANY = 'company'
}
export enum UserTypeEnum {
   COMPANY = 'company',
   INDIVIDUAL = 'individual',
   CHANGE_MANAGER = 'change-manager',
   COMPANY_CONTACT = 'company-contact',
   STAKEHOLDER = 'stakeholder'
}

export interface UserType extends Model {
   type: UserTypeEnum,
}

export interface UserAboutMe extends Model {
   text: string;
   type: UserAboutMeType
}

export interface User extends Model {
   email: string;
   emailVerified: boolean;
   firstName: string;
   fullName: string;
   hostId?: any;
   isActive: boolean;
   isCompanySetupDone: boolean
   lastName: string;
   remarks: any;
   role: Role;
   roleId: number;
   slug: string;
   aboutMe?: UserAboutMe,
   aboutMeId?: number,
   city?: City,
   cityId?: number,
   contactNumber?: string,
   dialCodeContactNumber?: string;
   country?: Country,
   countryId?: number,
   description?: string,
   fbId?: number,
   fbToken?: string,
   gmailId?: number,
   gmailToken?: string,
   isFromMaster?: boolean,
   isTenant?: boolean,
   isPasswordSet?: boolean,
   officeNumber?: string,
   dialCodeOfficeNumber?: string,
   profilePic?: FileType,
   profilePicId?: number,
   state?: State,
   stateId?: number,
   userType?: UserType,
   userTypeId?: number,
   zip?: string,
   addressLine1?: string,
   addressLine2?: string
}

export interface ChangeManagerListing extends Model {
   data: User[];
   total: number;
   page: number;
   perPage: number;
   totalPage: number;
   nextPage: string | null;
   prevPage: string | null;
}