import { fork, take, takeLatest, all, takeEvery, takeLeading } from 'redux-saga/effects';
import { SAGA_ACTIONS } from '../_config';

import {
   loginUser,
   getUserProfile,
   logoutUser,
   register,
   checkSubDomainAvailablity,
   verifyEmail,
   checkLogin,
   forgotPassword,
   resetPassword,
   changeAdminPassword,
   updateProfile,
   getAllRegisteredCompany,
   changeTenantStatus,
   adminUserUploadProfilePicture,
   adminUserRemoveProfilePicture,
   getAssignedResources,
   searchAboutMe, 
   getModulePermission,
   updateTenantModulePermission,
   resendVerificationEmail
} from './super-admin/userSaga'

import {
   tenantLogin,
   logoutTenantUser,
   tenantForgotPassword,
   tenantResetPassword,
   tenantVerifyEmail,
   changeTenantPassword,
   tenantCompanyProfileFetch,
   tenantCompanyProfileUpdate,
   tenantUserProfileUpdate,
   tenantUserProfileFetch,
   tenantUserUploadProfilePicture,
   tenantUserRemoveProfilePicture,
   tenantResouceCount,
} from './tenant/userSaga'

import {
   addRole,
   deleteRole,
   getAllRoleListing,
   getAllPermission,
   assignPermission,
   getRoleDetailsWithPermission,
} from './tenant/roleSaga'

import {
   getContactType,
   searchUserByEmail,
   addClientWithUser,
   addClientObjective,
   getParticularClientWithUser,
   setClientUserToTeamMembers,
   assignRoleToUser,
   getAllClientLists,
   getAllDraftClientLists,
   getClientDetails,
   completeClientSetup,
   getProjectsTimeline
} from './tenant/clientSaga'

import {
   uploadFiles,
   searchCountries,
   searchStates,
   searchCities,
   deleteFiles,
   getFiles,
   tenantAboutMeListing,
   getSiteInfo,
   publicShareLink,
   publicGetModuleDetails,
   getTenantModulePermission, searchDialcode
} from './tenant/commonSaga';

import { deleteUser, tenantAddUser, tenantUserListing } from './tenant/changeMangerSaga';

import {
   addEditStakeholder,
   addNewProjectUser,
   addProject,
   deleteDraftedProject,
   addProjectFiles,
   removeProjectFiles,
   addUserToProject,
   availableUsers,
   changeProjectRole,
   getProjectStakeholders,
   projectDetail,
   projectDraftList,
   projectList,
   removeUserFromProject,
   searchProjectUsers,
   setupFinalProject,
   updateProjectUser,
   uploadExcelFile,
   projectChangeQuestionMaster,
   projectCustomQuestionAnswer,
   saveUpdateProjectChangeQuestion,
   getProjectMethodologyMaster,
   updateProjectMethodology, searchProjectMethodologyQuestions, uploadProjectExcelFile
} from './tenant/projectSaga';

import {
   addNewVersionProjectFiles,
   uploadDocumentFiles
} from './tenant/inputSaga';

import {
   getAllPredefineAssmentList,
   addAssessment,
   getAllAssmentList,
   getPredefineCategoryWiseQuestionList,
   updatePredefineCategoryWiseQuestionAnswer,
   deleteAssessment,
   publicGetAssessmentDetails,
   publicSaveAssessmentResponse, saveAssessmentQuestions, deleteAssessmentSection, renameAssessmentQuestion, deleteAssessmentQuestion, renameAssessmentSection
} from './tenant/assessmentSaga';

import {
   getAllCommsList,
   getSingleCommsDetails,
   addEditComms,
   deleteComms,
   sendForApproval,
   getApprovalDetails,
   saveApprovalWithComments
} from './tenant/commsSaga';

import {
   getAllTrainingList,
   getSingleTrainingDetails,
   addEditTraining,
   deleteTraining,
   downloadTrainingExcel, shareTrainingData, publicGetTrainingShareDetails
} from './tenant/trainingSaga';

import {
   // getAllEngagementList,
   addEngagement,
   deleteEngagement,
   duplicateEngagement,
   updateEngagementStatus,
   getEngagementDetails,
   updateEngagement
  
} from './tenant/engagementSaga';

import {
   addEditOrganogram,
   getOrganogramDetails,
   getAllOrganograms,
   getDepartmentAndDivision,
   removeOrganogram,
} from './tenant/organogramSaga';

import {
   addEditStakeholderMatrix,
   getStakeholderMatrixDetails,
   getAllStakeholderMatrix,
   removeStakeholderMatrix,
   removeStakeholderFromMatrix,
} from './tenant/organogramSaga';

import {
   addEditInfluenceMap,
   getInfluenceMapDetails,
   getAllInfluenceMap,
   removeInfluenceMap,
   removeStakeholderFromInfluenceMap,shareOrganogram,copyOrganogram
} from './tenant/organogramSaga';

import {
   addEditChangePlan,
   addToChangePlan,
   getChangePlanDetails,
   getAllChangePlans,
   removeChangePlan,
   updateChangePlanStatus,
   updateChangePlanStartActivityStatus,
   getChangePlanTimeline,
   getTodaysTimeline,
} from './tenant/changePlanSaga';

import {
   addImpact,
   deleteImpact,
   getAllImpactList,
   getImpactDetails,
   downloadImpactExcel,
   editImpactNameDescription
} from './tenant/impactSaga';

import {
   getAllSurveyList,
   addSurvey,
   deleteSurvey,
   getSurveyDetails,
   getSurveyPoll, savePoll, sharePoll
} from './tenant/surveySaga';

import {
   addReport,deleteReport,getAllReports,getReport,publicGetReportChangeStory
  , addStatusReport,getAllStatusReports,getStatusReport,
   getChangeLoadReport,
   deleteStatusReport,
   addChangeReport,getAllChangeReports,getChangeReport,deleteChangeReport, downloadReport
} from './tenant/reportsSaga';

export function* sagas() {
   yield all([

      // super Admin user saga
      takeLatest(SAGA_ACTIONS.SUPER_ADMIN.USER.LOGIN, loginUser),
      takeLatest(SAGA_ACTIONS.SUPER_ADMIN.USER.DETAILS, getUserProfile),
      takeLatest(SAGA_ACTIONS.SUPER_ADMIN.USER.LOGOUT, logoutUser),
      takeLatest(SAGA_ACTIONS.SUPER_ADMIN.USER.REGISTRATION, register),
      takeLatest(SAGA_ACTIONS.SUPER_ADMIN.USER.UPLOAD_PROFILE_PICTURE, adminUserUploadProfilePicture),
      takeLatest(SAGA_ACTIONS.SUPER_ADMIN.USER.DELETE_PROFILE_PICTURE, adminUserRemoveProfilePicture),

      takeLatest(SAGA_ACTIONS.SUPER_ADMIN.COMMON.CHECK_SUBDOMAIN_AVAILABILITY, checkSubDomainAvailablity),
      takeLatest(SAGA_ACTIONS.SUPER_ADMIN.COMMON.SITE_INFO, getSiteInfo),
      takeLatest(SAGA_ACTIONS.SUPER_ADMIN.COMMON.SEARCH_ABOUT_ME, searchAboutMe),
      takeLatest(SAGA_ACTIONS.SUPER_ADMIN.USER.GET_RESOURCES, getAssignedResources),
      takeLatest(SAGA_ACTIONS.SUPER_ADMIN.USER.GET_MODULE_PERMISSION, getModulePermission),
      takeLatest(SAGA_ACTIONS.SUPER_ADMIN.USER.UPDATE_TENANT_MODULE_PERMISSION, updateTenantModulePermission),
      takeLatest(SAGA_ACTIONS.SUPER_ADMIN.USER.RESEND_VERIFICATION_EMAIL, resendVerificationEmail),

      takeLatest(SAGA_ACTIONS.SUPER_ADMIN.USER.VERIFY_EMAIL, verifyEmail),
      takeLatest(SAGA_ACTIONS.SUPER_ADMIN.USER.CHECK_LOGIN, checkLogin),
      takeLatest(SAGA_ACTIONS.SUPER_ADMIN.USER.FORGOT_PASSWORD, forgotPassword),
      takeLatest(SAGA_ACTIONS.SUPER_ADMIN.USER.RESET_PASSWORD, resetPassword),
      takeLatest(SAGA_ACTIONS.SUPER_ADMIN.USER.CHANGE_PASSWORD, changeAdminPassword),
      takeLatest(SAGA_ACTIONS.SUPER_ADMIN.USER.UPDATE_PROFILE, updateProfile),

      takeLatest(SAGA_ACTIONS.SUPER_ADMIN.TENANT.GET_ALL_REGISTERED_COMPANY_LIST, getAllRegisteredCompany),
      takeLatest(SAGA_ACTIONS.SUPER_ADMIN.TENANT.CHANGE_REGISTERED_COMPANY_STATUS, changeTenantStatus),


      // Tenant SAGA
      // user
      takeLatest(SAGA_ACTIONS.TENANT.USER.LOGIN, tenantLogin),
      takeLatest(SAGA_ACTIONS.TENANT.USER.LOGOUT, logoutTenantUser),
      takeLatest(SAGA_ACTIONS.TENANT.USER.FORGOT_PASSWORD, tenantForgotPassword),
      takeLatest(SAGA_ACTIONS.TENANT.USER.RESET_PASSWORD, tenantResetPassword),
      takeLatest(SAGA_ACTIONS.TENANT.USER.VERIFY_EMAIL, tenantVerifyEmail),
      takeLatest(SAGA_ACTIONS.TENANT.USER.CHANGE_PASSWORD, changeTenantPassword),

      takeLatest(SAGA_ACTIONS.TENANT.USER.FETCH_COMPANY_DETAILS, tenantCompanyProfileFetch),
      takeLatest(SAGA_ACTIONS.TENANT.USER.UPDATE_COMPANY_DETAILS, tenantCompanyProfileUpdate),
      takeLatest(SAGA_ACTIONS.TENANT.USER.UPDATE_PROFILE, tenantUserProfileUpdate),
      takeLatest(SAGA_ACTIONS.TENANT.USER.RESORCES_COUNT, tenantResouceCount),


      //change manager
      takeLatest(SAGA_ACTIONS.TENANT.CH_MANGER.ADD_NEW_USER, tenantAddUser),
      takeLatest(SAGA_ACTIONS.TENANT.CH_MANGER.GET_USER_LIST, tenantUserListing),
      takeLatest(SAGA_ACTIONS.TENANT.CH_MANGER.DELETE_USER, deleteUser),

      takeLatest(SAGA_ACTIONS.TENANT.USER.ME, tenantUserProfileFetch),
      takeLatest(SAGA_ACTIONS.TENANT.USER.UPLOAD_PROFILE_PICTURE, tenantUserUploadProfilePicture),
      takeLatest(SAGA_ACTIONS.TENANT.USER.DELETE_PROFILE_PICTURE, tenantUserRemoveProfilePicture),



      // client
      takeLatest(SAGA_ACTIONS.TENANT.CLIENT.GET_CONTACT_TYPE, getContactType),
      takeLatest(SAGA_ACTIONS.TENANT.CLIENT.SEARCH_USER_BY_EMAIL, searchUserByEmail),
      takeLatest(SAGA_ACTIONS.TENANT.CLIENT.ADD_CLIENT_WITH_USER, addClientWithUser),
      takeLatest(SAGA_ACTIONS.TENANT.CLIENT.ADD_CLIENT_OBJECTIVE, addClientObjective),
      takeLatest(SAGA_ACTIONS.TENANT.CLIENT.GET_PARTICULAR_CLIENT_WITH_USERS, getParticularClientWithUser),
      takeLatest(SAGA_ACTIONS.TENANT.CLIENT.SET_CLIENT_USERS_TO_TEAM_MEMBERS, setClientUserToTeamMembers),
      takeLatest(SAGA_ACTIONS.TENANT.CLIENT.ASSIGN_ROLE_TO_USERS, assignRoleToUser),
      takeLatest(SAGA_ACTIONS.TENANT.CLIENT.GET_CLIENT_LISTS, getAllClientLists),
      takeLatest(SAGA_ACTIONS.TENANT.CLIENT.GET_DRAFT_CLIENT_LISTS, getAllDraftClientLists),
      takeLatest(SAGA_ACTIONS.TENANT.CLIENT.GET_CLIENT_DETAILS, getClientDetails),
      takeLatest(SAGA_ACTIONS.TENANT.CLIENT.SETUP_CLIENT_COMPLETE, completeClientSetup),
      takeEvery(SAGA_ACTIONS.TENANT.CLIENT.GET_PROJECTS_TIMELINE, getProjectsTimeline),

      // role saga
      takeLatest(SAGA_ACTIONS.TENANT.ROLE.ADD_ROLE, addRole),
      takeLatest(SAGA_ACTIONS.TENANT.ROLE.DELETE_ROLE, deleteRole),
      takeLatest(SAGA_ACTIONS.TENANT.ROLE.ROLE_LISTING, getAllRoleListing),
      takeLatest(SAGA_ACTIONS.TENANT.ROLE.GET_ALL_PERMISSION, getAllPermission),
      takeLatest(SAGA_ACTIONS.TENANT.ROLE.ASSIGN_ROLE_PERMISSION, assignPermission),
      takeLatest(SAGA_ACTIONS.TENANT.ROLE.GET_ROLE_PERMISSION_DETAILS, getRoleDetailsWithPermission),

      //project
      takeLatest(SAGA_ACTIONS.TENANT.PROJECT.ADD_PROJECT, addProject),
      takeLatest(SAGA_ACTIONS.TENANT.PROJECT.DELETE_DRAFTED_PROJECT, deleteDraftedProject),
      takeLatest(SAGA_ACTIONS.TENANT.PROJECT.PROJECT_LIST, projectList),
      takeLatest(SAGA_ACTIONS.TENANT.PROJECT.DRAFT_LIST, projectDraftList),
      takeLatest(SAGA_ACTIONS.TENANT.PROJECT.ADD_PROJECT_FILES, addProjectFiles),
      takeLatest(SAGA_ACTIONS.TENANT.PROJECT.REMOVE_PROJECT_FILES, removeProjectFiles),
      takeLatest(SAGA_ACTIONS.TENANT.PROJECT.PROJECT_DETAIL, projectDetail),
      takeLatest(SAGA_ACTIONS.TENANT.PROJECT.UPLOAD_EXCEL_FILE, uploadExcelFile),
      takeLatest(SAGA_ACTIONS.TENANT.PROJECT.UPLOAD_PROJECT_EXCEL_FILE, uploadProjectExcelFile),
      takeLatest(SAGA_ACTIONS.TENANT.PROJECT.ADD_EDIT_STAKEHOLDER, addEditStakeholder),
      takeLatest(SAGA_ACTIONS.TENANT.PROJECT.AVAIL_PROJECT_USERS, availableUsers),
      takeLatest(SAGA_ACTIONS.TENANT.PROJECT.SEARCH_PROJECT_USERS, searchProjectUsers),
      takeLatest(SAGA_ACTIONS.TENANT.PROJECT.ADD_USER_TO_PROJECT, addUserToProject),
      takeLatest(SAGA_ACTIONS.TENANT.PROJECT.REMOVE_USER_FROM_PROJECT, removeUserFromProject),
      takeLatest(SAGA_ACTIONS.TENANT.PROJECT.ADD_NEW_PROJECT_USER, addNewProjectUser),

      takeLatest(SAGA_ACTIONS.TENANT.PROJECT.CHANGE_PROJECT_USER_ROLE, changeProjectRole),
      takeEvery(SAGA_ACTIONS.TENANT.PROJECT.GET_STAKEHOLDERS, getProjectStakeholders),
      takeLatest(SAGA_ACTIONS.TENANT.PROJECT.UPDATE_PROJECT_USER, updateProjectUser),
      takeLatest(SAGA_ACTIONS.TENANT.PROJECT.SETUP_FINAL, setupFinalProject),
      takeLatest(SAGA_ACTIONS.TENANT.PROJECT.PROJECT_CHANGE_QUESTION_MASTER, projectChangeQuestionMaster),
      takeLatest(SAGA_ACTIONS.TENANT.PROJECT.PROJECT_CUSTOM_QUESTION_ANSWER,  projectCustomQuestionAnswer),
     
      takeEvery(SAGA_ACTIONS.TENANT.PROJECT.PROJECT_CHANGE_QUESTION_SAVE_UPDATE, saveUpdateProjectChangeQuestion),
      takeLatest(SAGA_ACTIONS.TENANT.PROJECT.GET_PROJECT_METHODOLOGY_MASTER, getProjectMethodologyMaster),
      takeLatest(SAGA_ACTIONS.TENANT.PROJECT.SET_PROJECT_METHODOLOGY, updateProjectMethodology),
      takeLatest(SAGA_ACTIONS.TENANT.PROJECT.SEARCH_PROJECT_METHODOLOGY_QUESTIONS, searchProjectMethodologyQuestions),
      
      // INPUTS
      takeLatest(SAGA_ACTIONS.TENANT.PROJECT.INPUTS.UPLOAD_VERSION_CONTROL_FILES_FROM_INPUT_SECTION, addNewVersionProjectFiles),
      takeLatest(SAGA_ACTIONS.TENANT.PROJECT.INPUTS.UPLOAD_DOCUMENT, uploadDocumentFiles),

      //Engagement
      // takeLatest(SAGA_ACTIONS.TENANT.PROJECT.ENGAGEMENT.GET_ENGAGEMENT_LIST, getAllEngagementList),
      takeLatest(SAGA_ACTIONS.TENANT.PROJECT.ENGAGEMENT.ADD_ENGAGEMENT, addEngagement),
      takeLatest(SAGA_ACTIONS.TENANT.PROJECT.ENGAGEMENT.DELETE_ENGAGEMENT, deleteEngagement),

      takeLatest(SAGA_ACTIONS.TENANT.PROJECT.ENGAGEMENT.DUPLICATE_ENGAGEMENT, duplicateEngagement),
      takeLatest(SAGA_ACTIONS.TENANT.PROJECT.ENGAGEMENT.UPDATE_STATUS, updateEngagementStatus),
      takeLatest(SAGA_ACTIONS.TENANT.PROJECT.ENGAGEMENT.UPDATE_ENGAGEMENT, updateEngagement),
      takeLatest(SAGA_ACTIONS.TENANT.PROJECT.ENGAGEMENT.GET_ENGAGEMENT_DETAILS, getEngagementDetails),
     


      // ORGANOGRAM
      takeLatest(SAGA_ACTIONS.TENANT.PROJECT.ORGANOGRAM.GET_ORGANOGRAM, getOrganogramDetails),
      takeLatest(SAGA_ACTIONS.TENANT.PROJECT.ORGANOGRAM.ADD_EDIT_ORGANOGRAM, addEditOrganogram),
      takeLatest(SAGA_ACTIONS.TENANT.PROJECT.ORGANOGRAM.GET_ALL_ORGANOGRAMS, getAllOrganograms),
      takeEvery(SAGA_ACTIONS.TENANT.PROJECT.ORGANOGRAM.GET_DEPARTMENT_AND_DIVISION, getDepartmentAndDivision),
      takeLatest(SAGA_ACTIONS.TENANT.PROJECT.ORGANOGRAM.REMOVE_ORGANOGRAM, removeOrganogram),


      // STAKEHOLDER MATRIX
      takeLatest(SAGA_ACTIONS.TENANT.PROJECT.ORGANOGRAM.GET_STAKEHOLDER_MATRIX, getStakeholderMatrixDetails),
      takeLatest(SAGA_ACTIONS.TENANT.PROJECT.ORGANOGRAM.ADD_EDIT_STAKEHOLDER_MATRIX, addEditStakeholderMatrix),
      takeLatest(SAGA_ACTIONS.TENANT.PROJECT.ORGANOGRAM.GET_ALL_STAKEHOLDER_MATRIX, getAllStakeholderMatrix),
      takeLatest(SAGA_ACTIONS.TENANT.PROJECT.ORGANOGRAM.REMOVE_STAKEHOLDER_MATRIX, removeStakeholderMatrix),
      takeLatest(SAGA_ACTIONS.TENANT.PROJECT.ORGANOGRAM.REMOVE_STAKEHOLDER_FROM_STAKEHOLDER_MATRIX, removeStakeholderFromMatrix),


      // INFLUENCE MAP
      takeLatest(SAGA_ACTIONS.TENANT.PROJECT.ORGANOGRAM.GET_INFLUENCE_MAP, getInfluenceMapDetails),
      takeLatest(SAGA_ACTIONS.TENANT.PROJECT.ORGANOGRAM.ADD_EDIT_INFLUENCE_MAP, addEditInfluenceMap),
      takeLatest(SAGA_ACTIONS.TENANT.PROJECT.ORGANOGRAM.GET_ALL_INFLUENCE_MAP, getAllInfluenceMap),
      takeLatest(SAGA_ACTIONS.TENANT.PROJECT.ORGANOGRAM.REMOVE_INFLUENCE_MAP, removeInfluenceMap),
      takeLatest(SAGA_ACTIONS.TENANT.PROJECT.ORGANOGRAM.REMOVE_STAKEHOLDER_FROM_INFLUENCE_MAP, removeStakeholderFromInfluenceMap),
      takeLatest(SAGA_ACTIONS.TENANT.PROJECT.ORGANOGRAM.SHARE_ORGANOGRAM, shareOrganogram),
      takeLatest(SAGA_ACTIONS.TENANT.PROJECT.ORGANOGRAM.COPY_ORGANOGRAM, copyOrganogram),
      // CHANGE PLAN
      takeLatest(SAGA_ACTIONS.TENANT.PROJECT.CHANGE_PLAN.GET_CHANGE_PLAN, getChangePlanDetails),
      takeLatest(SAGA_ACTIONS.TENANT.PROJECT.CHANGE_PLAN.ADD_EDIT_CHANGE_PLAN, addEditChangePlan),
      takeLatest(SAGA_ACTIONS.TENANT.PROJECT.CHANGE_PLAN.ADD_TO_CHANGE_PLAN, addToChangePlan),
      takeLatest(SAGA_ACTIONS.TENANT.PROJECT.CHANGE_PLAN.GET_ALL_CHANGE_PLANS, getAllChangePlans),
      takeLatest(SAGA_ACTIONS.TENANT.PROJECT.CHANGE_PLAN.REMOVE_CHANGE_PLAN, removeChangePlan),
      takeLatest(SAGA_ACTIONS.TENANT.PROJECT.CHANGE_PLAN.UPDATE_CHANGE_PLAN_STATUS, updateChangePlanStatus),
      takeLatest(SAGA_ACTIONS.TENANT.PROJECT.CHANGE_PLAN.UPDATE_CHANGE_PLAN_START_ACTIVITY_STATUS, updateChangePlanStartActivityStatus),
      takeLatest(SAGA_ACTIONS.TENANT.PROJECT.CHANGE_PLAN.GET_CHANGE_PLAN_TIMELINE, getChangePlanTimeline),
      takeLatest(SAGA_ACTIONS.TENANT.PROJECT.CHANGE_PLAN.GET_TODAY_TIMELINE, getTodaysTimeline),




      //IMPACT
      takeLatest(SAGA_ACTIONS.TENANT.PROJECT.IMPACT.ADD_IMPACT, addImpact),
      takeLatest(SAGA_ACTIONS.TENANT.PROJECT.IMPACT.EDIT_IMPACT_NAME_DESCRIPTION, editImpactNameDescription),
      takeLatest(SAGA_ACTIONS.TENANT.PROJECT.IMPACT.DELETE_IMPACT, deleteImpact),
      takeLatest(SAGA_ACTIONS.TENANT.PROJECT.IMPACT.GET_ALL_IMPACT, getAllImpactList),
      takeLatest(SAGA_ACTIONS.TENANT.PROJECT.IMPACT.GET_IMPACT_DETAILS, getImpactDetails),
      takeLatest(SAGA_ACTIONS.TENANT.PROJECT.IMPACT.DOWNLOAD_IMPACT_EXCEL, downloadImpactExcel),

      // Assessment

      takeLatest(SAGA_ACTIONS.TENANT.PROJECT.ASSESSMENT.GET_ALL_PREDEFINE_ASSESSMENT_LIST, getAllPredefineAssmentList),
      takeLatest(SAGA_ACTIONS.TENANT.PROJECT.ASSESSMENT.ADD_ASSESSMENT, addAssessment),
      takeLatest(SAGA_ACTIONS.TENANT.PROJECT.ASSESSMENT.GET_ALL_ASSESSMENT,  getAllAssmentList),

      takeLatest(SAGA_ACTIONS.TENANT.PROJECT.ASSESSMENT.GET_PREDEFINED_CATEGORYWISE_QUESTION,  getPredefineCategoryWiseQuestionList),
      takeLatest(SAGA_ACTIONS.TENANT.PROJECT.ASSESSMENT.UPDATE_CATEGORYWISE_QUESTION_ANSWER,  updatePredefineCategoryWiseQuestionAnswer),
      takeLatest(SAGA_ACTIONS.TENANT.PROJECT.ASSESSMENT.DELETE_ASSESSMENT,  deleteAssessment),

      takeLatest(SAGA_ACTIONS.TENANT.PROJECT.ASSESSMENT.PUBLIC_GET_ASSESSMENT_DETAILS, publicGetAssessmentDetails),
      takeLatest(SAGA_ACTIONS.TENANT.PROJECT.ASSESSMENT.PUBLIC_SAVE_ASSESSMENT_RESPONSE,publicSaveAssessmentResponse),

      takeLatest(SAGA_ACTIONS.TENANT.PROJECT.ASSESSMENT.SAVE_CHANGE_ASSESSMENT_QUESTIONS,saveAssessmentQuestions),
      takeLatest(SAGA_ACTIONS.TENANT.PROJECT.ASSESSMENT.RENAME_ASSESSMENT_SECTION,renameAssessmentSection),
      takeLatest(SAGA_ACTIONS.TENANT.PROJECT.ASSESSMENT.DELETE_ASSESSMENT_SECTION,deleteAssessmentSection),
      takeLatest(SAGA_ACTIONS.TENANT.PROJECT.ASSESSMENT.DELETE_ASSESSMENT_QUESTION,deleteAssessmentQuestion),
      takeLatest(SAGA_ACTIONS.TENANT.PROJECT.ASSESSMENT.RENAME_ASSESSMENT_QUESTION,renameAssessmentQuestion),

      //comms
      takeLatest(SAGA_ACTIONS.TENANT.PROJECT.COMMS.GET_ALL_COMMS, getAllCommsList),
      takeLatest(SAGA_ACTIONS.TENANT.PROJECT.COMMS.GET_SINGLE_COMMS_DETAILS, getSingleCommsDetails),
      takeLatest(SAGA_ACTIONS.TENANT.PROJECT.COMMS.ADD_COMMS, addEditComms),
      takeLatest(SAGA_ACTIONS.TENANT.PROJECT.COMMS.SEND_FOR_APPROVAL, sendForApproval),
      takeLatest(SAGA_ACTIONS.TENANT.PROJECT.COMMS.DELETE_COMMS, deleteComms),
      takeLatest(SAGA_ACTIONS.TENANT.PROJECT.COMMS.GET_APPROVAL_DETAILS, getApprovalDetails),
      takeLatest(SAGA_ACTIONS.TENANT.PROJECT.COMMS.SAVE_APPROVAL_RESPONSE, saveApprovalWithComments),

      // Training
       //comms
       takeLatest(SAGA_ACTIONS.TENANT.PROJECT.TRAINING.GET_ALL_TRAINING, getAllTrainingList),
       takeLatest(SAGA_ACTIONS.TENANT.PROJECT.TRAINING.GET_SINGLE_TRAINING_DETAILS, getSingleTrainingDetails),
       takeLatest(SAGA_ACTIONS.TENANT.PROJECT.TRAINING.ADD_TRAINING, addEditTraining),      
       takeLatest(SAGA_ACTIONS.TENANT.PROJECT.TRAINING.DELETE_TRAINING, deleteTraining),
       takeLatest(SAGA_ACTIONS.TENANT.PROJECT.TRAINING.DOWNLOAD_TRAINING_EXCEL, downloadTrainingExcel),
       takeLatest(SAGA_ACTIONS.TENANT.PROJECT.TRAINING.SHARE_TRAINING_DATA, shareTrainingData),
       takeLatest(SAGA_ACTIONS.TENANT.PROJECT.TRAINING.GET_TRAINING_SHARE_DETAILS, publicGetTrainingShareDetails),
      //  takeLatest(SAGA_ACTIONS.TENANT.PROJECT.COMMS.SEND_FOR_APPROVAL, sendForApproval),
      //  takeLatest(SAGA_ACTIONS.TENANT.PROJECT.COMMS.GET_APPROVAL_DETAILS, getApprovalDetails),
      //  takeLatest(SAGA_ACTIONS.TENANT.PROJECT.COMMS.SAVE_APPROVAL_RESPONSE, saveApprovalWithComments),
 


      

      

      // SURVEY -----
      takeLatest(SAGA_ACTIONS.TENANT.PROJECT.SURVEY.GET_ALL_SURVEY,getAllSurveyList ),
      takeLatest(SAGA_ACTIONS.TENANT.PROJECT.SURVEY.ADD_SURVEY,addSurvey ),
      takeLatest(SAGA_ACTIONS.TENANT.PROJECT.SURVEY.DELETE_SURVEY, deleteSurvey ),
      takeLatest(SAGA_ACTIONS.TENANT.PROJECT.SURVEY.GET_SURVEY_DETAILS, getSurveyDetails ),
      takeLatest(SAGA_ACTIONS.TENANT.PROJECT.SURVEY.GET_SURVEY_POLL,getSurveyPoll),
      takeLatest(SAGA_ACTIONS.TENANT.PROJECT.SURVEY.GET_SAVE_POLL,savePoll),
      takeLatest(SAGA_ACTIONS.TENANT.PROJECT.SURVEY.SHARE_POLL,sharePoll),

      // reports------------

      takeLatest(SAGA_ACTIONS.TENANT.PROJECT.REPORTS.ADD_REPORT,addReport),
      takeLatest(SAGA_ACTIONS.TENANT.PROJECT.REPORTS.DELETE_REPORT,deleteReport),
      takeLatest(SAGA_ACTIONS.TENANT.PROJECT.REPORTS.GET_ALL_REPORTS,getAllReports),
      takeLatest(SAGA_ACTIONS.TENANT.PROJECT.REPORTS.GET_REPORT,getReport),
      takeLatest(SAGA_ACTIONS.TENANT.PROJECT.REPORTS.GET_PUBLIC_REPORT_CHANGE_STORY,publicGetReportChangeStory),
      takeLatest(SAGA_ACTIONS.TENANT.PROJECT.REPORTS.GET_CHANGE_LOAD_REPORT,getChangeLoadReport),

      

      takeLatest(SAGA_ACTIONS.TENANT.PROJECT.REPORTS.ADD_STATUS_REPORT,addStatusReport),
      takeLatest(SAGA_ACTIONS.TENANT.PROJECT.REPORTS.GET_ALL_STATUS_REPORTS,getAllStatusReports),
      takeLatest(SAGA_ACTIONS.TENANT.PROJECT.REPORTS.GET_STATUS_REPORT,getStatusReport),
      takeLatest(SAGA_ACTIONS.TENANT.PROJECT.REPORTS.DELETE_STATUS_REPORT,deleteStatusReport),
      takeLatest(SAGA_ACTIONS.TENANT.PROJECT.REPORTS.DOWNLOAD_REPORT,downloadReport),

      takeLatest(SAGA_ACTIONS.TENANT.PROJECT.REPORTS.ADD_CHANGE_REPORT,addChangeReport),
      takeLatest(SAGA_ACTIONS.TENANT.PROJECT.REPORTS.GET_ALL_CHANGE_REPORT,getAllChangeReports),
      takeLatest(SAGA_ACTIONS.TENANT.PROJECT.REPORTS.GET_SINGLE_CHANGE_REPORT,getChangeReport),
      takeLatest(SAGA_ACTIONS.TENANT.PROJECT.REPORTS.DELETE_CHANGE_REPORT,deleteChangeReport),


      // Common SAGA
      takeLatest(SAGA_ACTIONS.TENANT.COMMON.IMAGE_UPLOAD, uploadFiles),
      takeEvery(SAGA_ACTIONS.TENANT.COMMON.COUNTRY_LIST, searchCountries),
      takeEvery(SAGA_ACTIONS.TENANT.COMMON.STATE_LIST, searchStates),
      takeEvery(SAGA_ACTIONS.TENANT.COMMON.CITY_LIST, searchCities),
      takeLatest(SAGA_ACTIONS.TENANT.COMMON.DELETE_FILES, deleteFiles),
      takeEvery(SAGA_ACTIONS.TENANT.COMMON.GET_FILES, getFiles),
      takeLatest(SAGA_ACTIONS.TENANT.COMMON.ABOUT_ME_LISTING, tenantAboutMeListing),
      takeLatest(SAGA_ACTIONS.TENANT.COMMON.SHARE_LINK, publicShareLink),
      takeLatest(SAGA_ACTIONS.TENANT.COMMON.GET_PUBLIC_MODULE_DETAILS, publicGetModuleDetails),
      takeLatest(SAGA_ACTIONS.TENANT.COMMON.GET_TENANT_MODULE_PERMISSION,getTenantModulePermission),
      takeLatest(SAGA_ACTIONS.TENANT.COMMON.SEARCH_DIALCODE,searchDialcode),

      

   ]);
}