import React, { useEffect } from 'react'
import { useHistory } from 'react-router'
import { useAppUserDetailsSelector } from '../../../_common/hooks/selectors/user/userSelector'
import { UserAboutMeType } from '../../../_common/interfaces/models/user'
import { URLS } from '../../../_config'

function CompanyAboutMeChecker() {

  /**
   * const
   */
  const user = useAppUserDetailsSelector()
  const history = useHistory()
  /**
   * effects
   */
  useEffect(() => {
  
    if (history && user) {
    
      if (!user.isTenant || user.role.slug !== 'company-admin') {
        // not company
      
      } else {
        // company
      
        if (history.location.pathname != URLS.TENANT.USER.ABOUT_ME) {
          // not in about me page
        
          if (!user.aboutMeId || !user.aboutMe) {
           
            history.push(URLS.TENANT.USER.ABOUT_ME)
          } else {
         
            if (user.aboutMe.type == UserAboutMeType.COMPANY && !user.isCompanySetupDone) {
             
              history.push(URLS.TENANT.USER.ABOUT_ME)
            }
          }
        } else {
         
        }
      }
    } else {
     
    }
  }, [user, history])
  /**
   * functions
   */
  /**
   * render functions
   */

  return (
    <React.Fragment></React.Fragment>
  )
}

export default CompanyAboutMeChecker
