import { call, put } from 'redux-saga/effects';
import { ACTIONS, API_URL, SAGA_ACTIONS, STORAGE } from '../../_config';
import { CallApi } from '../api/callApi';

export function* addProject(action: any) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.POST, API_URL.TENANT.PROJECT.ADD_PROJECT, data, true);
      if (resp.status >= 200 && resp.status < 300 && resp.data) {
         yield put({
            type: ACTIONS.TENANT.PROJECT.ADD_PROJECT,
            payload: resp.data.data,
         })
         action && action.callbackSuccess && action.callbackSuccess(resp.data);
      } else {
         action && action.callbackError && action.callbackError(resp.data);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
   }
}

export function* deleteDraftedProject(action: any) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.POST, API_URL.TENANT.PROJECT.DELETE_DRAFTED_PROJECT, data, true);
      if (resp.status >= 200 && resp.status < 300 && resp.data) {
         action && action.callbackSuccess && action.callbackSuccess(resp.data);
      } else {
         action && action.callbackError && action.callbackError(resp.data);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
   }
}

export function* projectList(action: any) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.GET, API_URL.TENANT.PROJECT.PROJECT_LIST, data, true);
      if (resp.status >= 200 && resp.status < 300 && resp.data) {
         yield put({
            type: ACTIONS.TENANT.PROJECT.PROJECT_LIST,
            payload: resp.data.data,
         })
         action && action.callbackSuccess && action.callbackSuccess(resp.data);
      } else {
         action && action.callbackError && action.callbackError(resp.data);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
   }
}

export function* projectDraftList(action: any) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.GET, API_URL.TENANT.PROJECT.PROJECT_LIST, data, true);
      if (resp.status >= 200 && resp.status < 300 && resp.data) {
         yield put({
            type: ACTIONS.TENANT.PROJECT.DRAFT_LIST,
            payload: resp.data.data,
         })
         action && action.callbackSuccess && action.callbackSuccess(resp.data);
      } else {
         action && action.callbackError && action.callbackError(resp.data);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
   }
}

export function* addProjectFiles(action: any) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.POST, API_URL.TENANT.PROJECT.ADD_PROJECT_FILES, data, true);
      if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data) {
         action && action.callbackSuccess && action.callbackSuccess(resp.data);
      } else {
         action && action.callbackError && action.callbackError(resp.data);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
   }
}

export function* removeProjectFiles(action: any) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.POST, API_URL.TENANT.PROJECT.REMOVE_PROJECT_FILES, data, true);
      if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data) {
         action && action.callbackSuccess && action.callbackSuccess(resp.data);
      } else {
         action && action.callbackError && action.callbackError(resp.data);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
   }
}

export function* projectDetail(action: any) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.GET, API_URL.TENANT.PROJECT.PROJECT_DETAIL, data, true);
      if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data) {
         yield put({
            type: ACTIONS.TENANT.PROJECT.GET_PARTICULAR_PROJECT_DETAILS,
            payload: resp.data.data,
         })
         action && action.callbackSuccess && action.callbackSuccess(resp.data);
      } else {
         action && action.callbackError && action.callbackError(resp.data);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
   }
}

export function* uploadExcelFile(action: any) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.POST, API_URL.TENANT.PROJECT.UPLOAD_EXCEL_FILE, data, true);
      if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data) {
         action && action.callbackSuccess && action.callbackSuccess(resp.data);
      } else {
         action && action.callbackError && action.callbackError(resp.data);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
   }
}

export function* uploadProjectExcelFile(action: any) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.POST, API_URL.TENANT.PROJECT.UPLOAD_PROJECT_EXCEL_FILE, data, true);
      if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data) {
         action && action.callbackSuccess && action.callbackSuccess(resp.data);
      } else {
         action && action.callbackError && action.callbackError(resp.data);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
   }
}

export function* addEditStakeholder(action: any) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.POST, API_URL.TENANT.PROJECT.ADD_EDIT_STAKEHOLDER, data, true);
      if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data) {
         action && action.callbackSuccess && action.callbackSuccess(resp.data);
      } else {
         action && action.callbackError && action.callbackError(resp.data);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
   }
}

export function* availableUsers(action: any) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.GET, API_URL.TENANT.PROJECT.AVAIL_PROJECT_USERS, data, true);
      if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data) {
         action && action.callbackSuccess && action.callbackSuccess(resp.data);
      } else {
         action && action.callbackError && action.callbackError(resp.data);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
   }
}

export function* searchProjectUsers(action: any) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.GET, API_URL.TENANT.PROJECT.SEARCH_PROJECT_USERS, data, true);
      if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data) {
         yield put({
            type: ACTIONS.TENANT.PROJECT.GET_PROJECTUSERS ,
            payload: resp.data.data,
         })
         action && action.callbackSuccess && action.callbackSuccess(resp.data);
      } else {
         action && action.callbackError && action.callbackError(resp.data);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
   }
}

export function* addUserToProject(action: any) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.POST, API_URL.TENANT.PROJECT.ADD_USER_TO_PROJECT, data, true);
      if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data) {
         action && action.callbackSuccess && action.callbackSuccess(resp.data);
      } else {
         action && action.callbackError && action.callbackError(resp.data);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
   }
}

export function* removeUserFromProject(action: any) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.POST, API_URL.TENANT.PROJECT.REMOVE_USER_FROM_PROJECT, data, true);
      if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data) {
         action && action.callbackSuccess && action.callbackSuccess(resp.data);
      } else {
         action && action.callbackError && action.callbackError(resp.data);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
   }
}

export function* addNewProjectUser(action: any) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.POST, API_URL.TENANT.PROJECT.ADD_NEW_PROJECT_USER, data, true);
      if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data && resp.data.success) {
         action && action.callbackSuccess && action.callbackSuccess(resp.data);
      } else {
         action && action.callbackError && action.callbackError(resp.data);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
   }
}



export function* changeProjectRole(action: any) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.POST, API_URL.TENANT.PROJECT.CHANGE_PROJECT_USER_ROLE, data, true);
      if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data) {
         action && action.callbackSuccess && action.callbackSuccess(resp.data);
      } else {
         action && action.callbackError && action.callbackError(resp.data);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
   }
}

export function* getProjectStakeholders(action: any) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.GET, API_URL.TENANT.PROJECT.GET_STAKEHOLDERS, data, true);
      if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data) {
         yield put({
            type: ACTIONS.TENANT.PROJECT.GET_STAKEHOLDERS,
            payload: resp.data.data,
         })
         action && action.callbackSuccess && action.callbackSuccess(resp.data);
      } else {
         action && action.callbackError && action.callbackError(resp.data);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
   }
}

export function* updateProjectUser(action: any) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.POST, API_URL.TENANT.PROJECT.UPDATE_PROJECT_USER, data, true);
      if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data) {
         action && action.callbackSuccess && action.callbackSuccess(resp.data);
      } else {
         action && action.callbackError && action.callbackError(resp.data);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
   }
}

export function* setupFinalProject(action: any) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.POST, API_URL.TENANT.PROJECT.SETUP_FINAL, data, true);
      if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data) {
         action && action.callbackSuccess && action.callbackSuccess(resp.data);
      } else {
         action && action.callbackError && action.callbackError(resp.data);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
   }
}


export function* projectChangeQuestionMaster(action: any) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.POST, API_URL.TENANT.PROJECT.PROJECT_CHANGE_QUESTION_MASTER, data, true);
      if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data) {
         yield put({
            type: ACTIONS.TENANT.PROJECT.PROJECT_CHANGE_QUESTION_MASTER,
            payload: resp.data.data,
         })
         action && action.callbackSuccess && action.callbackSuccess(resp.data);
      } else {
         action && action.callbackError && action.callbackError(resp.data);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
   }
}

export function* projectCustomQuestionAnswer(action: any) {
   try {
      const data = action.payload;
      // console.log('payload==>',data)
      const resp = yield call(CallApi.POST, API_URL.TENANT.PROJECT.PROJECT_CUSTOM_QUESTION_ANSWER, data, true);
      if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data) {
        
         action && action.callbackSuccess && action.callbackSuccess(resp.data);
      } else {
         action && action.callbackError && action.callbackError(resp.data);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
   }
}

export function* saveUpdateProjectChangeQuestion(action: any) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.POST, API_URL.TENANT.PROJECT.PROJECT_CHANGE_QUESTION_SAVE_UPDATE, data, true);
      if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data) {
         yield put({
            type: ACTIONS.TENANT.PROJECT.GET_PARTICULAR_PROJECT_DETAILS,
            payload: resp.data.data,
         })
         action && action.callbackSuccess && action.callbackSuccess(resp.data);
      } else {
         action && action.callbackError && action.callbackError(resp.data);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
   }
}

export function* getProjectMethodologyMaster(action: any) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.GET, API_URL.TENANT.PROJECT.GET_PROJECT_METHODOLOGY_MASTER, data, true);
      if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data) {
         yield put({
            type: ACTIONS.TENANT.PROJECT.GET_PROJECT_METHODOLOGY_MASTER,
            payload: resp.data.data,
         })
         action && action.callbackSuccess && action.callbackSuccess(resp.data);
      } else {
         action && action.callbackError && action.callbackError(resp.data);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
   }
}

export function* updateProjectMethodology(action: any) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.POST, API_URL.TENANT.PROJECT.SET_PROJECT_METHODOLOGY, data, true);
      if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data) {
         yield put({
            type: ACTIONS.TENANT.PROJECT.GET_PARTICULAR_PROJECT_DETAILS,
            payload: resp.data.data,
         })
         action && action.callbackSuccess && action.callbackSuccess(resp.data);
      } else {
         action && action.callbackError && action.callbackError(resp.data);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
   }
}

export function* searchProjectMethodologyQuestions(action: any) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.POST, API_URL.TENANT.PROJECT.SEARCH_PROJECT_METHODOLOGY_QUESTIONS, data, true);
      if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data) {
         action && action.callbackSuccess && action.callbackSuccess(resp.data);
      } else {
         action && action.callbackError && action.callbackError(resp.data);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
   }
}