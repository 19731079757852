import { ActionExtended } from '../../../../_common/interfaces/ActionExtended';
import { ACTIONS } from '../../../../_config'
import { SearchContactType, ClientWithUsers, ClientList, Client } from '../../../../_common/interfaces/models/client'



export enum ClientSetupStep {
   COMPANY_ADMIN = 1,
   STRATEGY = 2,
   TEAM_MEMBERS = 3,
}

export interface ClientReducer {
   currentStep: ClientSetupStep;
   clientNameList: any[];
   reqUpdateClientNameDetails: any;
   contactTypeList: SearchContactType | null;
   carryForwardDetails: any;
   clientWithUsers: ClientWithUsers[] | null;
   clientLists: ClientList | null;
   clientDraftLists: ClientList | null;
   clientDetails: Client | null;
}

const initialState: ClientReducer = {
   currentStep: ClientSetupStep.COMPANY_ADMIN,
   clientNameList: [],
   reqUpdateClientNameDetails: null,
   contactTypeList: null,
   carryForwardDetails: null,
   clientWithUsers: null,
   clientLists: null,
   clientDraftLists: null,
   clientDetails: null
};

const clientReducer = (state = initialState, action: ActionExtended) => {
   switch (action.type) {
      case ACTIONS.TENANT.CLIENT.CLIENT_SETUP_STEP:
         return {
            ...state,
            currentStep: action.payload,
         }
      case ACTIONS.TENANT.CLIENT.TEMPORARY_CLIENT_NAME_LIST:
         var tempClientList = []
         if (state.clientNameList && state.clientNameList.length) {
            tempClientList = state.clientNameList;
            tempClientList.push(action.payload)
         }
         else {
            tempClientList.push(action.payload)
         }

         return {
            ...state,
            clientNameList: tempClientList.map((x: any) => Object.assign(x)),
         }

      case ACTIONS.TENANT.CLIENT.DELETE_DATA_FROM_TEMPORARY_CLIENT_NAME_LIST:
         var tempClientList = state.clientNameList;
         for (var i = 0; i < tempClientList.length; i++) {
            if (tempClientList[i].uniqueId == action.payload) {
               tempClientList.splice(i, 1);
            }
         }
         return {
            ...state,
            clientNameList: tempClientList.map(x => Object.assign(x)),
         }

      case ACTIONS.TENANT.CLIENT.UPDATE_DATA_FROM_TEMPORARY_CLIENT_NAME_LIST:
         return {
            ...state,
            reqUpdateClientNameDetails: action.payload,
         }
      case ACTIONS.TENANT.CLIENT.CLIENT_DATA_UPDATE_INTO_TEMPORARY_CLIENT_NAME_LIST:
         var tempClientList = state.clientNameList;
         for (var i = 0; i < tempClientList.length; i++) {
            if (tempClientList[i].uniqueId == action.payload.uniqueId) {
               tempClientList[i].contactType = action.payload.contactType;
               tempClientList[i].contactTypeName = action.payload.contactTypeName;
               tempClientList[i].firstName = action.payload.firstName;
               tempClientList[i].lastName = action.payload.lastName;
               tempClientList[i].contactNumber = action.payload.contactNumber;
               tempClientList[i].officeNumber = action.payload.officeNumber;
            }
         }
         return {
            ...state,
            clientNameList: tempClientList.map(x => Object.assign(x)),
         }
      case ACTIONS.TENANT.CLIENT.EMPTY_TEMPORARY_CLIENT_NAME_LIST:
         return {
            ...state,
            clientNameList: action.payload,
         }

      case ACTIONS.TENANT.CLIENT.GET_CONTACT_TYPE:
         return {
            ...state,
            contactTypeList: action.payload.data,
         }
      case ACTIONS.TENANT.CLIENT.CLIENT_SETUP_DETAILS_CARRY_FORWARD_TO_NEXT_STEP:
         return {
            ...state,
            carryForwardDetails: action.payload,
         }

      case ACTIONS.TENANT.CLIENT.GET_PARTICULAR_CLIENT_WITH_USERS:
         return {
            ...state,
            clientWithUsers: action.payload.data,
         }
      case ACTIONS.TENANT.CLIENT.GET_CLIENT_LISTS:
         // let prevData = state.clientLists && state.clientLists.data && state.clientLists.data.length ? state.clientLists.data : []

         // if (action.payload && action.payload.data && action.payload.data.data && action.payload.data.data.length) {
         //    for (let i = 0; i < action.payload.data.data.length; i++) {
         //       let nd = action.payload.data.data[i]
         //       let findIndex = prevData.findIndex(x => x.id == nd.id)
         //       if (findIndex >= 0) {
         //          prevData[findIndex] = nd;
         //       } else {
         //          prevData.push(nd)
         //       }
         //    }
         // }
         return {
            ...state,
            clientLists: action.payload.data,
            // clientLists: {
            //    ...action.payload.data,
            //    data: prevData.map(x => Object.assign(x)),
            // }
         }

      case ACTIONS.TENANT.CLIENT.GET_DRAFT_CLIENT_LISTS:
         // let prevDraftData = state.clientDraftLists && state.clientDraftLists.data && state.clientDraftLists.data.length ? state.clientDraftLists.data : []

         // if (action.payload && action.payload.data && action.payload.data.data && action.payload.data.data.length) {
         //    for (let i = 0; i < action.payload.data.data.length; i++) {
         //       let nd = action.payload.data.data[i]
         //       let findIndex = prevDraftData.findIndex(x => x.id == nd.id)
         //       if (findIndex >= 0) {
         //          prevDraftData[findIndex] = nd;
         //       } else {
         //          prevDraftData.push(nd)
         //       }
         //    }
         // }
         return {
            ...state,
            clientDraftLists: action.payload.data,
            // clientDraftLists:{
            //    ...action.payload.data,
            //    data: prevDraftData.map(x => Object.assign(x)),
            // } 
         }
      case ACTIONS.TENANT.CLIENT.GET_CLIENT_DETAILS:
         return {
            ...state,
            clientDetails: action.payload
         }

      default:
         return state;
   }
};

export default clientReducer;
