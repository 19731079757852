import { ActionExtended } from "../../../../../_common/interfaces/ActionExtended";
import { ACTIONS } from "../../../../../_config";
import {ImpactList} from '../../../../../_common/interfaces/models/impact';
export interface ImpactReducer {
   impactList: ImpactList[] | null;
   impactDetails: ImpactList | null;
}

const initialState: ImpactReducer = {
   impactList: null,
   impactDetails: null
};

const impactReducer = (state = initialState, action: ActionExtended) => {
   switch (action.type) {
      case ACTIONS.TENANT.PROJECT.IMPACT.GET_ALL_IMPACT:
         return {
            ...state,
            impactList: action.payload
         }
      case ACTIONS.TENANT.PROJECT.IMPACT.GET_IMPACT_DETAILS:
         return {
            ...state,
            impactDetails: action.payload
         }
      default:
         return state
   }
}

export default impactReducer