import { ActionExtended } from "../../../../../_common/interfaces/ActionExtended";
import { ACTIONS } from "../../../../../_config";
import { CommsList, CommsDetails } from '../../../../../_common/interfaces/models/comms';


export interface CommsReducer {
   commsList: CommsList[] | null;
   commsDetails: CommsDetails | null;
}
const initialState: CommsReducer = {
   commsList: null,
   commsDetails: null,

};
const commsReducer = (state = initialState, action: ActionExtended) => {
   switch (action.type) {
      case ACTIONS.TENANT.PROJECT.COMMS.GET_ALL_COMMS:
         return {
            ...state,
            commsList: action.payload
         }
      case ACTIONS.TENANT.PROJECT.COMMS.GET_SINGLE_COMMS_DETAILS:
         return {
            ...state,
            commsDetails: action.payload
         }

      default:
         return state
   }
}

export default commsReducer