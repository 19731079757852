import React from 'react'
import { Redirect } from 'react-router'
import { URLS, SUB_DOMAIN_CHECK, URL_AVAILABLE } from '../../_config'
import Layout from '../layout/Layout'
import { useAuthStatus } from '../hooks/common/auth/authHook'
import { useAppSiteInfoSelector } from '../hooks/selectors/common/commonSelector'
import { useAppUserDetailsSelector } from '../hooks/selectors/user/userSelector'

const requireNoAuth = (Component: React.ComponentType, availableTo: URL_AVAILABLE = URL_AVAILABLE.BOTH, operation = '', module = '') => {
  function NoAuthHoc(props: any) {
    const isAuth = useAuthStatus()
    const user = useAppUserDetailsSelector()
    const siteInfo = useAppSiteInfoSelector()
    var subdomain = window.location.host.split('.')[1] ? window.location.host.split('.')[0] : null;

    if (siteInfo && siteInfo.isTenant) {
      if (availableTo && availableTo == URL_AVAILABLE.ADMIN_ONLY) {
        //  return isAuth && user ? <Redirect to={URLS.TENANT.CLIENT.CLIENT}/> : <Redirect to={URLS.TENANT.USER.LOGIN}/>
         return isAuth && user ? <Redirect to={URLS.TENANT.CLIENT.CLIENT}/> : <Redirect to={'/tenant/login'}/>
      }
   }
   if (siteInfo && siteInfo.isSuperAdmin) {
      if (availableTo && availableTo == URL_AVAILABLE.TENANT_ONLY) {
         return isAuth && user ? <Redirect to={URLS.SUPER_ADMIN.USER.DASHBOARD}/> : <Redirect to={'/login'}/>
      }
   }

    return (
      //change-manager for prod
      isAuth ? <Redirect to={URLS.TENANT.CLIENT.CLIENT} /> : <Layout>
        <Component {...props} />
      </Layout>
    )
  }

  return NoAuthHoc
}
export default requireNoAuth
