import { call, put } from 'redux-saga/effects';
import { ACTIONS, API_URL, SAGA_ACTIONS, STORAGE } from '../../_config';
import { CallApi } from '../api/callApi';
import moment from 'moment'

export function* addEditOrganogram(action: any) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.POST, API_URL.TENANT.PROJECT.ORGANOGRAM.ADD_EDIT_ORGANOGRAM, data, true);
      if (resp.status >= 200 && resp.status < 300 && resp.data) {
         action && action.callbackSuccess && action.callbackSuccess(resp.data);
      } else {
         action && action.callbackError && action.callbackError(resp.data);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
   }
}

export function* removeOrganogram(action: any) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.POST, API_URL.TENANT.PROJECT.ORGANOGRAM.REMOVE_ORGANOGRAM, data, true);
      if (resp.status >= 200 && resp.status < 300 && resp.data) {
         action && action.callbackSuccess && action.callbackSuccess(resp.data);
      } else {
         action && action.callbackError && action.callbackError(resp.data);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
   }
}

export function* getOrganogramDetails(action: any) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.GET, API_URL.TENANT.PROJECT.ORGANOGRAM.GET_ORGANOGRAM_DETAILS, data, true);
      if (resp.status >= 200 && resp.status < 300 && resp.data) {
         yield put({
            type: ACTIONS.TENANT.PROJECT.ORGANOGRAM.GET_ORGANOGRAM_DETAILS,
            payload: resp.data,
         })
         action && action.callbackSuccess && action.callbackSuccess(resp.data);
      } else {
         action && action.callbackError && action.callbackError(resp.data);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
   }
}

export function* getAllOrganograms(action: any) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.GET, API_URL.TENANT.PROJECT.ORGANOGRAM.GET_ALL_ORGANOGRAMS, data, true);
      if (resp.status >= 200 && resp.status < 300 && resp.data) {
         yield put({
            type: ACTIONS.TENANT.PROJECT.ORGANOGRAM.GET_ALL_ORGANOGRAMS,
            payload: resp.data.data.sort((a: any, b: any) => moment(b.createdAt) > moment(a.createdAt) ? 1 : -1),
         })
         action && action.callbackSuccess && action.callbackSuccess(resp.data);
      } else {
         action && action.callbackError && action.callbackError(resp.data);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
   }
}

export function* getDepartmentAndDivision(action: any) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.GET, API_URL.TENANT.PROJECT.ORGANOGRAM.GET_DEPARTMENT_AND_DIVISION, data, true);
      if (resp.status >= 200 && resp.status < 300 && resp.data) {
         yield put({
            type: ACTIONS.TENANT.PROJECT.ORGANOGRAM.GET_DEPARTMENT_AND_DIVISION,
            payload: resp.data.data,
         })
         action && action.callbackSuccess && action.callbackSuccess(resp.data);
      } else {
         action && action.callbackError && action.callbackError(resp.data);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
   }
}

export function* addEditStakeholderMatrix(action: any) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.POST, API_URL.TENANT.PROJECT.ORGANOGRAM.ADD_EDIT_STAKEHOLDER_MATRIX, data, true);
      if (resp.status >= 200 && resp.status < 300 && resp.data) {
         action && action.callbackSuccess && action.callbackSuccess(resp.data);
      } else {
         action && action.callbackError && action.callbackError(resp.data);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
   }
}

export function* removeStakeholderMatrix(action: any) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.POST, API_URL.TENANT.PROJECT.ORGANOGRAM.REMOVE_STAKEHOLDER_MATRIX, data, true);
      if (resp.status >= 200 && resp.status < 300 && resp.data) {
         action && action.callbackSuccess && action.callbackSuccess(resp.data);
      } else {
         action && action.callbackError && action.callbackError(resp.data);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
   }
}

export function* removeStakeholderFromMatrix(action: any) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.POST, API_URL.TENANT.PROJECT.ORGANOGRAM.REMOVE_STAKEHOLDER_FROM_STAKEHOLDER_MATRIX, data, true);
      if (resp.status >= 200 && resp.status < 300 && resp.data) {
         action && action.callbackSuccess && action.callbackSuccess(resp.data);
      } else {
         action && action.callbackError && action.callbackError(resp.data);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
   }
}

export function* getStakeholderMatrixDetails(action: any) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.GET, API_URL.TENANT.PROJECT.ORGANOGRAM.GET_STAKEHOLDER_MATRIX_DETAILS, data, true);
      if (resp.status >= 200 && resp.status < 300 && resp.data) {
         yield put({
            type: ACTIONS.TENANT.PROJECT.ORGANOGRAM.GET_STAKEHOLDER_MATRIX_DETAILS,
            payload: resp.data.data || null,
         })
         action && action.callbackSuccess && action.callbackSuccess(resp.data);
      } else {
         action && action.callbackError && action.callbackError(resp.data);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
   }
}

export function* getAllStakeholderMatrix(action: any) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.GET, API_URL.TENANT.PROJECT.ORGANOGRAM.GET_ALL_STAKEHOLDER_MATRIX, data, true);
      if (resp.status >= 200 && resp.status < 300 && resp.data) {
         yield put({
            type: ACTIONS.TENANT.PROJECT.ORGANOGRAM.GET_ALL_ORGANOGRAMS,
            payload: resp.data.data.sort((a: any, b: any) => moment(b.createdAt) > moment(a.createdAt) ? 1 : -1),
         })
         action && action.callbackSuccess && action.callbackSuccess(resp.data);
      } else {
         action && action.callbackError && action.callbackError(resp.data);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
   }
}

export function* addEditInfluenceMap(action: any) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.POST, API_URL.TENANT.PROJECT.ORGANOGRAM.ADD_EDIT_INFLUENCE_MAP, data, true);
      if (resp.status >= 200 && resp.status < 300 && resp.data) {
         action && action.callbackSuccess && action.callbackSuccess(resp.data);
      } else {
         action && action.callbackError && action.callbackError(resp.data);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
   }
}

export function* removeInfluenceMap(action: any) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.POST, API_URL.TENANT.PROJECT.ORGANOGRAM.REMOVE_INFLUENCE_MAP, data, true);
      if (resp.status >= 200 && resp.status < 300 && resp.data) {
         action && action.callbackSuccess && action.callbackSuccess(resp.data);
      } else {
         action && action.callbackError && action.callbackError(resp.data);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
   }
}

export function* removeStakeholderFromInfluenceMap(action: any) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.POST, API_URL.TENANT.PROJECT.ORGANOGRAM.REMOVE_STAKEHOLDER_FROM_INFLUENCE_MAP, data, true);
      if (resp.status >= 200 && resp.status < 300 && resp.data) {
         action && action.callbackSuccess && action.callbackSuccess(resp.data);
      } else {
         action && action.callbackError && action.callbackError(resp.data);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
   }
}

export function* getInfluenceMapDetails(action: any) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.GET, API_URL.TENANT.PROJECT.ORGANOGRAM.GET_INFLUENCE_MAP_DETAILS, data, true);
      if (resp.status >= 200 && resp.status < 300 && resp.data) {
         yield put({
            type: ACTIONS.TENANT.PROJECT.ORGANOGRAM.GET_INFLUANCE_MAP_DETAILS,
            payload: resp.data.data,
         })
         action && action.callbackSuccess && action.callbackSuccess(resp.data);
      } else {
         action && action.callbackError && action.callbackError(resp.data);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
   }
}

export function* getAllInfluenceMap(action: any) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.GET, API_URL.TENANT.PROJECT.ORGANOGRAM.GET_ALL_INFLUENCE_MAP, data, true);
      if (resp.status >= 200 && resp.status < 300 && resp.data) {
         // yield put({
         //    type: ACTIONS.TENANT.PROJECT.ORGANOGRAM.GET_ALL_ORGANOGRAMS,
         //    payload: resp.data.data.sort((a: any, b: any) => moment(b.createdAt) > moment(a.createdAt) ? 1 : -1),
         // })
         action && action.callbackSuccess && action.callbackSuccess(resp.data);
      } else {
         action && action.callbackError && action.callbackError(resp.data);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
   }
}

export function* shareOrganogram(action: any) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.POST, API_URL.TENANT.PROJECT.ORGANOGRAM.SHARE_ORGANOGRAM, data, true);
      if (resp.status >= 200 && resp.status < 300 && resp.data) {
         action && action.callbackSuccess && action.callbackSuccess(resp.data);
      } else {
         action && action.callbackError && action.callbackError(resp.data);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
   }
}


export function* copyOrganogram(action: any) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.POST, API_URL.TENANT.PROJECT.ORGANOGRAM.COPY_ORGANOGRAM, data, true);
      if (resp.status >= 200 && resp.status < 300 && resp.data) {
         action && action.callbackSuccess && action.callbackSuccess(resp.data);
      } else {
         action && action.callbackError && action.callbackError(resp.data);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
   }
}