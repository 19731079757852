import { call, put } from 'redux-saga/effects';
import {ACTIONS, API_URL, STORAGE } from '../../_config'
import { CallApi } from '../api/callApi';

export function* loginUser(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.SUPER_ADMIN.USER.LOGIN, data);
    // console.log('loginUrl',resp);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      // console.log('loginUrl',resp.data);
      localStorage.setItem(STORAGE, resp.data.data.token);
      yield put({
        type: ACTIONS.USER.LOGIN,
        payload: {
          user: resp.data.data.user,
          token: resp.data.data.token,
        }
      })
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* register(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.SUPER_ADMIN.USER.REGISTRATION, data);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* checkSubDomainAvailablity(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.GET, API_URL.SUPER_ADMIN.COMMON.CHECK_SUBDOMAIN_AVAILABILITY, data);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* verifyEmail(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.SUPER_ADMIN.USER.VERIFY_EMAIL, data);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* checkLogin(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.SUPER_ADMIN.USER.CHECK_LOGIN, data);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}


export function* getUserProfile(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.GET, API_URL.SUPER_ADMIN.USER.DETAILS, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data) {
      yield put({
        type: ACTIONS.USER.ME,
        payload: resp.data.data,
      })
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* logoutUser(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.SUPER_ADMIN.USER.LOGOUT, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data) {
      localStorage.removeItem(STORAGE)
      yield put({
        type: ACTIONS.USER.LOGOUT,
      })
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}


export function* forgotPassword(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.SUPER_ADMIN.USER.FORGOT_PASSWORD, data);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.success) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* resetPassword(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.SUPER_ADMIN.USER.RESET_PASSWORD, data);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.success) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* updateProfile(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.SUPER_ADMIN.USER.DETAILS, data,true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.success) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* changeAdminPassword(action: any) {
  try {
    const data = action.payload;
   
    const resp = yield call(CallApi.POST, API_URL.SUPER_ADMIN.USER.CHANGE_PASSWORD, data,true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* getAllRegisteredCompany(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.GET, API_URL.SUPER_ADMIN.TENANT.GET_ALL_REGISTERED_COMPANY_LIST, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data) {
      yield put({
        type: ACTIONS.SUPER_ADMIN.TENANT.GET_ALL_REGISTERED_COMPANY_LIST,
        payload: resp.data
      })
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}


export function* changeTenantStatus(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.SUPER_ADMIN.TENANT.CHANGE_REGISTERED_COMPANY_STATUS, data, true);
    // console.log('changeTenantStatus',resp);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}


export function* adminUserUploadProfilePicture(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.SUPER_ADMIN.USER.UPLOAD_PROFILE_PICTURE, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.success) {
      yield put({
        type: ACTIONS.USER.ME,
        payload: resp.data.data
      })
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* adminUserRemoveProfilePicture(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.SUPER_ADMIN.USER.DELETE_PROFILE_PICTURE, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.success) {
      yield put({
        type: ACTIONS.USER.ME,
        payload: resp.data.data
      })
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* getAssignedResources(action: any) {
   try {
     const data = action.payload;
     const resp = yield call(CallApi.GET, API_URL.SUPER_ADMIN.USER.GET_RESOURCES, data, true);
     if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.success) {
       action && action.callbackSuccess && action.callbackSuccess(resp.data);
     } else {
       action && action.callbackError && action.callbackError(resp.data);
     }
   } catch (e) {
     action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
   }
 }

 export function* searchAboutMe(action: any) {
    try {
      const data = action.payload;
      const resp = yield call(CallApi.GET, API_URL.SUPER_ADMIN.COMMON.SEARCH_ABOUT_ME, data);
      if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.success) {
        action && action.callbackSuccess && action.callbackSuccess(resp.data);
      } else {
        action && action.callbackError && action.callbackError(resp.data);
      }
    } catch (e) {
      action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
  }

  export function* getModulePermission(action: any) {
     try {
       const data = action.payload;
       const resp = yield call(CallApi.GET, API_URL.SUPER_ADMIN.USER.GET_MODULE_PERMISSION, data, true);
       if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.success) {
        yield put({
          type: ACTIONS.SUPER_ADMIN.TENANT.GET_MODULE_PERMISSION,
          payload: resp.data.data
        })
         action && action.callbackSuccess && action.callbackSuccess(resp.data);
       } else {
         action && action.callbackError && action.callbackError(resp.data);
       }
     } catch (e) {
       action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
     }
   }

   export function* updateTenantModulePermission(action: any) {
     try {
       const data = action.payload;
       const resp = yield call(CallApi.POST, API_URL.SUPER_ADMIN.USER.UPDATE_TENANT_MODULE_PERMISSION, data, true);
       if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.success) {
         action && action.callbackSuccess && action.callbackSuccess(resp.data);
       } else {
         action && action.callbackError && action.callbackError(resp.data);
       }
     } catch (e) {
       action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
     }
   }

   export function* resendVerificationEmail(action: any) {
     try {
       const data = action.payload;
       const resp = yield call(CallApi.POST, API_URL.SUPER_ADMIN.USER.RESEND_VERIFICATION_EMAIL, data, true);
       if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.success) {
         action && action.callbackSuccess && action.callbackSuccess(resp.data);
       } else {
         action && action.callbackError && action.callbackError(resp.data);
       }
     } catch (e) {
       action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
     }
   }