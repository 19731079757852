import { type } from 'os';
import { ActionExtended } from '../../../_common/interfaces/ActionExtended';
import { City, Country, SiteInfo, State } from '../../../_common/interfaces/models/_common';
import { ACTIONS } from '../../../_config'

export interface GlobalReducer {
  countries: Country[];
  states: State[];
  cities: City[];
  siteInfo: SiteInfo | null;
  userName: string;
}

const initialState: GlobalReducer = {
  countries: [],
  states: [],
  cities: [],
  siteInfo: null,
  userName: "",
};

const globalsReducer = (state = initialState, action: ActionExtended) => {
  switch (action.type) {
    case ACTIONS.TENANT.COMMON.COUNTRY_LIST:
      return {
        ...state,
        countries: action.payload,
      }
    case ACTIONS.TENANT.COMMON.STATE_LIST:
      return {
        ...state,
        states: action.payload,
      }
    case ACTIONS.TENANT.COMMON.CITY_LIST:
      return {
        ...state,
        cities: action.payload,
      }
    case ACTIONS.TENANT.COMMON.RESET_COUNTRY_LIST:
      return {
        ...state,
        countries: [],
      }
    case ACTIONS.TENANT.COMMON.RESET_STATE_LIST:
      return {
        ...state,
        states: [],
      }
    case ACTIONS.TENANT.COMMON.RESET_CITY_LIST:
      return {
        ...state,
        cities: [],
      }
    case ACTIONS.SUPER_ADMIN.COMMON.SITE_INFO:
      return {
        ...state,
        siteInfo: action.payload,
      }
    case ACTIONS.GLOBAL.SET_USERNAME:
      return {
        ...state,
        userName: action.payload,
      }
    default:
      return state;
  }
};

export default globalsReducer;
