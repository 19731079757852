import { call, put } from 'redux-saga/effects';
import { ACTIONS, API_URL, SAGA_ACTIONS, STORAGE } from '../../_config';
import { CallApi } from '../api/callApi';


export function* getAllTrainingList(action: any) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.GET, API_URL.TENANT.PROJECT.TRAINING.GET_ALL_TRAINING, data, true);
      if (resp.status >= 200 && resp.status < 300 && resp.data) {
         yield put({
            type: ACTIONS.TENANT.PROJECT.TRAINING.GET_ALL_TRAINING,
            payload: resp.data.data,
         })
         action && action.callbackSuccess && action.callbackSuccess(resp.data);
      } else {
         action && action.callbackError && action.callbackError(resp.data);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
   }
}

export function* getSingleTrainingDetails(action: any) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.GET, API_URL.TENANT.PROJECT.TRAINING.GET_SINGLE_TRAINING_DETAILS, data, true);
      if (resp.status >= 200 && resp.status < 300 && resp.data) {
         yield put({
            type: ACTIONS.TENANT.PROJECT.TRAINING.GET_SINGLE_TRAINING_DETAILS,
            payload: resp.data.data,
         })
         action && action.callbackSuccess && action.callbackSuccess(resp.data);
      } else {
         action && action.callbackError && action.callbackError(resp.data);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
   }
}

export function* addEditTraining(action: any) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.POST, API_URL.TENANT.PROJECT.TRAINING.ADD_TRAINING, data, true);
      if (resp.status >= 200 && resp.status < 300 && resp.data) {
         action && action.callbackSuccess && action.callbackSuccess(resp.data);
      } else {
         action && action.callbackError && action.callbackError(resp.data);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
   }
}

export function* deleteTraining(action: any) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.DELETE, API_URL.TENANT.PROJECT.TRAINING.DELETE_TRAINING, data, true);
      if (resp.status >= 200 && resp.status < 300 && resp.data) {
         action && action.callbackSuccess && action.callbackSuccess(resp.data);
      } else {
         action && action.callbackError && action.callbackError(resp.data);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
   }
}

export function* downloadTrainingExcel(action: any) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.POST, API_URL.TENANT.PROJECT.TRAINING.DOWNLOAD_TRAINING_EXCEL, data, true);
      if (resp.status >= 200 && resp.status < 300 && resp.data) {
         action && action.callbackSuccess && action.callbackSuccess(resp.data);
      } else {
         action && action.callbackError && action.callbackError(resp.data);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
   }
}

export function* shareTrainingData(action: any) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.POST, API_URL.TENANT.PROJECT.TRAINING.SHARE_TRAINING_DATA, data, true);
      if (resp.status >= 200 && resp.status < 300 && resp.data) {
         action && action.callbackSuccess && action.callbackSuccess(resp.data);
      } else {
         action && action.callbackError && action.callbackError(resp.data);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
   }
}

export function* publicGetTrainingShareDetails(action: any) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.POST, API_URL.TENANT.PROJECT.TRAINING.GET_TRAINING_SHARE_DETAILS, data, false);
      if (resp.status >= 200 && resp.status < 300 && resp.data) {
         action && action.callbackSuccess && action.callbackSuccess(resp.data);
      } else {
         action && action.callbackError && action.callbackError(resp.data);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
   }
}

// export function* sendForApproval(action: any) {
//    try {
//       const data = action.payload;
//       const resp = yield call(CallApi.POST, API_URL.TENANT.PROJECT.COMMS.SEND_FOR_APPROVAL, data, true);
//       if (resp.status >= 200 && resp.status < 300 && resp.data) {
//          action && action.callbackSuccess && action.callbackSuccess(resp.data);
//       } else {
//          action && action.callbackError && action.callbackError(resp.data);
//       }
//    } catch (e) {
//       action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
//    }
// }



// export function* getApprovalDetails(action: any) {
//    try {
//       const data = action.payload;
//       const resp = yield call(CallApi.POST, API_URL.TENANT.PROJECT.COMMS.GET_APPROVAL_DETAILS, data, false);
//       if (resp.status >= 200 && resp.status < 300 && resp.data) {
//          action && action.callbackSuccess && action.callbackSuccess(resp.data);
//       } else {
//          action && action.callbackError && action.callbackError(resp.data);
//       }
//    } catch (e) {
//       action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
//    }
// }

// export function* saveApprovalWithComments(action: any) {
//    try {
//       const data = action.payload;
//       const resp = yield call(CallApi.POST, API_URL.TENANT.PROJECT.COMMS.SAVE_APPROVAL_RESPONSE, data, false);
//       if (resp.status >= 200 && resp.status < 300 && resp.data) {
//          action && action.callbackSuccess && action.callbackSuccess(resp.data);
//       } else {
//          action && action.callbackError && action.callbackError(resp.data);
//       }
//    } catch (e) {
//       action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
//    }
// }
