import { ActionExtended } from "../../../../../_common/interfaces/ActionExtended";
import { ACTIONS } from "../../../../../_config";
import {ReportList, Report, ChangeLoadReport} from '../../../../../_common/interfaces/models/reports';

export interface ReportsReducer {
   reportList: ReportList[] | null;
   report: Report | null;
   changeLoadReport: ChangeLoadReport | null;
}

const initialState: ReportsReducer = {
   reportList: null,
   report:null,
   changeLoadReport:null,
};

const reportsReducer = (state = initialState, action: ActionExtended) => {
   switch (action.type) {
      case ACTIONS.TENANT.PROJECT.REPORTS.GET_ALL_REPORTS:
         return {
            ...state,
            reportList: action.payload
         }
      case ACTIONS.TENANT.PROJECT.REPORTS.GET_REPORT:
         return {
            ...state,
            report: action.payload
         }
      case ACTIONS.TENANT.PROJECT.REPORTS.GET_CHANGE_LOAD_REPORT:
         return {
            ...state,
            changeLoadReport: action.payload
         }
      default:
         return state
   }
}

export default reportsReducer