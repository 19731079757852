import { ActionExtended } from '../../../_common/interfaces/ActionExtended';
import { CompanyProfile } from '../../../_common/interfaces/models/companyProfile';
import { User } from '../../../_common/interfaces/models/user';
import { ACTIONS } from '../../../_config'

export interface UserReducer {
  user: User | null;
  token: string | null;
  isAuthenticated: boolean;
  companyProfile: CompanyProfile | null;
}

const initialState: UserReducer = {
  user: null,
  token: null,
  isAuthenticated: false,
  companyProfile: null,
};

const userReducer = (state = initialState, action: ActionExtended) => {
  switch (action.type) {
    case ACTIONS.USER.LOGIN:
      return {
        ...state,
        user: action.payload.user,
        token: action.payload.token,
        isAuthenticated: true,
      };
    case ACTIONS.USER.LOGOUT:
      return {
        ...state,
        ...initialState,
      };
    case ACTIONS.USER.ME:
      return {
        ...state,
        user: action.payload,
        isAuthenticated: true,
      };
    case ACTIONS.TENANT.USER.COMPANY_PROFILE:
      return {
        ...state,
        companyProfile: action.payload,
      };
    case ACTIONS.TENANT.USER.RESET_COMPANY_PROFILE:
      return {
        ...state,
        companyProfile: null,
      };
    default:
      return state;
  }
};

export default userReducer;
