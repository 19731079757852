import { ActionExtended } from "../../../../_common/interfaces/ActionExtended";
import { ChangeManagerListing } from "../../../../_common/interfaces/models/user";
import { ACTIONS } from "../../../../_config";

export interface ChangeManagerReducer {
   userListing: ChangeManagerListing | null
}

const initialState: ChangeManagerReducer = {
   userListing: null,
};

const changeManagerReducer = (state = initialState, action: ActionExtended) => {
   switch (action.type) {
      case ACTIONS.TENANT.CH_MANGER.USER_LISTING:
         return {
            ...state,
            userListing: action.payload
         }
      case ACTIONS.TENANT.CH_MANGER.DELETE_USER:
         let usersArr = JSON.parse(JSON.stringify(state.userListing?.data))
         usersArr = usersArr?.filter((item: any) => item.id !== action.payload.ids[0])
         return {
            ...state,
            userListing: {
               ...state.userListing,
               data: usersArr
            }
         }
      default:
         return state
   }
}

export default changeManagerReducer