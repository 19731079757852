import { ActionExtended } from '../../../../_common/interfaces/ActionExtended';
import { ACTIONS } from '../../../../_config'
import { SearchContactType, ClientWithUsers, ClientList } from '../../../../_common/interfaces/models/client'
import { TenantAboutMe } from '../../../../_common/interfaces/models/tenantAboutMe';
import { CompanyProfile } from '../../../../_common/interfaces/models/companyProfile';

export interface TenantCommonReducer {
  aboutMeListing: TenantAboutMe[],
  dialCodeList: any[];
}

const initialState: TenantCommonReducer = {
  aboutMeListing: [],
  dialCodeList: []
};

const tenantCommonReducer = (state = initialState, action: ActionExtended) => {
  switch (action.type) {
    case ACTIONS.TENANT.COMMON.ABOUT_ME_LIST:
      return {
        ...state,
        aboutMeListing: action.payload,
      }
    case ACTIONS.TENANT.COMMON.RESET_ABOUT_ME_LIST:
      return {
        ...state,
        aboutMeListing: [],
      }
    case ACTIONS.TENANT.COMMON.SEARCH_DIALCODE:
      return {
        ...state,
        dialCodeList: action.payload,
      }
    default:
      return state;
  }
};

export default tenantCommonReducer;
