import React from 'react';

const LazyTenantMyAccount = React.lazy(() =>
  import(/* webpackChunkName: "TenantMyAccount" */ './tenantMyAccount')
);

const TenantMyAccount = (props: Record<string, any>) => (
  <React.Suspense fallback={<h1>Loading...</h1>}>
    <LazyTenantMyAccount {...props} />
  </React.Suspense>
);

export default TenantMyAccount