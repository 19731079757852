import { combineReducers } from 'redux';

import loaderReducer from './common/loaderReducer'
import userReducer from './user/userReducer'
import clientReducer from './tenant/client/clientReducer'
import superAdminTenantReducer from './super-admin/tenant/tenantReducer'
// import companyReducer from './company/companyReducer'
import roleReducer from './tenant/role/roleReducer'
import globalsReducer from './common/globalsReducer'
import tenantCommonReducer from './tenant/common/tenantCommonReducer'
import changeManagerReducer from './tenant/change-manager/chManagerReducer';
import projectReducer from './tenant/project/projectReducer';
import inputsReducer from './tenant/project/inputs/inputsReducer';
import organogramReducer from './tenant/project/organogram/organogramReducer'
import engagementReducer from './tenant/project/engagement/engagementReducer'
import changePlanReducer from './tenant/project/change-plan/changePlanReducer';
import impactReducer from './tenant/project/impact/impactReducer';
import surveyReducer from './tenant/project/survey/surveyReducer';
import reportsReducer from './tenant/project/reports/reportsReducer';
import assessmentReducer from './tenant/project/assessment/assessmentReducer';
import commsReducer from './tenant/project/comms/commsReducer';
import trainingReducer from './tenant/project/training/trainingReducer';


const rootReducer = combineReducers({
  loader: loaderReducer,
  globals: globalsReducer,
  user: userReducer,
  client: clientReducer,
  adminTenant: superAdminTenantReducer,
  role: roleReducer,
  tenantCommon: tenantCommonReducer,
  changeManager: changeManagerReducer,
  project: projectReducer,
  inputs: inputsReducer,
  organogram: organogramReducer,
  engagement: engagementReducer,
  impact :impactReducer,
  survey :surveyReducer,
  reports :reportsReducer,
  // company:companyReducer,
  changePlan: changePlanReducer,
  assessment:assessmentReducer,
  comms:commsReducer,
  training:trainingReducer
})

export default rootReducer