import { call, put } from 'redux-saga/effects';
import { ACTIONS, API_URL, SAGA_ACTIONS, STORAGE } from '../../_config';
import { CallApi } from '../api/callApi';

export function* getContactType(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.GET, API_URL.TENANT.CLIENT.GET_CONTACT_TYPE, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data) {
      yield put({
        type: ACTIONS.TENANT.CLIENT.GET_CONTACT_TYPE,
        payload: resp.data
      })
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* searchUserByEmail(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.GET, API_URL.TENANT.CLIENT.SEARCH_USER_BY_EMAIL, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data) {
      // yield put({
      //   type: ACTIONS.CLIENT.SEARCH_USER_BY_EMAIL,
      //   payload: resp.data
      // })
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* addClientWithUser(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.TENANT.CLIENT.ADD_CLIENT_WITH_USER, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* addClientObjective(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.TENANT.CLIENT.ADD_CLIENT_OBJECTIVE, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}


export function* getParticularClientWithUser(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.GET, API_URL.TENANT.CLIENT.GET_PARTICULAR_CLIENT_WITH_USERS, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data) {
      yield put({
        type: ACTIONS.TENANT.CLIENT.GET_PARTICULAR_CLIENT_WITH_USERS,
        payload: resp.data
      })
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}


export function* setClientUserToTeamMembers(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.TENANT.CLIENT.SET_CLIENT_USERS_TO_TEAM_MEMBERS, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* assignRoleToUser(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.TENANT.CLIENT.ASSIGN_ROLE_TO_USERS, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* getAllClientLists(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.GET, API_URL.TENANT.CLIENT.GET_CLIENT_LISTS, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data) {
      yield put({
        type: ACTIONS.TENANT.CLIENT.GET_CLIENT_LISTS,
        payload: resp.data
      })
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* getAllDraftClientLists(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.GET, API_URL.TENANT.CLIENT.GET_CLIENT_LISTS, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data) {
      yield put({
        type: ACTIONS.TENANT.CLIENT.GET_DRAFT_CLIENT_LISTS,
        payload: resp.data
      })
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* getClientDetails(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.GET, API_URL.TENANT.CLIENT.GET_CLIENT_DETAILS, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data) {
      yield put({
        type: ACTIONS.TENANT.CLIENT.GET_CLIENT_DETAILS,
        payload: resp.data.data
      })
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* completeClientSetup(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.TENANT.CLIENT.SETUP_CLIENT_COMPLETE, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* getProjectsTimeline(action:any){
  try {
    const data = action.payload;
    const resp = yield call(CallApi.GET, API_URL.TENANT.CLIENT.GET_PROJECTS_TIMELINE, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}
