import { getSubdomain } from './functions';
import { API_BASE_URL } from './site_urls'
export const STORAGE = 'cg_mg_ui'

export const APP_VERSION = process.env.REACT_APP_VERSION
export const APP_STAGE = process.env.REACT_APP_STAGE

export const APP_URL = APP_STAGE == 'dev' ? window.location.origin : window.location.origin;

export const EXCEL_STAKEHOLDER_FILE_URL = (APP_STAGE == 'dev' || APP_STAGE == 'stage') ? 'https://api-change-manager.glohtesting.com:9595/samples/stakeholders_sample.xlsx' : APP_STAGE == 'uat' ? 'https://api-change-manager-uat.glohtesting.com:8585/samples/stakeholders_sample.xlsx' : 'https://api.changd.net/samples/stakeholders_sample.xlsx'

export const EXCEL_PROJECT_USER_FILE_URL = (APP_STAGE == 'dev' || APP_STAGE == 'stage') ? 'https://api-change-manager.glohtesting.com:9595/samples/user_sample.xlsx' : APP_STAGE == 'uat' ? 'https://api-change-manager-uat.glohtesting.com:8585/samples/user_sample.xlsx' : 'https://api.changd.net/samples/user_sample.xlsx'

//src/index.ts,src/_common/hoc/reqNoAuth.tsx  change-manager manager conditionaly hardcodeed 

let mainDomainUrl = ''
let subDomainCheck = ''
let companySubDomain: any = ''
let redirectUrl = ''

if (APP_STAGE == 'dev') {

  // mainDomainUrl = 'http://change-dev.com/'
  // subDomainCheck = 'change-dev'
  // companySubDomain = 'sunayan' || 'testdevchangemanager'
  // redirectUrl = 'https://change-manager-comp1.glohtesting.com/tenant/login'

  mainDomainUrl = 'http://change-dev.com/'
  subDomainCheck = 'change-dev'
  companySubDomain = 'change-manager-comp2' || 'testdevchangemanager'
  redirectUrl = 'https://change-manager-comp2.glohtesting.com/tenant/login'

} else if (APP_STAGE == 'stage') {
  mainDomainUrl = 'https://change-manager.glohtesting.com/'
  subDomainCheck = 'change-manager'
  companySubDomain = getSubdomain()
  redirectUrl = 'https://change-manager-comp1.glohtesting.com/tenant/login'
  // mainDomainUrl = 'https://cm.glohtesting.com/'
  // subDomainCheck = 'cm'
  // companySubDomain = getSubdomain()
  // redirectUrl = 'https://cm-comp1.glohtesting.com/tenant/login'
} else if (APP_STAGE == 'uat') {
  mainDomainUrl = 'https://change-manager-uat.glohtesting.com/'
  subDomainCheck = 'change-manager-uat'
  companySubDomain = getSubdomain()
  redirectUrl = 'https://change-manager-comp1-uat.glohtesting.com/tenant/login'
  // mainDomainUrl = 'https://changd.net/'
  // subDomainCheck = 'changd'
  // companySubDomain = getSubdomain()
  // redirectUrl = 'https://changd.net/tenant/login'
} else {
  mainDomainUrl = 'https://changd.net/'
  subDomainCheck = 'changd'
  companySubDomain = getSubdomain()
  redirectUrl = 'https://changd.net/tenant/login'
  // mainDomainUrl = 'https://cm.glohtesting.com/'
  // subDomainCheck = 'cm'
  // companySubDomain = getSubdomain()
  // redirectUrl = 'https://cm-comp1.glohtesting.com/tenant/login'
}

export const MAIN_DOMAIN_URL = mainDomainUrl
export const SUB_DOMAIN_CHECK = subDomainCheck

export const COMPANY_SUBDOMAIN = companySubDomain
export const REDIRECT_URL = redirectUrl


export const PER_PAGE = 10;
export const WITHOUT_PER_PAGE = 99999;

export enum URL_AVAILABLE {
  ADMIN_ONLY = 'admin_only',
  TENANT_ONLY = 'tenant_only',
  BOTH = 'both'
}

export const URLS = {
  HOME: '/',
  JOIN_US: '/join-us',
  REG: '/reg',
  LOGIN: '/login/:message?',
  UNAUTHORIZED: '/access-denied',
  VERIFY_EMAIL: '/verify-email/:token',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password/:token',
  TENANT: {
    USER: {
      LOGIN: '/tenant/login/:email?',
      DASHBOARD: '/tenant/dashboard',
      ABOUT_ME: '/tenant/about-me',
      MY_ACCOUNT: '/tenant/account',
      FORGOT_PASSWORD: '/tenant/forgot-password',
      RESET_PASSWORD: '/tenant/reset-password/:token',
      VERIFY_EMAIL: '/tenant/verify-email/:token',
    },
    ROLE: {
      ROLE_LISTING: '/tenant/role-listing',
      PERMISSION_ASSIGN: '/tenant/role-permission-assign/:id',
    },
    CLIENT: {
      CLIENT: '/tenant/client',
      CLIENT_SETUP: '/tenant/client-setup/:clientSlug?'
    },
    CH_MANGER: {
      USER_LISTING: '/tenant/change-manager/listing',
      USER_ADD: '/tenant/change-manager/add',
      USER_EDIT: '/tenant/change-manager/add/:id'
    },
    PROJECT: {
      PROJECT: '/tenant/:clientSlug/projects',
      PROJECT_SETUP: '/tenant/:clientSlug/project-setup',
      INPUTS: '/tenant/:clientSlug/:projectSlug/inputs',
      CHANGES: '/tenant/:clientSlug/:projectSlug/change-plan',
      ORGNANOGRAM: '/tenant/:clientSlug/:projectSlug/organogram',
      ENGAGEMENT: '/tenant/:clientSlug/:projectSlug/engagement',
      IMPACT: '/tenant/:clientSlug/:projectSlug/impact',
      DASHBOARD: '/tenant/:clientSlug/:projectSlug/dashboard',
      COMMS: '/tenant/:clientSlug/:projectSlug/comms/:id?',
      COMMS_APPROVAL: '/tenant/comment/:pageCategory/:token/:id?',
      TRAINING: '/tenant/:clientSlug/:projectSlug/training',
      SURVEY: '/tenant/:clientSlug/:projectSlug/survey',
      POLL: '/tenant/poll/:token',
      ASSESSMENT: '/tenant/:clientSlug/:projectSlug/assessment',
      REPORTS: '/tenant/:clientSlug/:projectSlug/reports',
      PUBLIC_SHARE: '/tenant/share/:pageType/:token/:id?',
      CHANGE_LOAD: '/tenant/:clientSlug/change-load',
    }

  },
  SUPER_ADMIN: {
    USER: {
      DASHBOARD: '/admin/dashboard',
      MY_ACCOUNT: '/admin/account',
    },
    TENANT: {
      TENANT_LISTING: '/admin/tenant-listing'
    }
  },
}

export const API_URL = {
  SUPER_ADMIN: {
    USER: {
      LOGIN: `${API_BASE_URL}admin/auth/login`,
      REGISTRATION: `${API_BASE_URL}admin/user/register-tenant`,
      VERIFY_EMAIL: `${API_BASE_URL}admin/user/verify-email`,
      LOGOUT: `${API_BASE_URL}admin/auth/logout`,
      CHECK_LOGIN: `${API_BASE_URL}admin/auth/check-login`,
      CHANGE_PASSWORD: `${API_BASE_URL}admin/user/change-password`,
      FORGOT_PASSWORD: `${API_BASE_URL}admin/user/forgot-password`,
      RESET_PASSWORD: `${API_BASE_URL}admin/user/reset-password`,
      DETAILS: `${API_BASE_URL}admin/user/profile`,
      UPLOAD_PROFILE_PICTURE: `${API_BASE_URL}admin/user/upload-profile-picture`,
      DELETE_PROFILE_PICTURE: `${API_BASE_URL}admin/user/delete-profile-picture`,
      GET_RESOURCES: `${API_BASE_URL}admin/user/resource-count`,
      GET_MODULE_PERMISSION: `${API_BASE_URL}admin/user/get-module-permission`,
      UPDATE_TENANT_MODULE_PERMISSION: `${API_BASE_URL}admin/user/update-tenant-module-permission`,
      RESEND_VERIFICATION_EMAIL: `${API_BASE_URL}admin/user/resend-verification-email`,
    },
    TENANT: {
      GET_ALL_REGISTERED_COMPANY_LIST: `${API_BASE_URL}admin/user/search-tenant`,
      CHANGE_REGISTERED_COMPANY_STATUS: `${API_BASE_URL}admin/user/change-tenant-status`,
    },
    COMMON: {
      CHECK_SUBDOMAIN_AVAILABILITY: `${API_BASE_URL}admin/common/search-host-availability`,
      UPLOAD_FILES: `${API_BASE_URL}admin/common/upload-files`,
      GET_FILES: `${API_BASE_URL}admin/common/get-files`,
      DELETE_FILES: `${API_BASE_URL}admin/common/delete-files`,
      SITE_INFO: `${API_BASE_URL}admin/common/site-info`,
      SEARCH_ABOUT_ME: `${API_BASE_URL}admin/common/search-about-me`,
    }
  },
  TENANT: {
    USER: {
      DETAILS: `${API_BASE_URL}tenant/user/profile`,
      LOGIN: `${API_BASE_URL}tenant/auth/login`,
      LOGOUT: `${API_BASE_URL}tenant/auth/logout`,
      FORGOT_PASSWORD: `${API_BASE_URL}tenant/user/forgot-password`,
      RESET_PASSWORD: `${API_BASE_URL}tenant/user/reset-password`,
      CHANGE_PASSWORD: `${API_BASE_URL}tenant/user/change-password`,
      VERIFY_EMAIL: `${API_BASE_URL}tenant/user/verify-email`,
      UPLOAD_PROFILE_PICTURE: `${API_BASE_URL}tenant/user/upload-profile-picture`,
      DELETE_PROFILE_PICTURE: `${API_BASE_URL}tenant/user/delete-profile-picture`,
      COMPANY_PROFILE: `${API_BASE_URL}tenant/user/company-profile`,
      RESORCES_COUNT: `${API_BASE_URL}tenant/user/resource-count`,
    },
    ROLE: {
      ADD_ROLE: `${API_BASE_URL}tenant/role/add-edit-role`,
      DELETE_ROLE: `${API_BASE_URL}tenant/role/delete-role`,
      ROLE_LISTING: `${API_BASE_URL}tenant/role/search-role`,
      ASSIGN_ROLE_PERMISSION: `${API_BASE_URL}tenant/role/assign-role-permission`,
      GET_ALL_PERMISSION: `${API_BASE_URL}tenant/role/search-permission-master`,
      GET_ROLE_PERMISSION_DETAILS: `${API_BASE_URL}tenant/role/get-role-details`,
    },
    CLIENT: {
      GET_CONTACT_TYPE: `${API_BASE_URL}tenant/client/search-contact-type`,
      SEARCH_USER_BY_EMAIL: `${API_BASE_URL}tenant/user/search-by-email`,
      ADD_CLIENT_WITH_USER: `${API_BASE_URL}tenant/client/create-new-client-with-users`,
      ADD_CLIENT_OBJECTIVE: `${API_BASE_URL}tenant/client/add-edit-client-objectives`,
      GET_PARTICULAR_CLIENT_WITH_USERS: `${API_BASE_URL}tenant/client/search-client-users`,
      SET_CLIENT_USERS_TO_TEAM_MEMBERS: `${API_BASE_URL}tenant/client/set-client-users`,
      ASSIGN_ROLE_TO_USERS: `${API_BASE_URL}tenant/client/set-client-users-roles`,
      GET_CLIENT_LISTS: `${API_BASE_URL}tenant/client/search-clients`,
      GET_CLIENT_DETAILS: `${API_BASE_URL}tenant/client/get-client-details`,
      SETUP_CLIENT_COMPLETE: `${API_BASE_URL}tenant/client/setup-client`,
      GET_PROJECTS_TIMELINE: `${API_BASE_URL}tenant/project/get-project-overview`,
    },
    CH_MANGER: {
      ADD_NEW_USER: `${API_BASE_URL}tenant/user/add-edit-user`,
      GET_USER_LIST: `${API_BASE_URL}tenant/user/search`,
      DELETE_USER: `${API_BASE_URL}tenant/user/delete-users`
    },
    PROJECT: {
      ADD_PROJECT: `${API_BASE_URL}tenant/project/add-edit-project`,
      DELETE_DRAFTED_PROJECT: `${API_BASE_URL}tenant/project/delete-project`,
      PROJECT_LIST: `${API_BASE_URL}tenant/project/search-project`,
      ADD_PROJECT_FILES: `${API_BASE_URL}tenant/project/add-project-files`,
      REMOVE_PROJECT_FILES: `${API_BASE_URL}tenant/project/delete-project-files`,
      PROJECT_DETAIL: `${API_BASE_URL}tenant/project/get-project-details`,
      UPLOAD_EXCEL_FILE: `${API_BASE_URL}tenant/project/upload-stakeholders-excel`,
      UPLOAD_PROJECT_EXCEL_FILE: `${API_BASE_URL}tenant/project/upload-project-teams-excel`,
      ADD_EDIT_STAKEHOLDER: `${API_BASE_URL}tenant/project/add-edit-stakeholders`,
      AVAIL_PROJECT_USERS: `${API_BASE_URL}tenant/project/search-available-users`,
      SEARCH_PROJECT_USERS: `${API_BASE_URL}tenant/project/search-project-users`,
      ADD_USER_TO_PROJECT: `${API_BASE_URL}tenant/project/add-edit-user-project-team`,
      REMOVE_USER_FROM_PROJECT: `${API_BASE_URL}tenant/project/delete-user-project-team`,
      ADD_NEW_PROJECT_USER: `${API_BASE_URL}tenant/project/add-new-project-user`,

      CHANGE_PROJECT_USER_ROLE: `${API_BASE_URL}tenant/project/change-project-user-role`,
      GET_STAKEHOLDERS: `${API_BASE_URL}tenant/project/search-stakeholders`,
      UPDATE_PROJECT_USER: `${API_BASE_URL}tenant/user/add-edit-user`,
      SETUP_FINAL: `${API_BASE_URL}tenant/project/setup-project`,
      PROJECT_CHANGE_QUESTION_MASTER: `${API_BASE_URL}tenant/project/search-project-change-questions-master`,
      PROJECT_CHANGE_QUESTION_SAVE_UPDATE: `${API_BASE_URL}tenant/project/update-project-change-definitions`,
      PROJECT_CUSTOM_QUESTION_ANSWER:`${API_BASE_URL}tenant/project/create-custom-question`,
      GET_PROJECT_METHODOLOGY_MASTER: `${API_BASE_URL}tenant/project/search-project-methodologies-master`,
      SET_PROJECT_METHODOLOGY: `${API_BASE_URL}tenant/project/update-project-methodology`,
      SEARCH_PROJECT_METHODOLOGY_QUESTIONS: `${API_BASE_URL}tenant/project/search-project-methodology-questions`,
      INPUTS: {
        UPLOAD_DOCUMENT: `${API_BASE_URL}tenant/project/upload-documents`,
        UPLOAD_VERSION_CONTROL_FILES_FROM_INPUT_SECTION: `${API_BASE_URL}tenant/project/add-new-version-project-files`,
      },
      ENGAGEMENT: {
        ADD_ENGAGEMENT: `${API_BASE_URL}tenant/project/add-edit-engagement`,
        // GET_ENGAGEMENT_LIST: `${API_BASE_URL}tenant/project/get-engagement-details`,
        DELETE_ENGAGEMENT: `${API_BASE_URL}tenant/project/delete-engagement`,
        DUPLICATE_ENGAGEMENT: `${API_BASE_URL}tenant/project/duplicate-engagement-details`,
        UPDATE_STATUS: `${API_BASE_URL}tenant/project/update-engagement-status`,
        UPDATE_ENGAGEMENT: `${API_BASE_URL}tenant/project/update-engagement`,
        GET_ENGAGEMENT_DETAILS: `${API_BASE_URL}tenant/project/get-engagement-details`,
        PUBLIC_GET_ENGAGEMENT_DETAILS: `${API_BASE_URL}tenant/project/get-details`
      },
      ORGANOGRAM: {
        ADD_EDIT_ORGANOGRAM: `${API_BASE_URL}tenant/project/add-edit-organogram`,
        GET_ORGANOGRAM_DETAILS: `${API_BASE_URL}tenant/project/get-organogram-details`,
        GET_ALL_ORGANOGRAMS: `${API_BASE_URL}tenant/project/get-all-organogram-details`,
        REMOVE_ORGANOGRAM: `${API_BASE_URL}tenant/project/delete-organogram`,
        GET_DEPARTMENT_AND_DIVISION: `${API_BASE_URL}tenant/project/search-stakeholders-department-division`,
        // STAKEHOLDER MATRIX
        ADD_EDIT_STAKEHOLDER_MATRIX: `${API_BASE_URL}tenant/project/add-edit-stakeholder-matrix`,
        GET_STAKEHOLDER_MATRIX_DETAILS: `${API_BASE_URL}tenant/project/get-stakeholder-matrix-details`,
        GET_ALL_STAKEHOLDER_MATRIX: `${API_BASE_URL}tenant/project/get-all-stakeholder-matrix-details`,
        REMOVE_STAKEHOLDER_MATRIX: `${API_BASE_URL}tenant/project/delete-stakeholder-matrix`,
        REMOVE_STAKEHOLDER_FROM_STAKEHOLDER_MATRIX: `${API_BASE_URL}tenant/project/remove-stakeholders`,
        // INFLUENCE MAP
        ADD_EDIT_INFLUENCE_MAP: `${API_BASE_URL}tenant/project/add-edit-stakeholder-influence-map`,
        GET_INFLUENCE_MAP_DETAILS: `${API_BASE_URL}tenant/project/get-stakeholder-influence-map-details`,
        GET_ALL_INFLUENCE_MAP: `${API_BASE_URL}tenant/project/get-all-stakeholder-influence-map-details`,
        REMOVE_INFLUENCE_MAP: `${API_BASE_URL}tenant/project/delete-stakeholder-influence-map`,
        REMOVE_STAKEHOLDER_FROM_INFLUENCE_MAP: `${API_BASE_URL}tenant/project/remove-stakeholders`,
        SHARE_ORGANOGRAM: `${API_BASE_URL}tenant/project/share-link`,
        COPY_ORGANOGRAM: `${API_BASE_URL}tenant/project/copy-url`,
      },
      CHANGE_PLAN: {
        ADD_EDIT_CHANGE_PLAN: `${API_BASE_URL}tenant/project/add-edit-change-plan`,
        ADD_TO_CHANGE_PLAN: `${API_BASE_URL}tenant/project/add-edit-change-plan`,
        GET_CHANGE_PLAN_DETAILS: `${API_BASE_URL}tenant/project/get-change-plan-details`,
        GET_ALL_CHANGE_PLANS: `${API_BASE_URL}tenant/project/get-all-change-plan-details`,
        REMOVE_CHANGE_PLAN: `${API_BASE_URL}tenant/project/delete-change-plan`,
        UPDATE_CHANGE_PLAN_STATUS: `${API_BASE_URL}tenant/project/update-change-plan-status`,
        UPDATE_CHANGE_PLAN_START_ACTIVITY_STATUS: `${API_BASE_URL}tenant/project/update-change-plan-start-activity`,
        GET_CHANGE_PLAN_TIMELINE: `${API_BASE_URL}tenant/project/get-change-plan-timeline`,
        GET_TODAY_TIMELINE: `${API_BASE_URL}tenant/project/get-change-plan-timeline`,
      },
      IMPACT: {
        ADD_IMPACT: `${API_BASE_URL}tenant/project/add-edit-impact-assessment`,
        EDIT_IMPACT_NAME_DESCRIPTION: `${API_BASE_URL}tenant/project/update-impact-assessment-details`,
        GET_ALL_IMPACT: `${API_BASE_URL}tenant/project/get-all-impact-assessment-details`,
        GET_IMPACT_DETAILS: `${API_BASE_URL}tenant/project/get-impact-assessment-details`,
        DELETE_IMPACT: `${API_BASE_URL}tenant/project/delete-impact-assessment`,
        PUBLIC_GET_IMPACT_DETAILS: `${API_BASE_URL}tenant/project/get-details`,
        DOWNLOAD_IMPACT_EXCEL: `${API_BASE_URL}tenant/project/export-impact-data`,
      },
      SURVEY: {
        ADD_SURVEY: `${API_BASE_URL}tenant/feedback/add-edit-feedback`,
        DELETE_SURVEY: `${API_BASE_URL}tenant/feedback/delete-feedback`,
        GET_ALL_SURVEY: `${API_BASE_URL}tenant/feedback/get-all-feedback-details`,
        GET_SURVEY_DETAILS: `${API_BASE_URL}tenant/feedback/get-feedback-details`,
        GET_SURVEY_POLL: `${API_BASE_URL}tenant/feedback/get-feedback-details`,
        GET_SAVE_POLL: `${API_BASE_URL}tenant/feedback/save-feedback-response`,
        SHARE_POLL: `${API_BASE_URL}tenant/feedback/share-feedback-url`
      },
      REPORTS: {
        ADD_REPORT: `${API_BASE_URL}tenant/report/add-edit-report`,
        DELETE_REPORT: `${API_BASE_URL}tenant/report/delete-report`,
        GET_ALL_REPORTS: `${API_BASE_URL}tenant/report/get-all-reports`,
        GET_REPORT: `${API_BASE_URL}tenant/report/get-report`,
        GET_PUBLIC_REPORT_CHANGE_STORY: `${API_BASE_URL}tenant/project/get-change-report-details`,

        ADD_STATUS_REPORT: `${API_BASE_URL}tenant/status/add-edit-report`,
        GET_ALL_STATUS_REPORTS: `${API_BASE_URL}tenant/status/get-all-reports`,
        GET_STATUS_REPORT: `${API_BASE_URL}tenant/status/get-report`,
        DELETE_STATUS_REPORT: `${API_BASE_URL}tenant/status/delete-report`,
        DOWNLOAD_REPORT: `${API_BASE_URL}tenant/status/download-reports`,
        GET_CHANGE_LOAD_REPORT: `${API_BASE_URL}tenant/project/get-change-load-report`,

        ADD_CHANGE_REPORT: `${API_BASE_URL}tenant/change-report/add-edit-report`,
        GET_ALL_CHANGE_REPORT: `${API_BASE_URL}tenant/change-report/get-all-reports`,
        GET_SINGLE_CHANGE_REPORT: `${API_BASE_URL}tenant/change-report/get-report`,
        DELETE_CHANGE_REPORT: `${API_BASE_URL}tenant/change-report/delete-report`,
      },
      ASSESSMENT: {
        GET_ALL_PREDEFINE_ASSESSMENT_LIST: `${API_BASE_URL}tenant/change-assessment/search-project-change-assessments-master`,
        ADD_ASSESSMENT: `${API_BASE_URL}tenant/change-assessment/add-edit-change-assessment`,
        GET_ALL_ASSESSMENT: `${API_BASE_URL}tenant/change-assessment/get-all-change-assessment-details`,
        GET_PREDEFINED_CATEGORYWISE_QUESTION: `${API_BASE_URL}tenant/change-assessment/search-project-change-assessment-questions-master`,
        UPDATE_CATEGORYWISE_QUESTION_ANSWER: `${API_BASE_URL}tenant/change-assessment/update-project-change-assessment`,
        DELETE_ASSESSMENT: `${API_BASE_URL}tenant/change-assessment/delete-change-assessment`,
        PUBLIC_GET_ASSESSMENT_DETAILS: `${API_BASE_URL}tenant/change-assessment/get-assessment-details`,
        PUBLIC_SAVE_ASSESSMENT_RESPONSE: `${API_BASE_URL}tenant/change-assessment/save-assessment-response`,
        SAVE_CHANGE_ASSESSMENT_QUESTIONS: `${API_BASE_URL}tenant/change-assessment/save-change-assessment-questions`,
        RENAME_ASSESSMENT_SECTION: `${API_BASE_URL}tenant/change-assessment/rename-assessment-question-section`,
        DELETE_ASSESSMENT_SECTION: `${API_BASE_URL}tenant/change-assessment/delete-assessment-section`,
        DELETE_ASSESSMENT_QUESTION: `${API_BASE_URL}tenant/change-assessment/delete-assessment-question`,
        RENAME_ASSESSMENT_QUESTION: `${API_BASE_URL}tenant/change-assessment/rename-assessment-question`
      },
      COMMS: {
        ADD_COMMS: `${API_BASE_URL}tenant/communication/add-edit-communication`,
        GET_SINGLE_COMMS_DETAILS: `${API_BASE_URL}tenant/communication/get-communication-details`,
        GET_ALL_COMMS: `${API_BASE_URL}tenant/communication/get-all-communication-details`,
        DELETE_COMMS: `${API_BASE_URL}tenant/communication/delete-communication`,
        SEND_FOR_APPROVAL: `${API_BASE_URL}tenant/project/send-approval`,
        GET_APPROVAL_DETAILS: `${API_BASE_URL}tenant/project/get-approval-details`,
        SAVE_APPROVAL_RESPONSE: `${API_BASE_URL}tenant/project/save-approval-response`
      },
      TRAINING: {
        ADD_TRAINING: `${API_BASE_URL}tenant/training/add-edit-training`,
        GET_SINGLE_TRAINING_DETAILS: `${API_BASE_URL}tenant/training/get-training-details`,
        GET_ALL_TRAINING: `${API_BASE_URL}tenant/training/get-all-training-details`,
        DELETE_TRAINING: `${API_BASE_URL}tenant/training/delete-training`,
        DOWNLOAD_TRAINING_EXCEL: `${API_BASE_URL}tenant/training/download-data`,
        SHARE_TRAINING_DATA: `${API_BASE_URL}tenant/training/share-training-data`,
        GET_TRAINING_SHARE_DETAILS: `${API_BASE_URL}tenant/training/get-training-share-details`,
        // SEND_FOR_APPROVAL: `${API_BASE_URL}tenant/project/send-approval`,
        // GET_APPROVAL_DETAILS: `${API_BASE_URL}tenant/project/get-approval-details`,
        // SAVE_APPROVAL_RESPONSE: `${API_BASE_URL}tenant/project/save-approval-response`
      }

    },
    COMMON: {
      ABOUT_ME_LIST: `${API_BASE_URL}tenant/common/search-about-me-master`,
      IMAGE_UPLOAD: `${API_BASE_URL}tenant/common/upload-files`,
      DELETE_FILES: `${API_BASE_URL}tenant/common/delete-files`,
      GET_FILES: `${API_BASE_URL}tenant/common/get-files`,
      COUNTRY_LIST: `${API_BASE_URL}tenant/common/search-countries`,
      STATE_LIST: `${API_BASE_URL}tenant/common/search-states`,
      CITY_LIST: `${API_BASE_URL}tenant/common/search-cities`,
      SHARE_LINK: `${API_BASE_URL}tenant/project/share-link`,
      GET_PUBLIC_MODULE_DETAILS: `${API_BASE_URL}tenant/project/get-share-details`,
      GET_PUBLIC_ORGANOGRAM_DETAILS: `${API_BASE_URL}tenant/project/get-share-details`,
      GET_TENANT_MODULE_PERMISSION: `${API_BASE_URL}tenant/common/get-tenant-module-permission`,
      SEARCH_DIALCODE:`${API_BASE_URL}tenant/common/search-dialcode`,
    }
  }
}

export const ACTIONS = {
  LOADER: {
    SET_FP_STATUS: 'ACTIONS/LOADER/SET_FP_STATUS',
  },
  GLOBAL: {
    SET_LOGIN_MODAL: 'ACTIONS/GLOBAL/SET_LOGIN_MODAL',
    SET_USERNAME: 'ACTIONS/GLOBAL/SET_USERNAME',
  },
  USER: {
    LOGIN: 'ACTIONS/USER/LOGIN',
    ME: 'ACTIONS/USER/ME',
    LOGOUT: 'ACTIONS/USER/LOGOUT',
  },
  SUPER_ADMIN: {
    USER: {

    },
    TENANT: {
      GET_ALL_REGISTERED_COMPANY_LIST: 'ACTIONS/SUPER_ADMIN/TENANT/GET_ALL_REGISTERED_COMPANY_LIST',
      // CHANGE_REGISTERED_COMPANY_STATUS: 'ACTIONS/SUPER_ADMIN/TENANT/CHANGE_REGISTERED_COMPANY_STATUS',
      COMPANY_DETAILS: 'ACTIONS/SUPER_ADMIN/TENANT/COMPANY_DETAILS',
      GET_MODULE_PERMISSION:  'ACTIONS/SUPER_ADMIN/TENANT/GET_MODULE_PERMISSION',
    },
    COMMON: {
      SITE_INFO: 'ACTIONS/SUPER_ADMIN/COMMON/SITE_INFO'
    },
  },
  TENANT: {
    USER: {
      COMPANY_PROFILE: 'ACTIONS/TENANT/USER/COMPANY_PROFILE',
      RESET_COMPANY_PROFILE: 'ACTIONS/TENANT/USER/RESET_COMPANY_PROFILE',
    },
    ROLE: {
      ROLE_LISTING: 'ACTIONS/TENANT/ROLE/ROLE_LISTING',
      ABOUT_ME: 'ACTIONS/TENANT/ROLE/ABOUT_ME',
      GET_ALL_PERMISSION: 'ACTIONS/TENANT/ROLE/GET_ALL_PERMISSION',
      GET_ROLE_PERMISSION_DETAILS: 'ACTIONS/TENANT/ROLE/GET_ROLE_PERMISSION_DETAILS'
    },
    CLIENT: {
      CLIENT_SETUP_STEP: 'ACTIONS/TENANT/CLIENT/CLIENT_SETUP_STEP',
      CLIENT_SETUP_DETAILS_CARRY_FORWARD_TO_NEXT_STEP: 'ACTIONS/TENANT/CLIENT/CLIENT_SETUP_DETAILS_CARRY_FORWARD_TO_NEXT_STEP',
      TEMPORARY_CLIENT_NAME_LIST: 'ACTIONS/TENANT/CLIENT/TEMPORARY_CLIENT_NAME_LIST',
      EMPTY_TEMPORARY_CLIENT_NAME_LIST: 'ACTIONS/TENANT/CLIENT/EMPTY_TEMPORARY_CLIENT_NAME_LIST',
      DELETE_DATA_FROM_TEMPORARY_CLIENT_NAME_LIST: 'ACTIONS/TENANT/CLIENT/DELETE_DATA_FROM_TEMPORARY_CLIENT_NAME_LIST',
      UPDATE_DATA_FROM_TEMPORARY_CLIENT_NAME_LIST: 'ACTIONS/TENANT/CLIENT/UPDATE_DATA_FROM_TEMPORARY_CLIENT_NAME_LIST',
      CLIENT_DATA_UPDATE_INTO_TEMPORARY_CLIENT_NAME_LIST: 'ACTIONS/TENANT/CLIENT/CLIENT_DATA_UPDATE_INTO_TEMPORARY_CLIENT_NAME_LIST',
      GET_CONTACT_TYPE: 'ACTIONS/TENANT/CLIENT/GET_CONTACT_TYPE',
      GET_PARTICULAR_CLIENT_WITH_USERS: 'ACTIONS/TENANT/CLIENT/GET_PARTICULAR_CLIENT_WITH_USERS',
      GET_CLIENT_LISTS: 'ACTIONS/TENANT/CLIENT/GET_CLIENT_LISTS',
      GET_DRAFT_CLIENT_LISTS: 'ACTIONS/TENANT/GET_DRAFT_CLIENT_LISTS',
      // TEAM_MEMBER_ROLE_ASSIGN_TRACK:'ACTIONS/TENANT/CLIENT/TEAM_MEMBER_ROLE_ASSIGN_TRACK',
      GET_CLIENT_DETAILS: 'ACTIONS/TENANT/CLIENT/GET_CLIENT_DETAILS',
      GET_PROJECTS_TIMELINE: 'ACTIONS/TENANT/CLIENT/GET_PROJECTS_TIMELINE'
    },
    CH_MANGER: {
      USER_LISTING: 'ACTIONS/TENANT/CH_MANGER/USER_LISTING',
      DELETE_USER: 'ACTIONS/TENANT/CH_MANGER/DELETE_USER'
    },
    PROJECT: {
      ADD_PROJECT: 'ACTIONS/TENANT/PROJECT/ADD_PROJECT',
      PROJECT_LIST: 'ACTIONS/TENANT/PROJECT/PROJECT_LIST',
      SETUP_PROJECT_ID: 'ACTIONS/TENANT/PROJECT/SETUP_PROJECT_ID',
      CHANGE_SETUP_STEP: 'ACTIONS/TENANT/PROJECT/PROJECT_STEP',
      DRAFT_LIST: 'ACTIONS/TENANT/PROJECT/DRAFT_LIST',
      DELETE_DRAFT_PROJECT: 'ACTIONS/TENANT/PROJECT/DELETE_DRAFT_PROJECT',
      GET_STAKEHOLDERS: 'ACTIONS/TENANT/PROJECT/GET_STAKEHOLDERS',
      GET_PROJECTUSERS: 'ACTIONS/TENANT/PROJECT/GET_PROJECTUSERS',
      CHANGE_DEFINE_CHANGE_SETUP_STEP: 'ACTIONS/TENANT/PROJECT/CHANGE_DEFINE_CHANGE_SETUP_STEP',
      PROJECT_CHANGE_QUESTION_MASTER: 'ACTIONS/TENANT/PROJECT/PROJECT_CHANGE_QUESTION_MASTER',
      GET_PROJECT_METHODOLOGY_MASTER: 'ACTIONS/TENANT/PROJECT/GET_PROJECT_METHODOLOGY_MASTER',
      GET_PARTICULAR_PROJECT_DETAILS: 'ACTIONS/TENANT/PROJECT/GET_PARTICULAR_PROJECT_DETAILS',
      INPUTS: {
        IS_INPUT_SHOW_WITH_DETAILS: 'ACTIONS/TENANT/PROJECT/INPUTS/IS_INPUT_SHOW_WITH_DETAILS',
      },
      ORGANOGRAM: {
        GET_ACTIVE_TAB: 'ACTIONS/TENANT/PROJECT/ORGANOGRAM/GET_ACTIVE_TAB',
        GET_CHART_DATA: 'ACTIONS/TENANT/PROJECT/ORGANOGRAM/GET_CHART_DATA',
        GET_ADD_NODE_ID: 'ACTIONS/TENANT/PROJECT/ORGANOGRAM/GET_ADD_NODE_ID',
        GET_ORGANOGRAM_DETAILS: 'ACTIONS/TENANT/PROJECT/ORGANOGRAM/GET_ORGANOGRAM_DETAILS',
        GET_ALL_ORGANOGRAMS: 'ACTIONS/TENANT/PROJECT/ORGANOGRAM/GET_ALL_ORGANOGRAMS',
        GET_DEPARTMENT_AND_DIVISION: 'ACTIONS/TENANT/PROJECT/ORGANOGRAM/GET_DEPARTMENT_AND_DIVISION',
        GET_ORGANOGRAM_STAKEHOLDERS: 'ACTIONS/TENANT/PROJECT/ORGANOGRAM/GET_ORGANOGRAM_STAKEHOLDERS',
        GET_INFLUANCE_MAP_DETAILS: 'ACTIONS/TENANT/PROJECT/ORGANOGRAM/GET_INFLUANCE_MAP_DETAILS',
        GET_STAKEHOLDER_MATRIX_DETAILS: 'ACTIONS/TENANT/PROJECT/ORGANOGRAM/GET_STAKEHOLDER_MATRIX_DETAILS',
      },
      ENGAGEMENT: {
        // GET_ENGAGEMENT_LIST: 'ACTIONS/TENANT/PROJECT/ENGAGEMENT/GET_ENGAGEMENT_LIST',
        GET_ENGAGEMENT_DETAILS: 'ACTIONS/TENANT/PROJECT/ENGAGEMENT/GET_ENGAGEMENT_DETAILS'
      },
      CHANGE_PLAN: {
        GET_SHOW_MODAL: 'ACTIONS/TENANT/PROJECT/CHANGE_PLAN/GET_SHOW_MODAL',
        GET_MODAL_DATA: 'ACTIONS/TENANT/PROJECT/CHANGE_PLAN/GET_MODAL_DATA',
        GET_PAGE_TYPE: 'ACTIONS/TENANT/PROJECT/CHANGE_PLAN/GET_PAGE_TYPE',
        GET_CHANGE_PLAN_DETAILS: 'ACTIONS/TENANT/PROJECT/CHANGE_PLAN/GET_CHANGE_PLAN_DETAILS',
        GET_ALL_CHANGE_PLAN: 'ACTIONS/TENANT/PROJECT/CHANGE_PLAN/GET_ALL_CHANGE_PLAN',
        GET_DEPARTMENT_AND_DIVISION: 'ACTIONS/TENANT/PROJECT/CHANGE_PLAN/GET_DEPARTMENT_AND_DIVISION',
        GET_CHANGE_PLAN_TIMELINE: 'ACTIONS/TENANT/PROJECT/CHANGE_PLAN/GET_CHANGE_PLAN_TIMELINE',
        GET_TODAY_TIMELINE: 'ACTIONS/TENANT/PROJECT/CHANGE_PLAN/GET_TODAY_TIMELINE',
      },
      IMPACT: {
        GET_ALL_IMPACT: 'ACTIONS/TENANT/PROJECT/IMPACT/GET_ALL_IMPACT',
        GET_IMPACT_DETAILS: 'ACTIONS/TENANT/PROJECT/IMPACT/GET_IMPACT_DETAILS',
      },
      SURVEY: {
        GET_ALL_SURVEY: 'ACTIONS/TENANT/PROJECT/SURVEY/GET_ALL_SURVEY',
        GET_SURVEY_DETAILS: 'ACTIONS/TENANT/PROJECT/SURVEY/GET_SURVEY_DETAILS',
        GET_SURVEY_POLL: 'ACTIONS/TENANT/PROJECT/SURVEY/GET_SURVEY_POLL',
        GET_SAVE_POLL: 'ACTIONS/TENANT/PROJECT/SURVEY/GET_SAVE_POLL',
        SHARE_POLL: 'ACTIONS/TENANT/PROJECT/SURVEY/SHARE_POLL',
      },
      REPORTS: {
        ADD_REPORT: 'ACTIONS/TENANT/PROJECT/REPORTS/ADD_REPORT',
        GET_ALL_REPORTS: 'ACTIONS/TENANT/PROJECT/REPORTS/GET_ALL_REPORTS',
        DELETE_REPORT: 'ACTIONS/TENANT/PROJECT/REPORTS/DELETE_REPORT',
        GET_REPORT: 'ACTIONS/TENANT/PROJECT/REPORTS/GET_REPORT',

        ADD_STATUS_REPORT: 'ACTIONS/TENANT/PROJECT/REPORTS/ADD_STATUS_REPORT',
        GET_ALL_STATUS_REPORTS: 'ACTIONS/TENANT/PROJECT/REPORTS/GET_ALL_STATUS_REPORTS',
        GET_STATUS_REPORT: 'ACTIONS/TENANT/PROJECT/REPORTS/GET_STATUS_REPORT',
        DELETE_STATUS_REPORT: 'ACTIONS/TENANT/PROJECT/REPORTS/DELETE_STATUS_REPORT',
        GET_CHANGE_LOAD_REPORT: 'ACTIONS/TENANT/PROJECT/REPORTS/GET_CHANGE_LOAD_REPORT',
      },
      ASSESSMENT: {
        GET_ALL_PREDEFINE_ASSESSMENT_LIST: 'ACTIONS/TENANT/PROJECT/ASSESSMENT/GET_ALL_PREDEFINE_ASSESSMENT_LIST',
        GET_ALL_ASSESSMENT: 'ACTIONS/TENANT/PROJECT/ASSESSMENT/GET_ALL_ASSESSMENT',
        GET_PREDEFINED_CATEGORYWISE_QUESTION: 'ACTIONS/TENANT/PROJECT/ASSESSMENT/GET_PREDEFINED_CATEGORYWISE_QUESTION',
      },
      COMMS: {
        GET_ALL_COMMS: 'ACTIONS/TENANT/PROJECT/COMMS/GET_ALL_COMMS',
        GET_SINGLE_COMMS_DETAILS: 'ACTIONS/TENANT/PROJECT/COMMS/GET_SINGLE_COMMS_DETAILS'
      },
      TRAINING: {
        GET_ALL_TRAINING: 'ACTIONS/TENANT/PROJECT/TRAINING/GET_ALL_TRAINING',
        GET_SINGLE_TRAINING_DETAILS: 'ACTIONS/TENANT/PROJECT/TRAINING/GET_SINGLE_TRAINING_DETAILS'
      }


    },
    COMMON: {
      COUNTRY_LIST: 'ACTIONS/TENANT/COMMON/COUNTRY_LIST',
      ABOUT_ME_LIST: 'ACTIONS/TENANT/COMMON/ABOUT_ME_LIST',
      STATE_LIST: 'ACTIONS/TENANT/COMMON/STATE_LIST',
      CITY_LIST: 'ACTIONS/TENANT/COMMON/CITY_LIST',
      RESET_COUNTRY_LIST: 'ACTIONS/TENANT/COMMON/RESET_COUNTRY_LIST',
      RESET_ABOUT_ME_LIST: 'ACTIONS/TENANT/COMMON/RESET_ABOUT_ME_LIST',
      RESET_STATE_LIST: 'ACTIONS/TENANT/COMMON/RESET_STATE_LIST',
      RESET_CITY_LIST: 'ACTIONS/TENANT/COMMON/RESET_CITY_LIST',
      SEARCH_DIALCODE: 'ACTIONS/TENANT/COMMON/SEARCH_DIALCODE',
    },
  },

}

export const SAGA_ACTIONS = {
  SUPER_ADMIN: {
    USER: {
      LOGIN: 'SAGA_ACTIONS/SUPER_ADMIN/USER/LOGIN',
      LOGOUT: 'SAGA_ACTIONS/SUPER_ADMIN/USER/LOGOUT',
      DETAILS: 'SAGA_ACTIONS/SUPER_ADMIN/USER/DETAILS',
      REGISTRATION: 'SAGA_ACTIONS/SUPER_ADMIN/USER/REGISTRATION',
      VERIFY_EMAIL: 'SAGA_ACTIONS/SUPER_ADMIN/USER/VERIFY_EMAIL',
      CHECK_LOGIN: 'SAGA_ACTIONS/SUPER_ADMIN/USER/CHECK_LOGIN',
      CHANGE_PASSWORD: 'SAGA_ACTIONS/SUPER_ADMIN/USER/CHANGE_PASSWORD',
      FORGOT_PASSWORD: 'SAGA_ACTIONS/SUPER_ADMIN/USER/FORGOT_PASSWORD',
      RESET_PASSWORD: 'SAGA_ACTIONS/SUPER_ADMIN/USER/RESET_PASSWORD',
      UPDATE_PROFILE: 'SAGA_ACTIONS/SUPER_ADMIN/USER/UPDATE_PROFILE',
      UPLOAD_PROFILE_PICTURE: 'SAGA_ACTIONS/SUPER_ADMIN/USER/UPLOAD_PROFILE_PICTURE',
      DELETE_PROFILE_PICTURE: 'SAGA_ACTIONS/SUPER_ADMIN/USER/DELETE_PROFILE_PICTURE',
      GET_RESOURCES: 'SAGA_ACTIONS/SUPER_ADMIN/USER/GET_RESOURCES',
      GET_MODULE_PERMISSION: 'SAGA_ACTIONS/SUPER_ADMIN/USER/GET_MODULE_PERMISSION',
      UPDATE_TENANT_MODULE_PERMISSION: 'SAGA_ACTIONS/SUPER_ADMIN/USER/UPDATE_TENANT_MODULE_PERMISSION',
      RESEND_VERIFICATION_EMAIL: 'SAGA_ACTIONS/SUPER_ADMIN/USER/RESEND_VERIFICATION_EMAIL',
    },
    TENANT: {
      GET_ALL_REGISTERED_COMPANY_LIST: 'SAGA_ACTIONS/SUPER_ADMIN/TENANT/GET_ALL_REGISTERED_COMPANY_LIST',
      CHANGE_REGISTERED_COMPANY_STATUS: 'SAGA_ACTIONS/SUPER_ADMIN/TENANT/CHANGE_REGISTERED_COMPANY_STATUS',
    },
    COMMON: {
      CHECK_SUBDOMAIN_AVAILABILITY: 'SAGA_ACTIONS/SUPER_ADMIN/COMMON/CHECK_SUBDOMAIN_AVAILABILITY',
      UPLOAD_FILES: 'SAGA_ACTIONS/SUPER_ADMIN/COMMON/COMMON/UPLOAD_FILES',
      DELETE_FILES: 'SAGA_ACTIONS/SUPER_ADMIN/COMMON/COMMON/DELETE_FILES',
      GET_FILES: 'SAGA_ACTIONS/SUPER_ADMIN/COMMON/COMMON/GET_FILES',
      SITE_INFO: 'SAGA_ACTIONS/SUPER_ADMIN/COMMON/SITE_INFO',
      SEARCH_ABOUT_ME: 'SAGA_ACTIONS/SUPER_ADMIN/COMMON/SEARCH_ABOUT_ME',
    },
  },
  TENANT: {
    USER: {
      LOGIN: 'SAGA_ACTIONS/TENANT/USER/LOGIN',
      LOGOUT: 'SAGA_ACTIONS/TENANT/USER/LOGOUT',
      ME: 'SAGA_ACTIONS/TENANT/USER/ME',
      FORGOT_PASSWORD: 'SAGA_ACTIONS/TENANT/USER/FORGOT_PASSWORD',
      RESET_PASSWORD: 'SAGA_ACTIONS/TENANT/USER/RESET_PASSWORD',
      VERIFY_EMAIL: 'SAGA_ACTIONS/TENANT/USER/VERIFY_EMAIL',
      FETCH_TENANT_DETAILS: 'SAGA_ACTIONS/TENANT/USER/FETCH_TENANT_DETAILS',
      UPDATE_TENANT_DETAILS: 'SAGA_ACTIONS/TENANT/USER/UPDATE_TENANT_DETAILS',
      CHANGE_PASSWORD: 'SAGA_ACTIONS/TENANT/USER/CHANGE_PASSWORD',
      UPDATE_PROFILE: 'SAGA_ACTIONS/TENANT/USER/UPDATE_PROFILE',
      UPLOAD_PROFILE_PICTURE: 'SAGA_ACTIONS/TENANT/USER/UPLOAD_PROFILE_PICTURE',
      DELETE_PROFILE_PICTURE: 'SAGA_ACTIONS/TENANT/USER/DELETE_PROFILE_PICTURE',
      FETCH_COMPANY_DETAILS: 'SAGA_ACTIONS/TENANT/USER/FETCH_COMPANY_DETAILS',
      UPDATE_COMPANY_DETAILS: 'SAGA_ACTIONS/TENANT/USER/UPDATE_COMPANY_DETAILS',
      RESORCES_COUNT: 'SAGA_ACTIONS/TENANT/USER/RESORCES_COUNT',
    },
    ROLE: {
      ADD_ROLE: 'SAGA_ACTIONS/TENANT/ROLE/ADD_ROLE',
      DELETE_ROLE: 'SAGA_ACTIONS/TENANT/ROLE/DELETE_ROLE',
      ROLE_LISTING: 'SAGA_ACTIONS/TENANT/ROLE/ROLE_LISTING',
      ASSIGN_ROLE_PERMISSION: 'SAGA_ACTIONS/TENANT/ROLE/ADD_PERMISSION',
      GET_ALL_PERMISSION: 'SAGA_ACTIONS/TENANT/ROLE/GET_ALL_PERMISSION',
      GET_ROLE_PERMISSION_DETAILS: 'SAGA_ACTIONS/TENANT/ROLE/GET_ROLE_PERMISSION_DETAILS',
    },
    CLIENT: {
      GET_CONTACT_TYPE: 'SAGA_ACTIONS/TENANT/CLIENT/GET_CONTACT_TYPE',
      SEARCH_USER_BY_EMAIL: 'SAGA_ACTIONS/TENANT/CLIENT/SEARCH_USER_BY_EMAIL',
      ADD_CLIENT_WITH_USER: 'SAGA_ACTIONS/TENANT/CLIENT/ADD_CLIENT_WITH_USER',
      ADD_CLIENT_OBJECTIVE: 'SAGA_ACTIONS/TENANT/CLIENT/ADD_CLIENT_OBJECTIVE',
      GET_PARTICULAR_CLIENT_WITH_USERS: 'SAGA_ACTIONS/TENANT/CLIENT/GET_PARTICULAR_CLIENT_WITH_USERS',
      SET_CLIENT_USERS_TO_TEAM_MEMBERS: 'SAGA_ACTIONS/TENANT/CLIENT/SET_CLIENT_USERS_TO_TEAM_MEMBERS',
      ASSIGN_ROLE_TO_USERS: 'SAGA_ACTIONS/TENANT/CLIENT/ASSIGN_ROLE_TO_USERS',
      GET_CLIENT_LISTS: 'SAGA_ACTIONS/TENANT/CLIENT/GET_CLIENT_LISTS',
      GET_DRAFT_CLIENT_LISTS: 'SAGA_ACTIONS/TENANT/GET_DRAFT_CLIENT_LISTS',
      GET_CLIENT_DETAILS: 'SAGA_ACTIONS/TENANT/CLIENT/GET_CLIENT_DETAILS',
      SETUP_CLIENT_COMPLETE: 'SAGA_ACTIONS/TENANT/CLIENT/SETUP_CLIENT_COMPLETE',
      GET_PROJECTS_TIMELINE: 'SAGA_ACTIONS/TENANT/CLIENT/GET_PROJECTS_TIMELINE',
    },
    CH_MANGER: {
      ADD_NEW_USER: 'SAGA_ACTIONS/TENANT/CH_MANGER/ADD_NEW_USER',
      GET_USER_LIST: 'SAGA_ACTIONS/TENANT/CH_MANGER/GET_USER_LIST',
      DELETE_USER: 'SAGA_ACTIONS/TENANT/CH_MANGER/DELETE_USER'
    },
    PROJECT: {
      ADD_PROJECT: 'SAGA_ACTIONS/TENANT/PROJECT/ADD_PROJECT',
      DELETE_DRAFTED_PROJECT: 'SAGA_ACTIONS/TENANT/PROJECT/DELETE_DRAFTED_PROJECT',
      PROJECT_LIST: 'SAGA_ACTIONS/TENANT/PROJECT/PROJECT_LIST',
      ADD_PROJECT_FILES: 'SAGA_ACTIONS/TENANT/PROJECT/ADD_PROJECT_FILES',
      REMOVE_PROJECT_FILES: 'SAGA_ACTIONS/TENANT/PROJECT/REMOVE_PROJECT_FILES',
      PROJECT_DETAIL: 'SAGA_ACTIONS/TENANT/PROJECT/PROJECT_DETAIL',
      UPLOAD_EXCEL_FILE: 'SAGA_ACTIONS/TENANT/PROJECT/UPLOAD_EXCEL_FILE',
      UPLOAD_PROJECT_EXCEL_FILE: 'SAGA_ACTIONS/TENANT/PROJECT/UPLOAD_PROJECT_EXCEL_FILE',
      ADD_EDIT_STAKEHOLDER: 'SAGA_ACTIONS/TENANT/PROJECT/ADD_EDIT_STAKEHOLDER',
      AVAIL_PROJECT_USERS: 'SAGA_ACTIONS/TENANT/PROJECT/GET_PROJECT_USERS',
      SEARCH_PROJECT_USERS: 'SAGA_ACTIONS/TENANT/PROJECT/SEARCH_PROJECT_USERS',
      ADD_USER_TO_PROJECT: 'SAGA_ACTIONS/TENANT/PROJECT/ADD_USER_TO_PROJECT',
      REMOVE_USER_FROM_PROJECT: 'SAGA_ACTIONS/TENANT/PROJECT/REMOVE_USER_FROM_PROJECT',
      ADD_NEW_PROJECT_USER: 'SAGA_ACTIONS/TENANT/PROJECT/ADD_NEW_PROJECT_USER',

      CHANGE_PROJECT_USER_ROLE: 'SAGA_ACTIONS/TENANT/PROJECT/CHANGE_PROJECT_USER_ROLE',
      GET_STAKEHOLDERS: 'SAGA_ACTIONS/TENANT/PROJECT/GET_STAKEHOLDERS',
      UPDATE_PROJECT_USER: 'SAGA_ACTIONS/TENANT/PROJECT/UPDATE_PROJECT_USER',
      SETUP_FINAL: 'SAGA_ACTIONS/TENANT/PROJECT/SETUP_FINAL',
      DRAFT_LIST: 'SAGA_ACTIONS/TENANT/PROJECT/DRAFT_LIST',
      PROJECT_CHANGE_QUESTION_MASTER: 'SAGA_ACTIONS/TENANT/PROJECT/PROJECT_CHANGE_QUESTION_MASTER',
      PROJECT_CUSTOM_QUESTION_ANSWER:'SAGA_ACTIONS/TENANT/PROJECT/PROJECT_CUSTOM_QUESTION_ANSWER',
      PROJECT_CHANGE_QUESTION_SAVE_UPDATE: 'SAGA_ACTIONS/TENANT/PROJECT/PROJECT_CHANGE_QUESTION_SAVE_UPDATE',
      GET_PROJECT_METHODOLOGY_MASTER: 'SAGA_ACTIONS/TENANT/PROJECT/GET_PROJECT_METHODOLOGY_MASTER',
      SET_PROJECT_METHODOLOGY: 'SAGA_ACTIONS/TENANT/PROJECT/SET_PROJECT_METHODOLOGY',
      SEARCH_PROJECT_METHODOLOGY_QUESTIONS: 'SAGA_ACTIONS/TENANT/PROJECT/SEARCH_PROJECT_METHODOLOGY_QUESTIONS',
      INPUTS: {
        UPLOAD_DOCUMENT: 'SAGA_ACTIONS/TENANT/PROJECT/INPUTS/UPLOAD_DOCUMENT',
        UPLOAD_VERSION_CONTROL_FILES_FROM_INPUT_SECTION: 'SAGA_ACTIONS/TENANT/PROJECT/INPUTS/UPLOAD_VERSION_CONTROL_FILES_FROM_INPUT_SECTION',
      },
      ENGAGEMENT: {
        ADD_ENGAGEMENT: 'SAGA_ACTIONS/TENANT/PROJECT/ENGAGEMENT/ADD_ENGAGEMENT',
        // GET_ENGAGEMENT_LIST: 'SAGA_ACTIONS/TENANT/PROJECT/ENGAGEMENT/GET_ENGAGEMENT_LIST',
        DELETE_ENGAGEMENT: 'SAGA_ACTIONS/TENANT/PROJECT/ENGAGEMENT/DELETE_ENGAGEMENT',
        DUPLICATE_ENGAGEMENT: 'SAGA_ACTIONS/TENANT/PROJECT/ENGAGEMENT/DUPLICATE_ENGAGEMENT',
        UPDATE_STATUS: 'SAGA_ACTIONS/TENANT/PROJECT/ENGAGEMENT/UPDATE_STATUS',
        UPDATE_ENGAGEMENT: 'SAGA_ACTIONS/TENANT/PROJECT/ENGAGEMENT/UPDATE_ENGAGEMENT',
        GET_ENGAGEMENT_DETAILS: 'SAGA_ACTIONS/TENANT/PROJECT/ENGAGEMENT/GET_ENGAGEMENT_DETAILS',

      },
      ORGANOGRAM: {
        ADD_EDIT_ORGANOGRAM: 'SAGA_ACTIONS/TENANT/ORGANOGRAM/ADD_EDIT_ORGANOGRAM',
        GET_ORGANOGRAM: 'SAGA_ACTIONS/TENANT/ORGANOGRAM/GET_ORGANOGRAM',
        GET_ALL_ORGANOGRAMS: 'SAGA_ACTIONS/TENANT/ORGANOGRAM/GET_ALL_ORGANOGRAMS',
        REMOVE_ORGANOGRAM: 'SAGA_ACTIONS/TENANT/ORGANOGRAM/REMOVE_ORGANOGRAM',
        GET_DEPARTMENT_AND_DIVISION: 'SAGA_ACTIONS/TENANT/ORGANOGRAM/GET_DEPARTMENT_AND_DIVISION',
        // STAKEHOLDER MATRIX
        ADD_EDIT_STAKEHOLDER_MATRIX: 'SAGA_ACTIONS/TENANT/ORGANOGRAM/ADD_EDIT_STAKEHOLDER_MATRIX',
        GET_STAKEHOLDER_MATRIX: 'SAGA_ACTIONS/TENANT/ORGANOGRAM/GET_STAKEHOLDER_MATRIX',
        GET_ALL_STAKEHOLDER_MATRIX: 'SAGA_ACTIONS/TENANT/ORGANOGRAM/GET_ALL_STAKEHOLDER_MATRIX',
        REMOVE_STAKEHOLDER_MATRIX: 'SAGA_ACTIONS/TENANT/ORGANOGRAM/REMOVE_STAKEHOLDER_MATRIX',
        REMOVE_STAKEHOLDER_FROM_STAKEHOLDER_MATRIX: 'SAGA_ACTIONS/TENANT/ORGANOGRAM/REMOVE_STAKEHOLDER_FROM_STAKEHOLDER_MATRIX',
        // INFLUENCE MAP
        ADD_EDIT_INFLUENCE_MAP: 'SAGA_ACTIONS/TENANT/ORGANOGRAM/ADD_EDIT_INFLUENCE_MAP',
        GET_INFLUENCE_MAP: 'SAGA_ACTIONS/TENANT/ORGANOGRAM/GET_INFLUENCE_MAP',
        GET_ALL_INFLUENCE_MAP: 'SAGA_ACTIONS/TENANT/ORGANOGRAM/GET_ALL_INFLUENCE_MAP',
        REMOVE_INFLUENCE_MAP: 'SAGA_ACTIONS/TENANT/ORGANOGRAM/REMOVE_INFLUENCE_MAP',
        REMOVE_STAKEHOLDER_FROM_INFLUENCE_MAP: 'SAGA_ACTIONS/TENANT/ORGANOGRAM/REMOVE_STAKEHOLDER_FROM_INFLUENCE_MAP',
        SHARE_ORGANOGRAM: 'SAGA_ACTIONS/TENANT/ORGANOGRAM/SHARE_ORGANOGRAM',
        COPY_ORGANOGRAM: 'SAGA_ACTIONS/TENANT/ORGANOGRAM/COPY_ORGANOGRAM',
      },
      CHANGE_PLAN: {
        ADD_EDIT_CHANGE_PLAN: 'SAGA_ACTIONS/TENANT/CHANGE_PLAN/ADD_EDIT_CHANGE_PLAN',
        ADD_TO_CHANGE_PLAN: 'SAGA_ACTIONS/TENANT/CHANGE_PLAN/ADD_TO_CHANGE_PLAN',
        GET_CHANGE_PLAN: 'SAGA_ACTIONS/TENANT/CHANGE_PLAN/GET_CHANGE_PLAN',
        GET_ALL_CHANGE_PLANS: 'SAGA_ACTIONS/TENANT/CHANGE_PLAN/GET_ALL_CHANGE_PLANS',
        REMOVE_CHANGE_PLAN: 'SAGA_ACTIONS/TENANT/CHANGE_PLAN/REMOVE_CHANGE_PLAN',
        UPDATE_CHANGE_PLAN_STATUS: 'SAGA_ACTIONS/TENANT/CHANGE_PLAN/UPDATE_CHANGE_PLAN_STATUS',
        UPDATE_CHANGE_PLAN_START_ACTIVITY_STATUS: 'SAGA_ACTIONS/TENANT/CHANGE_PLAN/UPDATE_CHANGE_PLAN_START_ACTIVITY_STATUS',
        GET_CHANGE_PLAN_TIMELINE: 'SAGA_ACTIONS/TENANT/CHANGE_PLAN/GET_CHANGE_PLAN_TIMELINE',
        GET_TODAY_TIMELINE: 'SAGA_ACTIONS/TENANT/CHANGE_PLAN/GET_TODAY_TIMELINE',
      },
      IMPACT: {
        ADD_IMPACT: 'SAGA_ACTIONS/TENANT/PROJECT/IMPACT/ADD_IMPACT',
        EDIT_IMPACT_NAME_DESCRIPTION: 'SAGA_ACTIONS/TENANT/PROJECT/IMPACT/EDIT_IMPACT_NAME_DESCRIPTION',
        GET_ALL_IMPACT: 'SAGA_ACTIONS/TENANT/PROJECT/IMPACT/GET_ALL_IMPACT',
        GET_IMPACT_DETAILS: 'SAGA_ACTIONS/TENANT/PROJECT/IMPACT/GET_IMPACT_DETAILS',
        DELETE_IMPACT: 'SAGA_ACTIONS/TENANT/PROJECT/IMPACT/DELETE_IMPACT',
        DOWNLOAD_IMPACT_EXCEL: 'SAGA_ACTIONS/TENANT/PROJECT/IMPACT/DOWNLOAD_IMPACT_EXCEL',
      },
      SURVEY: {
        ADD_SURVEY: 'SAGA_ACTIONS/TENANT/PROJECT/SURVEY/ADD_SURVEY',
        GET_ALL_SURVEY: 'SAGA_ACTIONS/TENANT/PROJECT/SURVEY/GET_ALL_SURVEY',
        DELETE_SURVEY: 'SAGA_ACTIONS/TENANT/PROJECT/SURVEY/DELETE_SURVEY',
        GET_SURVEY_DETAILS: 'SAGA_ACTIONS/TENANT/PROJECT/SURVEY/GET_SURVEY_DETAILS',
        GET_SURVEY_POLL: 'SAGA_ACTIONS/TENANT/PROJECT/SURVEY/GET_SURVEY_POLL',
        GET_SAVE_POLL: 'SAGA_ACTIONS/TENANT/PROJECT/SURVEY/GET_SAVE_POLL',
        SHARE_POLL: 'SAGA_ACTIONS/TENANT/PROJECT/SURVEY/SHARE_POLL',
      },
      REPORTS: {
        ADD_REPORT: 'SAGA_ACTIONS/TENANT/PROJECT/REPORTS/ADD_REPORT',
        GET_ALL_REPORTS: 'SAGA_ACTIONS/TENANT/PROJECT/REPORTS/GET_ALL_REPORTS',
        DELETE_REPORT: 'SAGA_ACTIONS/TENANT/PROJECT/REPORTS/DELETE_REPORT',
        GET_REPORT: 'SAGA_ACTIONS/TENANT/PROJECT/REPORTS/GET_REPORT',
        GET_PUBLIC_REPORT_CHANGE_STORY: 'SAGA_ACTIONS/TENANT/PROJECT/REPORTS/GET_PUBLIC_REPORT_CHANGE_STORY',

        ADD_STATUS_REPORT: 'SAGA_ACTIONS/TENANT/PROJECT/REPORTS/ADD_STATUS_REPORT',
        GET_ALL_STATUS_REPORTS: 'SAGA_ACTIONS/TENANT/PROJECT/REPORTS/GET_ALL_STATUS_REPORTS',
        GET_STATUS_REPORT: 'SAGA_ACTIONS/TENANT/PROJECT/REPORTS/GET_STATUS_REPORT',
        DELETE_STATUS_REPORT: 'SAGA_ACTIONS/TENANT/PROJECT/REPORTS/DELETE_STATUS_REPORT',
        DOWNLOAD_REPORT: 'SAGA_ACTIONS/TENANT/PROJECT/REPORTS/DOWNLOAD_REPORT',
        GET_CHANGE_LOAD_REPORT: 'SAGA_ACTIONS/TENANT/PROJECT/REPORTS/GET_CHANGE_LOAD_REPORT',


        ADD_CHANGE_REPORT: 'SAGA_ACTIONS/TENANT/PROJECT/REPORTS/ADD_CHANGE_REPORT',
        GET_ALL_CHANGE_REPORT: 'SAGA_ACTIONS/TENANT/PROJECT/REPORTS/GET_ALL_CHANGE_REPORT',
        GET_SINGLE_CHANGE_REPORT: 'SAGA_ACTIONS/TENANT/PROJECT/REPORTS/GET_SINGLE_CHANGE_REPORT',
        DELETE_CHANGE_REPORT: 'SAGA_ACTIONS/TENANT/PROJECT/REPORTS/DELETE_CHANGE_REPORT',

      },
      ASSESSMENT: {
        GET_ALL_PREDEFINE_ASSESSMENT_LIST: 'SAGA_ACTIONS/TENANT/PROJECT/ASSESSMENT/GET_ALL_PREDEFINE_ASSESSMENT_LIST',
        ADD_ASSESSMENT: 'SAGA_ACTIONS/TENANT/PROJECT/ASSESSMENT/ADD_ASSESSMENT',
        GET_ALL_ASSESSMENT: 'SAGA_ACTIONS/TENANT/PROJECT/ASSESSMENT/GET_ALL_ASSESSMENT',
        GET_PREDEFINED_CATEGORYWISE_QUESTION: 'SAGA_ACTIONS/TENANT/PROJECT/ASSESSMENT/GET_PREDEFINED_CATEGORYWISE_QUESTION',
        UPDATE_CATEGORYWISE_QUESTION_ANSWER: 'SAGA_ACTIONS/TENANT/PROJECT/ASSESSMENT/UPDATE_CATEGORYWISE_QUESTION_ANSWER',
        DELETE_ASSESSMENT: 'SAGA_ACTIONS/TENANT/PROJECT/ASSESSMENT/DELETE_ASSESSMENT',
        PUBLIC_GET_ASSESSMENT_DETAILS: 'SAGA_ACTIONS/TENANT/PROJECT/ASSESSMENT/PUBLIC_GET_ASSESSMENT_DETAILS',
        PUBLIC_SAVE_ASSESSMENT_RESPONSE: 'SAGA_ACTIONS/TENANT/PROJECT/ASSESSMENT/PUBLIC_SAVE_ASSESSMENT_RESPONSE',
        SAVE_CHANGE_ASSESSMENT_QUESTIONS: 'SAGA_ACTIONS/TENANT/PROJECT/ASSESSMENT/SAVE_CHANGE_ASSESSMENT_QUESTIONS',
        RENAME_ASSESSMENT_SECTION: 'SAGA_ACTIONS/TENANT/PROJECT/ASSESSMENT/RENAME_ASSESSMENT_SECTION',
        DELETE_ASSESSMENT_SECTION: 'SAGA_ACTIONS/TENANT/PROJECT/ASSESSMENT/DELETE_ASSESSMENT_SECTION',
        DELETE_ASSESSMENT_QUESTION: 'SAGA_ACTIONS/TENANT/PROJECT/ASSESSMENT/DELETE_ASSESSMENT_QUESTION',
        RENAME_ASSESSMENT_QUESTION: 'SAGA_ACTIONS/TENANT/PROJECT/ASSESSMENT/RENAME_ASSESSMENT_QUESTION',
      },
      COMMS: {
        ADD_COMMS: 'SAGA_ACTIONS/TENANT/PROJECT/COMMS/ADD_COMMS',
        GET_SINGLE_COMMS_DETAILS: 'SAGA_ACTIONS/TENANT/PROJECT/COMMS/GET_SINGLE_COMMS_DETAILS',
        GET_ALL_COMMS: 'SAGA_ACTIONS/TENANT/PROJECT/COMMS/GET_ALL_COMMS',
        DELETE_COMMS: 'SAGA_ACTIONS/TENANT/PROJECT/COMMS/DELETE_COMMS',
        SEND_FOR_APPROVAL: 'SAGA_ACTIONS/TENANT/PROJECT/COMMS/SEND_FOR_APPROVAL',
        GET_APPROVAL_DETAILS: 'SAGA_ACTIONS/TENANT/PROJECT/COMMS/GET_APPROVAL_DETAILS',
        SAVE_APPROVAL_RESPONSE: 'SAGA_ACTIONS/TENANT/PROJECT/COMMS/SAVE_APPROVAL_RESPONSE'
      },
      TRAINING: {
        ADD_TRAINING: 'SAGA_ACTIONS/TENANT/PROJECT/TRAINING/ADD_TRAINING',
        GET_SINGLE_TRAINING_DETAILS: 'SAGA_ACTIONS/TENANT/PROJECT/TRAINING/GET_SINGLE_TRAINING_DETAILS',
        GET_ALL_TRAINING: 'SAGA_ACTIONS/TENANT/PROJECT/TRAINING/GET_ALL_TRAINING',
        DELETE_TRAINING: 'SAGA_ACTIONS/TENANT/PROJECT/TRAINING/DELETE_TRAINING',
        DOWNLOAD_TRAINING_EXCEL: 'SAGA_ACTIONS/TENANT/PROJECT/TRAINING/DOWNLOAD_TRAINING_EXCEL',
        SHARE_TRAINING_DATA: 'SAGA_ACTIONS/TENANT/PROJECT/TRAINING/SHARE_TRAINING_DATA',
        GET_TRAINING_SHARE_DETAILS: 'SAGA_ACTIONS/TENANT/PROJECT/TRAINING/GET_TRAINING_SHARE_DETAILS',
        // SEND_FOR_APPROVAL: 'SAGA_ACTIONS/TENANT/PROJECT/TRAINING/SEND_FOR_APPROVAL',
        // GET_APPROVAL_DETAILS: 'SAGA_ACTIONS/TENANT/PROJECT/TRAINING/GET_APPROVAL_DETAILS',
        // SAVE_APPROVAL_RESPONSE: 'SAGA_ACTIONS/TENANT/PROJECT/TRAINING/SAVE_APPROVAL_RESPONSE'
      }
    },
    COMMON: {
      IMAGE_UPLOAD: 'SAGA_ACTIONS/TENANT/COMMON/IMAGE_UPLOAD',
      DELETE_FILES: 'SAGA_ACTIONS/TENANT/COMMON/DELETE_FILES',
      ABOUT_ME_LISTING: 'SAGA_ACTIONS/TENANT/COMMON/ABOUT_ME_LISTING',
      GET_FILES: 'SAGA_ACTIONS/TENANT/COMMON/GET_FILES',
      COUNTRY_LIST: 'SAGA_ACTIONS/TENANT/COMMON/COUNTRY_LIST',
      STATE_LIST: 'SAGA_ACTIONS/TENANT/COMMON/STATE_LIST',
      CITY_LIST: 'SAGA_ACTIONS/TENANT/COMMON/CITY_LIST',
      SHARE_LINK: 'SAGA_ACTIONS/TENANT/COMMON/SHARE_LINK',
      GET_PUBLIC_MODULE_DETAILS: 'SAGA_ACTIONS/TENANT/COMMON/GET_PUBLIC_MODULE_DETAILS',
      GET_PUBLIC_ORGANOGRAM_DETAILS: 'SAGA_ACTIONS/TENANT/COMMON/GET_PUBLIC_ORGANOGRAM_DETAILS',
      GET_TENANT_MODULE_PERMISSION: 'SAGA_ACTIONS/TENANT/COMMON/GET_TENANT_MODULE_PERMISSION',
      SEARCH_DIALCODE: 'SAGA_ACTIONS/TENANT/COMMON/SEARCH_DIALCODE',
    },
  },
}

export const MODULES = {
  USER: 'change-manager',
  PROJECT: 'project',
  PERMISSION: 'permission',
  ROLE: 'role',
  CLIENT: 'client',
  CONTACT_TYPE: 'contact-type',
  USER_TYPE: 'uesr-type',
  ABOUT_ME: 'about-me',
  COMPANY_PROFILE: 'company-profile',
  INPUTS: 'project-inputs'
}

export const OPERATIONS = {
  ADD: 'add',
  EDIT: 'edit',
  DELETE: 'delete',
  LIST: 'list',
  ASSIGN: 'assign'
}

export * from './site_statics'
export * from './site_urls'
export * from './functions'
export * from './canvasUtils'
export * from './card_utils'