import { ActionExtended } from "../../../../../_common/interfaces/ActionExtended";
import { ACTIONS } from "../../../../../_config";
import { TrainingList, TrainingDetails } from '../../../../../_common/interfaces/models/training';


export interface TrainingReducer {
   trainingList: TrainingList | null;
   trainingDetails: TrainingDetails | null;
}
const initialState: TrainingReducer = {
   trainingList: null,
   trainingDetails: null,

};
const trainingReducer = (state = initialState, action: ActionExtended) => {
   switch (action.type) {
      case ACTIONS.TENANT.PROJECT.TRAINING.GET_ALL_TRAINING:
         return {
            ...state,
            trainingList: action.payload
         }
      case ACTIONS.TENANT.PROJECT.TRAINING.GET_SINGLE_TRAINING_DETAILS:
         return {
            ...state,
            trainingDetails: action.payload
         }

      default:
         return state
   }
}

export default trainingReducer