import { call, put } from 'redux-saga/effects';
import { ACTIONS, API_URL, SAGA_ACTIONS, STORAGE } from '../../_config';
import { CallApi } from '../api/callApi';

export function* tenantAddUser(action: any) {
   try {
     const data = action.payload;
     const resp = yield call(CallApi.POST, API_URL.TENANT.CH_MANGER.ADD_NEW_USER, data, true);
     if (resp.status >= 200 && resp.status < 300 && resp.data) {
       action && action.callbackSuccess && action.callbackSuccess(resp.data);
     } else {
       action && action.callbackError && action.callbackError(resp.data);
     }
   } catch (e) {
     action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
   }
 }

 export function* tenantUserListing(action: any) {
   try {
     const data = action.payload;
     const resp = yield call(CallApi.GET, API_URL.TENANT.CH_MANGER.GET_USER_LIST, data, true);
     if (resp.status >= 200 && resp.status < 300 && resp.data) {
      yield put({
         type: ACTIONS.TENANT.CH_MANGER.USER_LISTING,
         payload: resp.data.data
       })
       action && action.callbackSuccess && action.callbackSuccess(resp.data);
     } else {
       action && action.callbackError && action.callbackError(resp.data);
     }
   } catch (e) {
     action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
   }
 }

 export function* deleteUser(action: any) {
   try {
     const data = action.payload;
     const resp = yield call(CallApi.POST, API_URL.TENANT.CH_MANGER.DELETE_USER, data, true);
     if (resp.status >= 200 && resp.status < 300 && resp.data) {
      yield put({
         type: ACTIONS.TENANT.CH_MANGER.DELETE_USER,
         payload: action.payload
       })
       action && action.callbackSuccess && action.callbackSuccess(resp.data);
     } else {
       action && action.callbackError && action.callbackError(resp.data);
     }
   } catch (e) {
     action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
   }
 }