import React from 'react';

const LazyTraining = React.lazy(() =>
  import(/* webpackChunkName: "Home" */ './training')
);

const TrainingPage = (props: Record<string, any>) => (
  <React.Suspense fallback={<h1>Loading...</h1>}>
    <LazyTraining {...props} />
  </React.Suspense>
);

export default TrainingPage