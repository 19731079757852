import { ActionExtended } from "../../../../../_common/interfaces/ActionExtended";
import { ACTIONS } from "../../../../../_config";


export interface ChangePlanReducer {
   showModal: boolean,
   pageType: 'organogram' | 'engagement' | 'input',
   modalData: any,
   changePlanList: any[],
   changePlanDetails: any,
   changePlanTimeline: any,
   todayTimeline: any,
   departmentDivisionList: any[],
}

const initialState: ChangePlanReducer = {
    showModal: false,
    pageType: 'organogram',
    modalData: null,
    changePlanList: [],
    changePlanDetails: null,
    changePlanTimeline: null,
    todayTimeline: null,
    departmentDivisionList: [],
};

const changePlanReducer = (state = initialState, action: ActionExtended) => {
   switch (action.type) {
      case ACTIONS.TENANT.PROJECT.CHANGE_PLAN.GET_SHOW_MODAL:
         return {
            ...state,
            showModal: action.payload
         }

      case ACTIONS.TENANT.PROJECT.CHANGE_PLAN.GET_MODAL_DATA:
         return {
            ...state,
            modalData: action.payload
         }

      case ACTIONS.TENANT.PROJECT.CHANGE_PLAN.GET_PAGE_TYPE:
         return {
            ...state,
            pageType: action.payload
         }
      case ACTIONS.TENANT.PROJECT.CHANGE_PLAN.GET_CHANGE_PLAN_DETAILS:
         return {
            ...state,
            changePlanDetails: action.payload
         }
      case ACTIONS.TENANT.PROJECT.CHANGE_PLAN.GET_ALL_CHANGE_PLAN:
         return {
            ...state,
            changePlanList: action.payload
         }       
      case ACTIONS.TENANT.PROJECT.CHANGE_PLAN.GET_DEPARTMENT_AND_DIVISION:
         return {
            ...state,
            departmentDivisionList: action.payload
         } 
      case ACTIONS.TENANT.PROJECT.CHANGE_PLAN.GET_CHANGE_PLAN_TIMELINE:
         return {
            ...state,
            changePlanTimeline: action.payload
         } 
      case ACTIONS.TENANT.PROJECT.CHANGE_PLAN.GET_TODAY_TIMELINE:
         return {
            ...state,
            todayTimeline: action.payload
         } 

      default:
         return state
   }
}

export default changePlanReducer