import { ACTIONS, SAGA_ACTIONS } from '../../../../../_config'
import { GetTenantListReq, ChangeTenantStatusReq, FileUploadReq, GetDeleteFilesReq, Domain } from '../../../../interfaces/ApiReqRes'
import { useApiCall } from '../../../common/appApiCallHook'

export function useSuperAdminCommonApi() {

  const callApi = useApiCall()

  const uploadFiles = (data: FileUploadReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SUPER_ADMIN.COMMON.UPLOAD_FILES, data, onSuccess, onError);
  }
  const deleteFiles = (data: GetDeleteFilesReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SUPER_ADMIN.COMMON.DELETE_FILES, data, onSuccess, onError);
  }
  const getFiles = (data: GetDeleteFilesReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SUPER_ADMIN.COMMON.GET_FILES, data, onSuccess, onError);
  }

  const getSiteInfo = (data: Domain, onSuccess: Function, onError: Function) => {
   callApi(SAGA_ACTIONS.SUPER_ADMIN.COMMON.SITE_INFO, data, onSuccess, onError);
 }

 const searchAboutMe = (data:any, onSuccess: Function, onError: Function) => {
  callApi(SAGA_ACTIONS.SUPER_ADMIN.COMMON.SEARCH_ABOUT_ME, null, onSuccess, onError);
}


  return {
    callUploadFiles: uploadFiles,
    callDeleteFiles: deleteFiles,
    callGetFiles: getFiles,
    callSiteInfo: getSiteInfo,
    callSearchAboutMe: searchAboutMe,
  }
}