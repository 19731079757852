import { call, put } from 'redux-saga/effects';
import { ACTIONS, API_URL, SAGA_ACTIONS, STORAGE } from '../../_config';
import { CallApi } from '../api/callApi';
import moment from 'moment'

export function* addEditChangePlan(action: any) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.POST, API_URL.TENANT.PROJECT.CHANGE_PLAN.ADD_EDIT_CHANGE_PLAN, data, true);
      if (resp.status >= 200 && resp.status < 300 && resp.data) {
         action && action.callbackSuccess && action.callbackSuccess(resp.data);
      } else {
         action && action.callbackError && action.callbackError(resp.data);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
   }
}

export function* addToChangePlan(action: any) {
    try {
       const data = action.payload;
       const resp = yield call(CallApi.POST, API_URL.TENANT.PROJECT.CHANGE_PLAN.ADD_TO_CHANGE_PLAN, data, true);
       if (resp.status >= 200 && resp.status < 300 && resp.data) {
          action && action.callbackSuccess && action.callbackSuccess(resp.data);
       } else {
          action && action.callbackError && action.callbackError(resp.data);
       }
    } catch (e) {
       action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
 }

 export function* updateChangePlanStatus(action: any) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.PUT, API_URL.TENANT.PROJECT.CHANGE_PLAN.UPDATE_CHANGE_PLAN_STATUS, data, true);
      if (resp.status >= 200 && resp.status < 300 && resp.data) {
         action && action.callbackSuccess && action.callbackSuccess(resp.data);
      } else {
         action && action.callbackError && action.callbackError(resp.data);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
   }
}

export function* updateChangePlanStartActivityStatus(action: any) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.PUT, API_URL.TENANT.PROJECT.CHANGE_PLAN.UPDATE_CHANGE_PLAN_START_ACTIVITY_STATUS, data, true);
      if (resp.status >= 200 && resp.status < 300 && resp.data) {
         action && action.callbackSuccess && action.callbackSuccess(resp.data);
      } else {
         action && action.callbackError && action.callbackError(resp.data);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
   }
}

export function* removeChangePlan(action: any) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.POST, API_URL.TENANT.PROJECT.CHANGE_PLAN.REMOVE_CHANGE_PLAN, data, true);
      if (resp.status >= 200 && resp.status < 300 && resp.data) {
         action && action.callbackSuccess && action.callbackSuccess(resp.data);
      } else {
         action && action.callbackError && action.callbackError(resp.data);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
   }
}

export function* getChangePlanDetails(action: any) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.GET, API_URL.TENANT.PROJECT.CHANGE_PLAN.GET_CHANGE_PLAN_DETAILS, data, true);
      if (resp.status >= 200 && resp.status < 300 && resp.data) {
         yield put({
            type: ACTIONS.TENANT.PROJECT.CHANGE_PLAN.GET_CHANGE_PLAN_DETAILS,
            payload: resp.data,
         })
         action && action.callbackSuccess && action.callbackSuccess(resp.data);
      } else {
         action && action.callbackError && action.callbackError(resp.data);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
   }
}

export function* getChangePlanTimeline(action: any) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.GET, API_URL.TENANT.PROJECT.CHANGE_PLAN.GET_CHANGE_PLAN_TIMELINE, data, true);
      if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
         yield put({
            type: ACTIONS.TENANT.PROJECT.CHANGE_PLAN.GET_CHANGE_PLAN_TIMELINE,
            payload: resp.data.data,
         })
         action && action.callbackSuccess && action.callbackSuccess(resp.data);
      } else {
         action && action.callbackError && action.callbackError(resp.data);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
   }
}

export function* getTodaysTimeline(action: any) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.GET, API_URL.TENANT.PROJECT.CHANGE_PLAN.GET_TODAY_TIMELINE, data, true);
      if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
         // console.log("ressss",resp.data.data)
         yield put({
            type: ACTIONS.TENANT.PROJECT.CHANGE_PLAN.GET_TODAY_TIMELINE,
            payload: resp.data.data,
         })
         action && action.callbackSuccess && action.callbackSuccess(resp.data);
      } else {
         action && action.callbackError && action.callbackError(resp.data);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
   }
}

export function* getAllChangePlans(action: any) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.GET, API_URL.TENANT.PROJECT.CHANGE_PLAN.GET_ALL_CHANGE_PLANS, data, true);
      if (resp.status >= 200 && resp.status < 300 && resp.data) {
         yield put({
            type: ACTIONS.TENANT.PROJECT.CHANGE_PLAN.GET_ALL_CHANGE_PLAN,
            // payload: resp.data.data.data.sort((a: any, b: any) => moment(b.createdAt) > moment(a.createdAt) ? 1 : -1),
            payload: resp.data.data.data,
         })
         action && action.callbackSuccess && action.callbackSuccess(resp.data);
      } else {
         action && action.callbackError && action.callbackError(resp.data);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
   }
}