import { call, put } from 'redux-saga/effects';
import { ACTIONS, API_URL, SAGA_ACTIONS, STORAGE } from '../../_config';
import { CallApi } from '../api/callApi';


export function* getAllPredefineAssmentList(action: any) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.GET, API_URL.TENANT.PROJECT.ASSESSMENT.GET_ALL_PREDEFINE_ASSESSMENT_LIST, data, true);
      if (resp.status >= 200 && resp.status < 300 && resp.data) {
         yield put({
            type: ACTIONS.TENANT.PROJECT.ASSESSMENT.GET_ALL_PREDEFINE_ASSESSMENT_LIST,
            payload: resp.data.data,
         })
         action && action.callbackSuccess && action.callbackSuccess(resp.data);
      } else {
         action && action.callbackError && action.callbackError(resp.data);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
   }
}

export function* addAssessment(action: any) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.POST, API_URL.TENANT.PROJECT.ASSESSMENT.ADD_ASSESSMENT, data, true);
      if (resp.status >= 200 && resp.status < 300 && resp.data) {
         action && action.callbackSuccess && action.callbackSuccess(resp.data);
      } else {
         action && action.callbackError && action.callbackError(resp.data);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
   }
}

export function* getAllAssmentList(action: any) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.GET, API_URL.TENANT.PROJECT.ASSESSMENT.GET_ALL_ASSESSMENT, data, true);
      if (resp.status >= 200 && resp.status < 300 && resp.data) {
         yield put({
            type: ACTIONS.TENANT.PROJECT.ASSESSMENT.GET_ALL_ASSESSMENT,
            payload: resp.data.data,
         })
         action && action.callbackSuccess && action.callbackSuccess(resp.data);
      } else {
         action && action.callbackError && action.callbackError(resp.data);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
   }
}

export function* getPredefineCategoryWiseQuestionList(action: any) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.POST, API_URL.TENANT.PROJECT.ASSESSMENT.GET_PREDEFINED_CATEGORYWISE_QUESTION, data, true);
      if (resp.status >= 200 && resp.status < 300 && resp.data) {
         yield put({
            type: ACTIONS.TENANT.PROJECT.ASSESSMENT.GET_PREDEFINED_CATEGORYWISE_QUESTION,
            payload: resp.data.data,
         })
         action && action.callbackSuccess && action.callbackSuccess(resp.data);
      } else {
         action && action.callbackError && action.callbackError(resp.data);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
   }
}

export function* updatePredefineCategoryWiseQuestionAnswer(action: any) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.POST, API_URL.TENANT.PROJECT.ASSESSMENT.UPDATE_CATEGORYWISE_QUESTION_ANSWER, data, true);
      if (resp.status >= 200 && resp.status < 300 && resp.data) {         
         action && action.callbackSuccess && action.callbackSuccess(resp.data);
      } else {
         action && action.callbackError && action.callbackError(resp.data);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
   }
}


export function* deleteAssessment(action: any) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.DELETE, API_URL.TENANT.PROJECT.ASSESSMENT.DELETE_ASSESSMENT, data, true);
      if (resp.status >= 200 && resp.status < 300 && resp.data) {
         action && action.callbackSuccess && action.callbackSuccess(resp.data);
      } else {
         action && action.callbackError && action.callbackError(resp.data);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
   }
}


export function* publicGetAssessmentDetails(action: any) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.POST, API_URL.TENANT.PROJECT.ASSESSMENT.PUBLIC_GET_ASSESSMENT_DETAILS, data, false);
      if (resp.status >= 200 && resp.status < 300 && resp.data) {         
         action && action.callbackSuccess && action.callbackSuccess(resp.data);
      } else {
         action && action.callbackError && action.callbackError(resp.data);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
   }
}

export function* publicSaveAssessmentResponse(action: any) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.POST, API_URL.TENANT.PROJECT.ASSESSMENT.PUBLIC_SAVE_ASSESSMENT_RESPONSE, data, false);
      if (resp.status >= 200 && resp.status < 300 && resp.data) {         
         action && action.callbackSuccess && action.callbackSuccess(resp.data);
      } else {
         action && action.callbackError && action.callbackError(resp.data);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
   }
}

export function* saveAssessmentQuestions(action: any) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.POST, API_URL.TENANT.PROJECT.ASSESSMENT.SAVE_CHANGE_ASSESSMENT_QUESTIONS, data, true);
      if (resp.status >= 200 && resp.status < 300 && resp.data) {     
         yield put({
            type: ACTIONS.TENANT.PROJECT.ASSESSMENT.GET_PREDEFINED_CATEGORYWISE_QUESTION,
            payload: resp.data.data,
         })    
         action && action.callbackSuccess && action.callbackSuccess(resp.data);
      } else {
         action && action.callbackError && action.callbackError(resp.data);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
   }
}

export function* renameAssessmentSection(action: any) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.PATCH, API_URL.TENANT.PROJECT.ASSESSMENT.RENAME_ASSESSMENT_SECTION, data, true);
      if (resp.status >= 200 && resp.status < 300 && resp.data) {     
         action && action.callbackSuccess && action.callbackSuccess(resp.data);
      } else {
         action && action.callbackError && action.callbackError(resp.data);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
   }
}


export function* deleteAssessmentSection(action: any) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.DELETE, API_URL.TENANT.PROJECT.ASSESSMENT.DELETE_ASSESSMENT_SECTION, data, true);
      if (resp.status >= 200 && resp.status < 300 && resp.data) {
         action && action.callbackSuccess && action.callbackSuccess(resp.data);
      } else {
         action && action.callbackError && action.callbackError(resp.data);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
   }
}


export function* deleteAssessmentQuestion(action: any) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.DELETE, API_URL.TENANT.PROJECT.ASSESSMENT.DELETE_ASSESSMENT_QUESTION, data, true);
      if (resp.status >= 200 && resp.status < 300 && resp.data) {
         action && action.callbackSuccess && action.callbackSuccess(resp.data);
      } else {
         action && action.callbackError && action.callbackError(resp.data);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
   }
}


export function* renameAssessmentQuestion(action: any) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.PATCH, API_URL.TENANT.PROJECT.ASSESSMENT.RENAME_ASSESSMENT_QUESTION, data, true);
      if (resp.status >= 200 && resp.status < 300 && resp.data) {
         action && action.callbackSuccess && action.callbackSuccess(resp.data);
      } else {
         action && action.callbackError && action.callbackError(resp.data);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
   }
}