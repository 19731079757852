import React from 'react';

const LazyTenantAboutMe = React.lazy(() =>
  import(/* webpackChunkName: "tenantaboutme" */ './aboutme')
);

const TenantAboutMe = (props: Record<string, any>) => (
  <React.Suspense fallback={<h1>Loading...</h1>}>
    <LazyTenantAboutMe {...props} />
  </React.Suspense>
);

export default TenantAboutMe