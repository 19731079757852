import { ActionExtended } from "../../../../../_common/interfaces/ActionExtended";
import { ACTIONS, ChartInitialState } from "../../../../../_config";
import { IChart } from "@mrblenny/react-flow-chart";
import { uniqueId } from "lodash";
import {InfluancemapDetails} from "../../../../../_common/interfaces/models/organogram"

export interface OrganogramReducer {
   activeTab: String,
   chartData: IChart,
   addNodeId: string,
   organogramList: any[],
   organogramDetails: any,
   departmentDivisionList: any[],
   organogramStakeholders: any[],
   influanceMapDetails:InfluancemapDetails|null;
   stakeholderMatrixDetails: any;
}

const initialState: OrganogramReducer = {
   activeTab: 'organogram',
   chartData: ChartInitialState,
   addNodeId: uniqueId('stakeholder_'),
   organogramList: [],
   organogramDetails: null,
   departmentDivisionList: [],
   organogramStakeholders: [],
   influanceMapDetails:null,
   stakeholderMatrixDetails: null,
};

const organogramReducer = (state = initialState, action: ActionExtended) => {
   switch (action.type) {
      case ACTIONS.TENANT.PROJECT.ORGANOGRAM.GET_ACTIVE_TAB:
         return {
            ...state,
            activeTab: action.payload
         }

      case ACTIONS.TENANT.PROJECT.ORGANOGRAM.GET_CHART_DATA:
         return {
            ...state,
            chartData: action.payload
         }

      case ACTIONS.TENANT.PROJECT.ORGANOGRAM.GET_ADD_NODE_ID:
         return {
            ...state,
            addNodeId: action.payload
         }
      case ACTIONS.TENANT.PROJECT.ORGANOGRAM.GET_ORGANOGRAM_DETAILS:
         return {
            ...state,
            organogramDetails: action.payload
         }
      case ACTIONS.TENANT.PROJECT.ORGANOGRAM.GET_ALL_ORGANOGRAMS:
         return {
            ...state,
            organogramList: action.payload
         }
      case ACTIONS.TENANT.PROJECT.ORGANOGRAM.GET_DEPARTMENT_AND_DIVISION:
         return {
            ...state,
            departmentDivisionList: action.payload
         }
      case ACTIONS.TENANT.PROJECT.ORGANOGRAM.GET_ORGANOGRAM_STAKEHOLDERS:
         return {
            ...state,
            organogramStakeholders: action.payload
         }
      case ACTIONS.TENANT.PROJECT.ORGANOGRAM.GET_INFLUANCE_MAP_DETAILS:
         return {
            ...state,
            influanceMapDetails: action.payload
         }
      case ACTIONS.TENANT.PROJECT.ORGANOGRAM.GET_STAKEHOLDER_MATRIX_DETAILS:
         return {
            ...state,
            stakeholderMatrixDetails: action.payload
         }

      default:
         return state
   }
}

export default organogramReducer