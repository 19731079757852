import React from 'react';

const LazyVerifyEmail = React.lazy(() =>
  import(/* webpackChunkName: "Login" */ './verifyEmail')
);

const VerifyEmail = (props: Record<string, any>) => (
  <React.Suspense fallback={<h1>Loading...</h1>}>
    <LazyVerifyEmail {...props} />
  </React.Suspense>
);

export default VerifyEmail