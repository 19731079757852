import { call, put } from 'redux-saga/effects';
import { ACTIONS, API_URL, SAGA_ACTIONS, STORAGE } from '../../_config';
import { CallApi } from '../api/callApi';

export function* uploadFiles(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.TENANT.COMMON.IMAGE_UPLOAD, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* deleteFiles(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.TENANT.COMMON.DELETE_FILES, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* getFiles(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.TENANT.COMMON.GET_FILES, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* searchCountries(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.GET, API_URL.TENANT.COMMON.COUNTRY_LIST, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data) {
      yield put({
        type: ACTIONS.TENANT.COMMON.COUNTRY_LIST,
        payload: resp.data.data,
      })
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* searchStates(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.GET, API_URL.TENANT.COMMON.STATE_LIST, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data) {
      yield put({
        type: ACTIONS.TENANT.COMMON.STATE_LIST,
        payload: resp.data.data,
      })
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* searchCities(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.GET, API_URL.TENANT.COMMON.CITY_LIST, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data) {
      yield put({
        type: ACTIONS.TENANT.COMMON.CITY_LIST,
        payload: resp.data.data,
      })
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* tenantAboutMeListing(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.GET, API_URL.TENANT.COMMON.ABOUT_ME_LIST, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data) {
      yield put({
        type: ACTIONS.TENANT.COMMON.ABOUT_ME_LIST,
        payload: resp.data.data
      })
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      yield put({
        type: ACTIONS.TENANT.COMMON.RESET_ABOUT_ME_LIST,
        payload: []
      })
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    yield put({
      type: ACTIONS.TENANT.COMMON.RESET_ABOUT_ME_LIST,
      payload: []
    })
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* getSiteInfo(action: any) {
   try {
     const data = action.payload;
     const resp = yield call(CallApi.GET, API_URL.SUPER_ADMIN.COMMON.SITE_INFO, data, false);
     if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data) {
       yield put({
         type: ACTIONS.SUPER_ADMIN.COMMON.SITE_INFO,
         payload: resp.data.data,
       })
       action && action.callbackSuccess && action.callbackSuccess(resp.data);
     } else {
       action && action.callbackError && action.callbackError(resp.data);
     }
   } catch (e) {
     action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
   }
 }

 export function* publicShareLink(action: any) {
  try {
     const data = action.payload;
     const resp = yield call(CallApi.POST, API_URL.TENANT.COMMON.SHARE_LINK, data, true);
     if (resp.status >= 200 && resp.status < 300 && resp.data) {         
        action && action.callbackSuccess && action.callbackSuccess(resp.data);
     } else {
        action && action.callbackError && action.callbackError(resp.data);
     }
  } catch (e) {
     action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* publicGetModuleDetails(action: any) {
  try {
     const data = action.payload;
     const resp = yield call(CallApi.POST, API_URL.TENANT.COMMON.GET_PUBLIC_MODULE_DETAILS, data, false);
     if (resp.status >= 200 && resp.status < 300 && resp.data) {         
        action && action.callbackSuccess && action.callbackSuccess(resp.data);
     } else {
        action && action.callbackError && action.callbackError(resp.data);
     }
  } catch (e) {
     action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* getTenantModulePermission(action: any) {
   try {
     const data = action.payload;
     const resp = yield call(CallApi.GET, API_URL.TENANT.COMMON.GET_TENANT_MODULE_PERMISSION, data, true);
     if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data) {
       action && action.callbackSuccess && action.callbackSuccess(resp.data);
     } else {
       action && action.callbackError && action.callbackError(resp.data);
     }
   } catch (e) {
     action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
   }
 }

 export function* searchDialcode(action: any) {
    try {
      const data = action.payload;
      const resp = yield call(CallApi.GET, API_URL.TENANT.COMMON.SEARCH_DIALCODE, data, true);
      if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data) {
        yield put({
          type: ACTIONS.TENANT.COMMON.SEARCH_DIALCODE,
          payload: resp.data.data,
        })
        action && action.callbackSuccess && action.callbackSuccess(resp.data);
      } else {
        action && action.callbackError && action.callbackError(resp.data);
      }
    } catch (e) {
      action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
  }