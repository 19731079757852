let apiBaseUrl = 'https://api-change-manager.glohtesting.com:9595/api/'

let appStage = process.env.REACT_APP_STAGE

if (appStage == 'dev') {
  apiBaseUrl = 'https://api-change-manager.glohtesting.com:9595/api/'
  // apiBaseUrl = 'http://10.25.27.34:5000/api/'
  // apiBaseUrl = 'https://localhost:4000/api/'
} else if (appStage == 'stage') {
  apiBaseUrl = 'https://api-change-manager.glohtesting.com:9595/api/'
} else if (appStage == 'uat') {
  // apiBaseUrl = 'https://api.changd.net/api/'
  apiBaseUrl = 'https://api-change-manager-uat.glohtesting.com:8585/api/'
} else if (appStage == 'prod') {
  apiBaseUrl = 'https://api.changd.net/api/'
  // apiBaseUrl = 'https://api-change-manager-uat.glohtesting.com:8585/api/'
}

export const API_BASE_URL = apiBaseUrl